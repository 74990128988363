import React, { useEffect } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Entries from "components/Entries";
import Header from "components/Header";
import RouteLoading from "components/Loading/RouteLoading";
import ContactMap from "components/Map/ContactMap";
import TopTabNav from "components/TopTab/TopTabNav";
import useContest from "hooks/contest.hook";
import { useLocation, useSearchParams } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";
import { UserAvatar } from "components/UserAvatar";

const UserLogbookDetail = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const contestPassed = location?.state?.contestPassed || false;
  const {
    getContestantDetail,
    userContestantDetail,
    isUserContestantLoading,
    declareWinner,
  } = useContest();
  useEffect(() => {
    if (searchParams.get("contest_id") && searchParams.get("user_id")) {
      getContestantDetail(
        searchParams.get("contest_id")!,
        searchParams.get("user_id")!
      );
    } else {
      globalNavigate("/contest");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="LeaderBoard" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        {isUserContestantLoading ? (
          <RouteLoading noBg />
        ) : (
          <>
            <div className="flex justify-between bg-[#0B0B38] w-full mb-12 rounded-[50px] h-[25vw] lg:h-[20vw]">
              <div className="flex flex-col items-center w-[20%] pt-4 pb-5 pl-3">
                <h3 className="text-white text-center text-base xl:text-lg font-[Play] font-bold">
                  {userContestantDetail?.user?.callSign || ""}
                </h3>
                {userContestantDetail?.user?.profilePic ? (
                  <img
                    className="w-[70%] rounded-full h-[10vw] mt-9"
                    src={userContestantDetail?.user?.profilePic}
                    alt=""
                  />
                ) : (
                  <UserAvatar
                    className="h-[10vw] mt-9 w-[70%]"
                  />
                )}
              </div>
              <div className="w-[40%] h-full flex flex-col justify-center py-7">
                <div className="flex justify-between items-center w-full py-3">
                  <p className="font-[Play] text-sm xl:text-base text-white font-bold">
                    CONTEST RANKING
                  </p>
                  <p className="font-[Play] text-lg xl:text-xl text-white font-bold">
                    {userContestantDetail?.contestRanking || 0}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full py-3">
                  <p className="font-[Play] text-sm xl:text-base text-white font-bold">
                    TOTAL POINTS
                  </p>
                  <p className="font-[Play] text-lg xl:text-xl text-white font-bold">
                    {userContestantDetail?.totalQso || 0}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full py-3">
                  <p className="font-[Play] text-sm xl:text-base text-white font-bold">
                    TOTAL QSO
                  </p>
                  <p className="font-[Play] text-lg xl:text-xl text-white font-bold">
                    {userContestantDetail?.totalQso || 0}
                  </p>
                </div>
              </div>
              <div className="relative w-[30%] lg:w-[33%] flex justify-center">
                <div className="absolute w-[90%] h-[110%] flex justify-center top-[-5%]">
                  <img
                    className="w-full object-fit"
                    src={Icons.GradientRectangle2}
                    alt="bg"
                  />
                </div>
                <div className="bg-[#141440] rounded-[50px] w-full relative py-4">
                  <p className="text-center text-white text-sm xl:text-base font-[Play] font-bold">
                    MY FEATURED AWARDS
                  </p>
                  <div className="w-full flex justify-center items-center my-4 lg:my-[12%]"></div>
                  <p className="text-center text-white font-[Barlow] text-lg">
                    See more
                  </p>
                </div>
              </div>
            </div>
            {contestPassed ? (
              <AccessControl
                permission={PERMISSIONS.declareContestWinner}
                children={
                  <div className="w-full flex justify-end">
                    <div className="w-[220px]">
                      <div className="w-full md:w-[220px]">
                        <BorderButtonSecondary
                          className=""
                          height="h-11"
                          text={"Declare as Winner"}
                          onClick={() =>
                            declareWinner(searchParams.get("contest_id")!)
                          }
                          childClassName="text-sm md:text-base py-2 px-[10px]"
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            ) : null}

            <TopTabNav
              setSelectedTabIndex={(tab) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("tab", tab);
                setSearchParams(newSearchParams);
              }}
              selectedTabIndex={searchParams.get("tab") || ""}
              firstIndexText={`ENTRIES(${userContestantDetail?.totalQso || 0})`}
              secondIndexText={`QSO Map`}
            />
            {searchParams.get("tab") === "entries" ? (
              <Entries
                lists={userContestantDetail?.userLogs || []}
                editable={false}
              />
            ) : (
              <ContactMap
                className="w-full h-[500px] relative"
                containerClassName="w-full"
                userContactMap={true}
              />
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default UserLogbookDetail;
