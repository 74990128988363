import {
    QSOManagerState,
    defaultQSOManagerState,
} from 'types/State';
import {
    SET_QSO_MANAGER_CONTACTS,
    REMOVE_QSO_MANAGER_CONTACT,
    SET_QSO_MANAGER_LOADING,
    SET_QSO_MANAGER_ERROR,
    QSOManagerPayload,
} from 'store/Actions/qsoManager.actions';
import { RootState } from 'store/Reducers';

export default function qsoManagerReducer(
    state: QSOManagerState = defaultQSOManagerState,
    action: QSOManagerPayload
): QSOManagerState {
    switch (action.type) {
        case SET_QSO_MANAGER_CONTACTS:
            return {
                ...state,
                contacts: action.payload.contacts,
                totalCount: action.payload.totalCount,
                limitExceeded: action.payload.limitExceeded,
                // lastVisibleDocId: action.payload.lastVisibleDocId,

                loading: false,
                error: null,
            };
        case REMOVE_QSO_MANAGER_CONTACT:
            return {
                ...state,
                contacts: state.contacts.filter(contact => contact.id !== action.payload),
                loading: false,
                error: null,
            };
        case SET_QSO_MANAGER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_QSO_MANAGER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}

export const getQSOManagerContactsSelector = (state: RootState) => {
    return state.qsoManager;
  };