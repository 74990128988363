import React, { useMemo, useState } from "react";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import { globalNavigate } from "routes/GlobalRoutes";
import { FileUploader } from "react-drag-drop-files";
import Icons from "assets/Icons";
import TextInput from "components/Inputs/TextInput";
import useAdifImport from "hooks/adifImport.hook";
import { SliderButtonGroup } from "components/SelectableButtonsGroup";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { LogBookModel, SavedLocation } from "types/Models";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { getLogBookListSelector } from "store/Reducers/logBook.reducer";
import { useSelector } from "react-redux";
import Accordion from "./Accordian";

function ImportContact() {
  const fileTypes = ["ADI", "ADIF"];
  const {
    setFile,
    file,
    submitForm,
    setLogBookName,
    logBookName,
    isAdiUploadLoading,
    logbookAdiLocation,
    setLogbookAdiLocation,
    isNewLogbook,
    setIsNewLogbook,
    setSelectedLogbookId,
    selectedLogbookId,
  } = useAdifImport();

  const {
    setLocationOption,
    locationOption,
    savedLocationOptions,
    savedLocations,
  } = useLogbookSetting();
  // const { values, setFormValue } = useLogBookSettingsForm();

  const logBooksList: LogBookModel[] = useSelector(getLogBookListSelector);

  const logbookOptions = useMemo(
    () =>
      logBooksList.map((logbook) => ({
        label: `${logbook.name}`,
        value: logbook.id,
      })),
    [logBooksList]
  );

  const handleChange = (file: any) => {
    setFile(file);
  };
  return (
    <main className="w-full dashboardWrapper flex flex-col items-center relative">
      <Header title="Import .ADI Files" />

      <div className="mt-10 flex justify-center items-center flex-col lg:block">
        <div className="w-11/12 sm:w-5/6 md:w-[690px]  flex flex-col relative bg-[#151541] py-[35px] px-[50px]">
          <h3 className="font-['Play'] text-xl text-white mb-4 font-bold">
            Import ADIF
          </h3>

          <p className="font-[Barlow] font-bold text-white">Upload Files</p>
          <div className="my-2">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              required={true}
              classes="h-[200px]"
            >
              <div className="w-full border-dashed border h-[205px] flex items-center justify-between px-[32px] cursor-pointer">
                <img
                  className="w-[48px] h-[48px] object-contain"
                  alt="upload"
                  src={Icons.UploadIcon}
                />
                <div className="justify-center">
                  <p className="font-[Barlow] text-white">
                    Select a file or drag and drop here
                  </p>
                  <div className="flex flex-col">
                    <span className="font-[Barlow] text-[#73738D] text-xs">
                      Select .ADI or .ADIF file
                    </span>
                    <span className="font-[Barlow] text-[#844D0F] text-xs mt-3">
                      Imported contacts do not affect leaderboards or recent
                      contacts list
                    </span>
                  </div>
                </div>
                <button className="text-xs w-[90px] h-[36px] bg-[#0243DD] font-[Barlow] text-white">
                  SELECT FILE
                </button>
              </div>
            </FileUploader>
          </div>

          {file ? (
            <>
              <p className="font-[Barlow] text-white mt-2 mb-2">File Added</p>
              <div className="flex w-full h-[58px] bg-[#2A2852] items-center px-6 justify-between">
                <div className="flex items-center">
                  <img
                    src={Icons.FileIcon}
                    alt="file-icon"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-white font-[Barlow] text-[12px] ml-4">
                    {file.name}
                  </span>
                </div>
                <span className="text-white font-[Barlow] text-[12px] ml-4">
                  {(file.size / 1024).toFixed(1)} KB
                </span>
              </div>
              <div className="mb-3">
                <h3 className="font-['Barlow'] text-base text-white mb-2.5 font-bold mt-4">
                  Import ADIF Into:
                </h3>

                <div className="w-[100%] md:w-[90%] lg:w-[80%] xl:w-[70%]">
                  <SliderButtonGroup
                    initialSelected={1}
                    actions={[
                      {
                        id: 1,
                        label: "New Logbook",
                      },
                      {
                        id: 2,
                        label: "Existing Logbook",
                      },
                    ]}
                    onButtonChange={(action) => {
                      if (action?.id === 1) {
                        setIsNewLogbook(true);
                      } else {
                        setIsNewLogbook(false);
                      }
                    }}
                  />
                </div>

                {isNewLogbook ? (
                  <>
                    <h3 className="font-['Barlow'] text-base text-white mb-2.5 font-bold mt-5">
                      Logbook Name
                    </h3>
                    <TextInput
                      text={logBookName}
                      setText={(text) => {
                        setLogBookName(text);
                      }}
                      placeHolder="Logbook Name *"
                      name="logbookName"
                      className="mb-2.5 bg-[#73738D] border-1 border-[#a7a7a7] text-sm h-[40px]"
                    />
                    <p className="text-[#17f9da] text-xs">
                      Choose any name! Example: HF Log, 10M Log, SSB, POTA
                    </p>
                  </>
                ) : (
                  <>
                    <h3 className="font-['Barlow'] text-base text-white font-bold mt-5">
                      Select Logbook
                    </h3>
                    <LogbookSettingDropdown
                      list={logbookOptions}
                      title=""
                      value={selectedLogbookId || ""}
                      setValue={(value) => {
                        if (value === "add") {
                        } else {
                          setSelectedLogbookId(value);
                        }
                      }}
                      className={"w-[99.9%] sm:w-[99.9%] md:w-[99.9%]"}
                    />
                  </>
                )}
              </div>
            </>
          ) : null}

          {file ? (
            <>
              <Accordion title="Advanced Settings">
                <div className="mb-3 p-1 w-full">
                  <h3 className="font-['Barlow'] text-base text-white  font-bold">
                    Update Missing “My Location” During Import
                  </h3>
                  <p className="font-[Play] text-xs text-[#17F9DA] mb-2">
                    When “My Location” is not set in the ADIF file, set My
                    Location to:
                  </p>

                  <div className="flex flex-col w-full py-4 pb-4">
                  <div className="w-[100%] md:w-[90%] lg:w-[80%] xl:w-[70%]">
                    <SliderButtonGroup
                      initialSelected={2}
                      actions={[
                        {
                          id: 2,
                          label: "Do Not Set / Leave Blank",
                        },
                        {
                          id: 1,
                          label: "Saved Location",
                        },
                      ]}
                      onButtonChange={(action) => {
                        setLocationOption(action?.id || 2);
                        if (action?.id === 2) {
                          setLogbookAdiLocation(null);
                        }
                      }}
                    />
                    </div>
                    <div className="flex flex-row flex-wrap mt-3">
                      {locationOption === 1 ? (
                        <LogbookSettingDropdown
                          list={savedLocationOptions}
                          title="Saved Locations:"
                          value={logbookAdiLocation?.id || ""}
                          setValue={(value) => {
                            if (value === "add") {
                              globalNavigate("/log-contacts/saved-locations");
                            } else {
                              setLogbookAdiLocation(
                                savedLocations.find(
                                  (location: SavedLocation) =>
                                    location.id === value
                                ) || null
                              );
                            }
                          }}
                          className={"w-[99.9%] sm:w-[99.9%] md:w-[99.9%]"}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </Accordion>
            </>
          ) : null}
        </div>
        {file ? (
          <div className="w-full  flex items-center justify-between mb-8 mt-10 sm:mt-10 px-10">
            <div className="flex items-end w-[40%]">
              <BorderButtonSecondary
                isLoading={isAdiUploadLoading}
                text={"Upload"}
                onClick={() => {
                  submitForm();
                }}
                childClassName="text-sm"
              />
            </div>
            <div className="flex items-end w-[40%]">
              <BorderButtonSecondary
                isLoading={false}
                text={"Cancel"}
                onClick={() => {
                  globalNavigate("/log-contacts");
                }}
                childClassName="text-sm"
              />
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
}

export default ImportContact;
