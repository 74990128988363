import { useState, useRef, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { addDays, isSameDay, format, isValid } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './CustomDateRangePicker.css';
import { DateRange } from 'types/QSOManager.types';

interface PredefinedDateRangePickerProps {
  onDateRangeChange: (range: { startDate: Date | null, endDate: Date | null }) => void;
  initialValue: DateRange;
}

const PredefinedDateRangePicker: React.FC<PredefinedDateRangePickerProps> = ({ onDateRangeChange, initialValue }) => {
  const datePickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const isAllTimeRef = useRef(!initialValue?.startDate || !initialValue?.endDate ? true : false);

  const [range, setRange] = useState([
    {
      startDate: initialValue?.startDate ? new Date(initialValue.startDate) : new Date(),
      endDate: initialValue?.endDate ? new Date(initialValue.endDate) : new Date(),
      key: 'selection',
    },
  ]);

  // useEffect to update the range when the initialValue changes
  useEffect(() => {
    if (initialValue?.startDate && initialValue?.endDate) {
      setRange([
        {
          startDate: new Date(initialValue.startDate),
          endDate: new Date(initialValue.endDate),
          key: 'selection',
        },
      ]);
    }
  }, [initialValue]);
  
  const earliestStartDate = new Date(1970, 0, 1); // Earliest possible date
  const latestEndDate = new Date(9999, 11, 31); // Latest possible date

  const predefinedRanges = [
    {
      label: 'All Time',
      range: () => ({
        startDate: earliestStartDate,
        endDate: latestEndDate
      }),
      isSelected: (range: any) => isSameDay(range.startDate, earliestStartDate) && isSameDay(range.endDate, latestEndDate) || (isAllTimeRef.current && isSameDay(range.startDate, new Date()) && isSameDay(range.endDate, new Date())),
    },
    {
      label: 'Today',
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: (range: any) => !isAllTimeRef.current && isSameDay(range.startDate, new Date()) && isSameDay(range.endDate, new Date()),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
      }),
      isSelected: (range: any) => isSameDay(range.startDate, addDays(new Date(), -1)) && isSameDay(range.endDate, addDays(new Date(), -1)),
    },
    {
      label: 'Last 7 Days',
      range: () => ({
        startDate: addDays(new Date(), -6),
        endDate: new Date(),
      }),
      isSelected: (range: any) => isSameDay(range.startDate, addDays(new Date(), -6)) && isSameDay(range.endDate, new Date()),
    },
    {
      label: 'Last 30 Days',
      range: () => ({
        startDate: addDays(new Date(), -29),
        endDate: new Date(),
      }),
      isSelected: (range: any) => isSameDay(range.startDate, addDays(new Date(), -29)) && isSameDay(range.endDate, new Date()),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      }),
      isSelected: (range: any) => isSameDay(range.startDate, new Date(new Date().getFullYear(), new Date().getMonth(), 1)) && isSameDay(range.endDate, new Date()),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      }),
      isSelected: (range: any) => isSameDay(range.startDate, new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)) && isSameDay(range.endDate, new Date(new Date().getFullYear(), new Date().getMonth(), 0)),
    },
  ];

  const toggleDatePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleSelect = (ranges: any) => {
    debugger
    const selectedRange = ranges.selection;
    const predefinedRange = predefinedRanges.find((predefinedRange) => predefinedRange.isSelected(selectedRange));
    const isAllTime = predefinedRange?.label === 'All Time' && isSameDay(selectedRange.startDate, earliestStartDate) && isSameDay(selectedRange.endDate, latestEndDate);
    if (isAllTime) {
      isAllTimeRef.current = true;
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
      // Notify parent with the selected date range
      onDateRangeChange({ startDate: null, endDate: null });
    } else {
      isAllTimeRef.current = false;
      setRange([selectedRange]);
      // Notify parent with the selected date range
      onDateRangeChange({ startDate: selectedRange.startDate, endDate: selectedRange.endDate });
    }
    setShowPicker(false); // Close picker after selection
  };

  return (
    <div className="custom-date-range-picker date-range-picker">
      <div className='w-[150px] font-[Barlow] text-white text-sm' onClick={toggleDatePicker}>
        {isValid(range[0].startDate) && isValid(range[0].endDate) ? ( isAllTimeRef.current
           ? ( '' ) : (
        <div>{`${format(range[0].startDate, 'MMM d')} - ${format(range[0].endDate, 'MMM d, yyyy')}`}</div>
          )
        ) : (
          <div>Invalid Date</div>
        )}
        <div className="flex justify-between items-center">
          <span className="text-lg">{isValid(range[0].startDate) ? (isAllTimeRef.current ? 'All Time' : format(range[0].startDate, 'yyyy'))  : 'Invalid Date'}</span>
          <span className="text-xs text-[#00FFFF]">▼</span>
        </div>
      </div>

      {showPicker && (
        <div ref={datePickerRef} className="date-range-wrapper">
          <DateRangePicker
            ranges={range}
            onChange={handleSelect}
            className="custom-date-range"
            staticRanges={predefinedRanges}
          />
        </div>
      )}
    </div>
  );
};

export default PredefinedDateRangePicker;