import { useState, useEffect } from 'react';

function useIsLargeScreen() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)'); // Tailwind's 'lg' breakpoint

    // Set initial value based on current screen width
    setIsLargeScreen(mediaQuery.matches);

    // Update state when the screen size changes
    const handleResize = (event: MediaQueryListEvent) => {
      setIsLargeScreen(event.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isLargeScreen;
}

export default useIsLargeScreen;