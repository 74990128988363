import Icons from 'assets/Icons';
import React from 'react';

interface GradientDynamicButtonProps {
    text: string;
    width?: string;
    height?: string;
    fontSize?: string;
    buttonClasses?: string;
    spanClasses?: string;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    showCloseIcon?: boolean;
    onClose?: () => void;
}
// Default props for the GradientDynamicButton component
const GradientDynamicButton: React.FC<GradientDynamicButtonProps> = ({
    text,
    width = 'auto',  // Default width to auto for dynamic resizing
    height = '40px',
    fontSize = 'text-base',
    buttonClasses = '',
    spanClasses = '',
    onClick = (event: React.MouseEvent<HTMLSpanElement>) => { },
    showCloseIcon = true,
    onClose = () => { },
}) => {
    return (
        <div className="flex h-[auto]"> {/* No fixed width here */}
            <button
                onClick={onClick}
                className={`relative bg-gradient-to-r from-[#FFD80E] via-[#272750] to-[#2CD9FF] p-[1px] rounded-md flex justify-between items-center ${buttonClasses}`}
                style={{ height: height, minWidth: width }} // Set minimum width if needed
            >
                {/* Inner span for text and close button */}
                <div className="flex items-center justify-between bg-[#1D204A] w-full h-full rounded-md pl-3 pr-2">
                    <span
                        className={`text-white font-Play ${fontSize} ${spanClasses}`}
                        style={{ fontSize: fontSize }}
                    >
                        {text}
                    </span>

                    {showCloseIcon && (
                        <img 
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                        className="h-3 w-3 ml-2 text-white cursor-pointer" 
                        src={Icons.CrossIcon} 
                        alt="cross icon" 
                        />
                    )}
                </div>
            </button>
        </div>
    );
};

export default GradientDynamicButton;
