import { auth } from "firebase-config";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserActivityService from "services/userActivity.service";
import { setUserActivities as setUserActivitiesAction } from "store/Actions/userActivity.actions";
import {
  getShowExportAllContactsSelector,
  getUserActivitiesSelector,
  getUserActivityIdSelector,
  getUserActivityStatusSelector,
  getUserActivityTypeSelector,
} from "store/Reducers/userActivity.reducer";

export function useUserActivity() {
  const dispatch = useDispatch();
  const userActivities = useSelector(getUserActivitiesSelector);
  const userActivityStatus = useSelector(getUserActivityStatusSelector);
  const userActivityId = useSelector(getUserActivityIdSelector);
  const userActivityType = useSelector(getUserActivityTypeSelector);
  const showExportAllContacts = useSelector(getShowExportAllContactsSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [previousStatus, setPreviousStatus] = useState("Complete");
  const [shouldFetchActivities, setShouldFetchActivities] = useState(false);

  const getAllUserActivities = (userId: string, cb: any) => {
    return UserActivityService.getAllUserActivities(
      userId,
      {
        orderByField: "timestamp",
        order: "desc",
      },
      cb
    );
  };

  const getActivityDetailsById = (
    activityId: string,
    cb: (activityData: any) => void
  ) => {
    return UserActivityService.getActivityDetailsById(activityId, cb);
  };

  const setUserActivities = (activities: any[]) => {
    dispatch(setUserActivitiesAction(activities));
  };

  return {
    isLoading,
    setIsLoading,
    getAllUserActivities,
    userActivities,
    getActivityDetailsById,
    setUserActivities,
    userActivityStatus,
    userActivityType,
    previousStatus,
    setPreviousStatus,
    shouldFetchActivities,
    setShouldFetchActivities,
    showExportAllContacts,
    userActivityId
  };
}
