import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import SpottingFilters from "components/SpottingFilters";
import SpottingGridView from "components/SpottingGridView";
import SpottingTableView from "components/SpottingTableView";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddSpotModal } from "store/Actions/logBook.actions";
import { getIsSpottingLoadingSelector } from "store/Reducers/loading.reducer";
import { getSpotSelector } from "store/Reducers/spotting.reducer";
import Icons from "assets/Icons";
import { ContactSpottingProps, ToastTypes } from "types/Component";
import {
  SpotFilters,
  SpotModel,
  SortConfig,
  ParkReferenceModel,
} from "types/Models";
import { showToast } from "helpers/Toast";

import ActivityIndicator from "components/Loading/ActivityIndicator";
import SpotRefresh from "components/SpotRefresh";

function ContactSpotting({
  setValues,
  setMode,
  setBand,
  setTheirPark,

  setSortConfig,
  filters,
  setFilters,
  applyChanges,

  filterCountry,
  setFilterCountry,
  filterRegion,
  setFilterRegion,
  filterBand,
  setFilterBand,
  filterMode,
  setFilterMode,
  spottedCountry,
  setSpottedCountry,
  spottedRegion,
  setSpottedRegion,
  operatingNearMe,
  setOperatingNearMe,
  dxOnly,
  setDxOnly,
  spottedNearMe,
  setSpottedNearMe,
  potaOnly,
  setPotaOnly,
  wrlOnly,
  setWrlOnly,
  logged,
  setLogged,
}: ContactSpottingProps) {
  const dispatch = useDispatch();
  const spotsData = useSelector(getSpotSelector);
  const spotLoading = useSelector(getIsSpottingLoadingSelector);
  const [showGridView, setShowGridView] = useState(true);

  const onCopyPressed = (item: SpotModel) => {
    if (setValues) {
      let theirParks: any = {};
      if (item?.parkNumber) {
        const parkData: ParkReferenceModel = {
          entityId: 0,
          parkId: 0,
          parktypeDesc: "",
          referencePrefix: item?.parkNumber
            ? item?.parkNumber.split("-")[0]
              ? item?.parkNumber.split("-")[0]
              : ""
            : "",
          name: item?.parkName || "",
          grid6: item?.theirGrid || "",
          grid4: item?.theirGrid || "",
          longitude: item?.theirCoordinates?.longitude || 0,
          latitude: item?.theirCoordinates?.latitude || 0,
          reference: item?.parkNumber || "",
          locationName: item.theirState || "",
          parktype: "",
          entityName: item?.theirCountry || "",
        };
        theirParks[`${item.parkNumber}`] = parkData;
      }

      setValues((prev: any) => {
        let data = {
          ...prev,
        };
        data.theirCallsign = item.theirCallSign || "";
        data.operator = item.theirName || "";
        data.state = item.theirState || "";
        data.country = item.theirCountry || "";
        data.grid = item.theirGrid || "";
        data.frequency = item.frequency || "";
        data.band = item.band || "";
        data.userMode = item.mode || "";
        data.theirName = item.theirName || "";
        data.spotSource = item.spotSource || "";
        data.spottedContact = true;
        data.qth = item.theirState || "";
        data.theirPark = item.parkNumber || "";
        data.theirParkKey = item.parkNumber || "";
        data.theirParks = theirParks;

        return data;
      });
      setMode && setMode(item.mode);
      setBand && setBand(item.band);
      setTheirPark && setTheirPark(item.parkNumber);
      showToast({
        message: "Spotting data copied to logbook",
        type: ToastTypes.SUCCESS,
      });
    }
  };

  const onFilterApply = (filter: SpotFilters[]) => {
    applyChanges(filter);
  };

  const refreshSpots = useCallback(() => {
    applyChanges();
  }, [dispatch]);

  const clearFilter = () => {
    applyChanges([]);
  };

  return (
    <main className="w-full flex flex-col items-center relative mt-4">
      <SpottingFilters
        onFilterApply={onFilterApply}
        clearFilter={clearFilter}
        setFilters={setFilters}
        filters={filters}
        country={filterCountry}
        setCountry={setFilterCountry}
        region={filterRegion}
        setRegion={setFilterRegion}
        band={filterBand}
        setBand={setFilterBand}
        mode={filterMode}
        setMode={setFilterMode}
        spottedCountry={spottedCountry}
        setSpottedCountry={setSpottedCountry}
        spottedRegion={spottedRegion}
        setSpottedRegion={setSpottedRegion}
        operatingNearMe={operatingNearMe}
        setOperatingNearMe={setOperatingNearMe}
        dxOnly={dxOnly}
        setDxOnly={setDxOnly}
        spottedNearMe={spottedNearMe}
        setSpottedNearMe={setSpottedNearMe}
        potaOnly={potaOnly}
        setPotaOnly={setPotaOnly}
        wrlOnly={wrlOnly}
        setWrlOnly={setWrlOnly}
        logged={logged}
        setLogged={setLogged}
      />

      <div className="flex justify-between w-full mb-5 mt-3">
        <div className="flex">
          <div
            className="w-[150px] bg-[#fff] h-[30px] flex items-center justify-center rounded-md mb-3 ml-2 cursor-pointer"
            onClick={() => {
              setShowGridView(!showGridView);
            }}
          >
            <p className="font-[Play] text-[#0243DD] text-center">
              {showGridView ? "Table View" : "Grid View"}
            </p>
            <img src={Icons.View} alt="" className="w-[15px] h-[15px] ml-2" />
          </div>
        </div>
        <div className="w-[220px]">
          <BorderButtonSecondary
            text={`+  Add Spot`}
            onClick={() => {
              dispatch(setAddSpotModal(true));
            }}
            className="sm:mb-0 cursor-pointer"
          />
        </div>
      </div>
      <SpotRefresh refreshSpots={refreshSpots} />
      {spotLoading ? (
        <ActivityIndicator size={36} />
      ) : showGridView ? (
        <SpottingGridView spotsData={spotsData} onCopyPressed={onCopyPressed} />
      ) : (
        <SpottingTableView
          spotsData={spotsData}
          onCopyPressed={onCopyPressed}
          setSortConfig={setSortConfig}
        />
      )}

      <div className="h-[100px]"></div>
    </main>
  );
}

export default ContactSpotting;
