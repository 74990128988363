import { call, put, takeLatest } from "redux-saga/effects";
import LogBookService from "services/logBook.service";
import {
  FETCH_QSO_MANAGER_CONTACTS,
  DELETE_QSO_MANAGER_CONTACT,
  setQSOManagerContacts,
  setQSOManagerLoading,
  setQSOManagerError,
} from "store/Actions/qsoManager.actions";

import { SagaIterator } from "redux-saga";

function* fetchQSOManagerContacts(action: any): SagaIterator {
    try {
      yield put(setQSOManagerLoading(true));
      const result = yield call(LogBookService.getQSOManagerContacts, action.payload);
      yield put(setQSOManagerContacts(result));
    } catch (error) {
      yield put(setQSOManagerError("Error fetching QSO Manager contacts: "+ error));
    } finally {
      yield put(setQSOManagerLoading(false));
    }
  }

// function* deleteQSOManagerContact(action) {
//   try {
//     yield call(LogBookService.deleteQSOManagerContact, action.payload);
//     yield put(removeQSOManagerContact(action.payload));
//   } catch (error) {
//     console.error("Error deleting QSO Manager contact:", error);
//   }
// }

export function* watchQSOManager() {
  yield takeLatest(FETCH_QSO_MANAGER_CONTACTS, fetchQSOManagerContacts);
//   yield takeLatest(DELETE_QSO_MANAGER_CONTACT, deleteQSOManagerContact);
}