import React, { useEffect, useState, useMemo } from "react";

import Switch from "react-switch";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import { AllBandsOption, AllModesOption } from "constants/ArrayVariables";
import { RegionDropdown } from "react-country-region-selector";
import { SpottingFiltersProps } from "types/Component";
import { SpotFilters } from "types/Models";

import MultiSelectDropDown from "components/DropDown/DropDown/MultiSelectDropDown";
import countryList from "react-select-country-list";
import { dxccCountryList } from "constants/DXCCCountryList";
import { getStateShortCode } from "helpers/Utils";

function SpottingFilters({
  onFilterApply,
  clearFilter,
  bandChange,
  modeChange,
  setFilters,
  filters,

  country,
  setCountry,
  region,
  setRegion,
  band,
  setBand,
  mode,
  setMode,
  spottedCountry,
  setSpottedCountry,
  spottedRegion,
  setSpottedRegion,
  operatingNearMe,
  setOperatingNearMe,
  dxOnly,
  setDxOnly,
  spottedNearMe,
  setSpottedNearMe,
  potaOnly,
  setPotaOnly,
  wrlOnly,
  setWrlOnly,
  logged,
  setLogged,
}: SpottingFiltersProps) {
  const countryOptions = useMemo(
    () =>
      dxccCountryList.map((data, index) => {
        return { name: data.country, id: index + 1 };
      }),
    []
  );

  const clearFilters = () => {
    setFilters([]);
    //reset all filter states
    setCountry([]);
    setRegion("Region");
    setSpottedCountry([]);
    setSpottedRegion("Region");
    setOperatingNearMe(false);
    setDxOnly(false);
    setMode([]);
    setBand([]);
    setSpottedNearMe(false);
    setPotaOnly(false);
    setWrlOnly(false);
    setLogged(true);
    clearFilter();
  };

  useEffect(() => {
    if (filters.length === 0) {
      onFilterApply([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const applyFilter = () => {
    onFilterApply(filters);
  };

  const updateFilter = (
    currentFilters: SpotFilters[],
    filterName: string,
    newValue: string | boolean
  ) => {
    // Check if the filter already exists
    const filterExists = currentFilters.some((filter) =>
      filter.hasOwnProperty(filterName)
    );

    if (filterExists) {
      // Update the existing filter
      return currentFilters.map((filter) =>
        filter.hasOwnProperty(filterName)
          ? { ...filter, [filterName]: newValue }
          : filter
      );
    } else if (newValue) {
      // Add a new filter if it doesn't exist and newValue is not empty
      return [...currentFilters, { [filterName]: newValue }];
    }
    // If newValue is empty and filter doesn't exist, just return currentFilters
    return currentFilters;
  };

  return (
    <div className="w-full text-white flex flex-wrap">
      <div className="w-[100%] md:w-[50%]">
        <div className="w-full flex justify-center mb-2">
          <span className="font-[Barlow] font-bold text-xs">
            Station Located In:
          </span>
        </div>
        <div className="flex flex-wrap">
          <MultiSelectDropDown
            options={AllBandsOption}
            value={band}
            onSelectValue={(selectedList) => {
              setBand(selectedList);
              bandChange && bandChange(selectedList);

              setFilters((currentFilters: any) =>
                updateFilter(currentFilters, "band", selectedList)
              );
            }}
            onRemoveValue={(selectedList) => {
              setBand(selectedList);
              bandChange && bandChange(selectedList);

              if (selectedList.length === 0) {
                setFilters(filters.filter((f: any) => !f.band));
              } else {
                setFilters((currentFilters: any) =>
                  updateFilter(currentFilters, "band", selectedList)
                );
              }
            }}
            placeholder={"All Bands"}
            width="110px"
          />
          <MultiSelectDropDown
            options={AllModesOption}
            value={mode}
            onSelectValue={(selectedList) => {
              setMode(selectedList);
              modeChange && modeChange(selectedList);
              setFilters((currentFilters: any) =>
                updateFilter(currentFilters, "mode", selectedList)
              );
            }}
            onRemoveValue={(selectedList) => {
              setMode(selectedList);
              modeChange && modeChange(selectedList);

              if (selectedList.length === 0) {
                setFilters(filters.filter((f: any) => !f.mode));
              } else {
                setFilters((currentFilters: any) =>
                  updateFilter(currentFilters, "mode", selectedList)
                );
              }
            }}
            placeholder={"All Modes"}
            className="w-[110px]"
            width="110px"
          />
          <MultiSelectDropDown
            options={countryOptions}
            value={country}
            onSelectValue={(selectedList) => {
              setCountry(selectedList);
              setFilters((currentFilters: any) =>
                updateFilter(currentFilters, "myCountry", selectedList)
              );
            }}
            onRemoveValue={(selectedList) => {
              setCountry(selectedList);
              if (selectedList.length === 0) {
                setFilters(filters.filter((f: any) => !f.myCountry));
              } else {
                setFilters((currentFilters: any) =>
                  updateFilter(currentFilters, "myCountry", selectedList)
                );
              }
            }}
            placeholder={"Country"}
            width="210px"
          />
          {country && country.length === 1 ? (
            <RegionDropdown
              country={country[0].name}
              value={region}
              blankOptionLabel="Region"
              defaultOptionLabel="Region"
              onChange={(txt) => {
                setRegion(txt);
                setFilters((currentFilters: any) =>
                  updateFilter(currentFilters, "theirState", txt)
                );
              }}
              classes={`bg-[#23234C] text-white font-[Barlow] text-sm ml-3 mb-2 w-[100px] h-[30px]`}
            />
          ) : null}

          <div
            className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[210px] h-[30px] flex items-center justify-center ml-3 mb-1`}
          >
            <span className="font-[Play] mr-2">Operating Near Me</span>
            <Switch
              onChange={() => {
                setOperatingNearMe(!operatingNearMe);
                if (operatingNearMe) {
                  setFilters(
                    filters.filter((f: any) => f.operatingNearMe !== true)
                  );
                } else {
                  setFilters([...filters, { operatingNearMe: true }]);
                }
              }}
              checked={operatingNearMe}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              onColor="#7B61FF"
              width={30}
            />
          </div>
          <div
            className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[110px] h-[30px] flex items-center justify-center ml-3 mb-1`}
          >
            <span className="font-[Play] mr-2">Logged</span>
            <Switch
              onChange={() => {
                setLogged(!logged);
                if (!logged) {
                  setFilters(filters.filter((f: any) => f.logged !== false));
                } else {
                  setFilters([...filters, { logged: false }]);
                }
              }}
              checked={logged}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              onColor="#7B61FF"
              width={30}
            />
          </div>
        </div>
      </div>
      <div className="w-[100%] md:w-[50%]">
        <div className="w-full flex justify-center mb-2">
          <span className="font-[Barlow] font-bold text-xs">Spotted In:</span>
        </div>
        <div className="flex flex-wrap">
          <MultiSelectDropDown
            options={countryOptions}
            value={spottedCountry}
            onSelectValue={(selectedList) => {
              setSpottedCountry(selectedList);
              setFilters((currentFilters: any) =>
                updateFilter(currentFilters, "theirCountry", selectedList)
              );
            }}
            onRemoveValue={(selectedList) => {
              setSpottedCountry(selectedList);
              if (selectedList.length === 0) {
                setFilters(filters.filter((f: any) => !f.theirCountry));
              } else {
                setFilters((currentFilters: any) =>
                  updateFilter(currentFilters, "theirCountry", selectedList)
                );
              }
            }}
            placeholder={"Country"}
            width="210px"
          />
          {/* <CountryDropdown
            value={spottedCountry}
            defaultOptionLabel="Country"
            onChange={(txt) => {
              setSpottedCountry(txt);
              setFilters((currentFilters) =>
                updateFilter(currentFilters, "theirCountry", txt)
              );
            }}
            classes={`bg-[#23234C] text-white font-[Barlow] text-sm ml-3 mb-2 w-[110px] h-[30px]`}
          /> */}
          {spottedCountry && spottedCountry.length === 1 ? (
            <RegionDropdown
              country={spottedCountry[0].name}
              value={spottedRegion}
              blankOptionLabel="Region"
              defaultOptionLabel="Region"
              onChange={(txt) => {
                console.log("REGION SEARCH TEXT : ", txt);
                setSpottedRegion(txt);
                setFilters((currentFilters: any) =>
                  updateFilter(
                    currentFilters,
                    "theirRegion",
                    getStateShortCode(txt)
                  )
                );
              }}
              classes={`bg-[#23234C] text-white font-[Barlow] text-sm ml-3 mb-2 w-[100px] h-[30px]`}
            />
          ) : null}
          <div
            className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[135px] h-[30px] flex items-center justify-center ml-3 mb-1`}
          >
            <span className="font-[Play] mr-2">DX Only</span>
            <Switch
              onChange={() => {
                setDxOnly(!dxOnly);

                if (dxOnly) {
                  setFilters(filters.filter((f: any) => f.dxOnly !== true));
                } else {
                  setFilters([...filters, { dxOnly: true }]);
                }
              }}
              checked={dxOnly}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              onColor="#7B61FF"
              width={30}
            />
          </div>
          <div
            className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[210px] h-[30px] flex items-center justify-center ml-3 mb-1`}
          >
            <span className="font-[Play] mr-2">Spotted Near Me</span>
            <Switch
              onChange={() => {
                setSpottedNearMe(!spottedNearMe);
                if (spottedNearMe) {
                  setFilters(
                    filters.filter((f: any) => f.spottedNearMe !== true)
                  );
                } else {
                  setFilters([...filters, { spottedNearMe: true }]);
                }
              }}
              checked={spottedNearMe}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              onColor="#7B61FF"
              width={30}
            />
          </div>
          <div
            className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[210px] h-[30px] flex items-center justify-center ml-3 mb-1`}
          >
            <span className="font-[Play] mr-2">POTA Only</span>
            <Switch
              onChange={() => {
                setPotaOnly(!potaOnly);
                if (potaOnly) {
                  setFilters(filters.filter((f: any) => f.potaOnly !== true));
                } else {
                  setFilters([...filters, { potaOnly: true }]);
                }
              }}
              checked={potaOnly}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              onColor="#7B61FF"
              width={30}
            />
          </div>
          <div
            className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[210px] h-[30px] flex items-center justify-center ml-3 mb-1`}
          >
            <span className="font-[Play] mr-2">WRL Only</span>
            <Switch
              onChange={() => {
                setWrlOnly(!wrlOnly);
                if (wrlOnly) {
                  setFilters(filters.filter((f: any) => f.wrlOnly !== true));
                } else {
                  setFilters([...filters, { wrlOnly: true }]);
                }
              }}
              checked={wrlOnly}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              onColor="#7B61FF"
              width={30}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex w-full justify-end items-center">
        <div className="mr-2 w-[150px]">
          <BorderButtonPrimary
            text={`Apply Filters (${filters.length})`}
            onClick={applyFilter}
            className="sm:mb-0 cursor-pointer"
            height="h-8"
            childClassName="text-xs"
          />
        </div>
        <div className="w-[150px]">
          <BorderButtonPrimary
            text={`Clear Filters`}
            onClick={clearFilters}
            className="sm:mb-0 cursor-pointer"
            height="h-8"
            childClassName="text-xs"
          />
        </div>
      </div>
    </div>
  );
}

export default SpottingFilters;
