import { collection, getDocs, query, where, addDoc, Timestamp, orderBy, QueryConstraint, getDoc, doc, onSnapshot, Unsubscribe, limit, DocumentReference, serverTimestamp } from "firebase/firestore";
import { db } from "firebase-config";

export enum UserActivityTypeEnum {
  BULK_EDIT = "bulkEdit",
  BULK_DELETE = "bulkDelete",
  MERGE_LOGBOOK = "mergeLogbook",
  IMPORT_ADI_FILE = "importAdiFile",
  SINGLE_EDIT = "singleEdit",      // For editing a single contact
  SINGLE_DELETE = "singleDelete",  // For deleting a single contact
  EXPORT_LOGBOOK = "exportLogbook", // For exporting a logbook
  EXPORT_CONTACTS = "exportContacts", // For exporting contacts
  EXPORT_ALL_CONTACTS = "exportAllContacts", // For exporting all contacts
}


interface UserActivityType {
  bulkEditContacts(userId: string, contactIds: string[], changes: object): Promise<void>;
  bulkDeleteContacts(userId: string, contactIds: string[]): Promise<void>;
  mergeLogbooks(userId: string, sourceLogbookId: string, targetLogbookId: string): Promise<void>;
  importAdiFile(data: object): Promise<any | null>;
  logActivity(activityData: object): Promise<DocumentReference | null>;
  getAllUserActivities(userId: string, filters?: { logbookId?: string; status?: string; activityType?: string; orderByField?: string; order?: "asc" | "desc"; }, callback?: (activityData: any) => void): Unsubscribe | null;
  getActivityDetailsById(activityId: string, callback: (activityData: any) => void): any;
}

const UserActivityService: UserActivityType = {
  async bulkEditContacts(userId, contactIds, changes) {
    try {
      // Perform bulk edit logic (modify the contacts here)
      // Track activity after the operation
      await this.logActivity({
        userId,
        operationType: UserActivityTypeEnum.BULK_EDIT,
        contactIds,
        changes,
        timestamp: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
      });
      console.log("Bulk edit contacts logged");
    } catch (error) {
      console.error("Error during bulk edit:", error);
    }
  },

  async bulkDeleteContacts(userId, contactIds) {
    try {
      // Perform bulk delete logic (delete the contacts here)
      // Track activity after the operation
      await this.logActivity({
        userId,
        operationType: UserActivityTypeEnum.BULK_DELETE,
        contactIds,
        timestamp: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
      });
      console.log("Bulk delete contacts logged");
    } catch (error) {
      console.error("Error during bulk delete:", error);
    }
  },

  async mergeLogbooks(userId, sourceLogbookId, targetLogbookId) {
    try {
      // Perform the logbook merge logic here
      // Track activity after the operation
      await this.logActivity({
        userId,
        operationType: UserActivityTypeEnum.MERGE_LOGBOOK,
        sourceLogbookId,
        targetLogbookId,
        timestamp: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
      });
      console.log("Logbook merge logged");
    } catch (error) {
      console.error("Error during logbook merge:", error);
    }
  },

  async importAdiFile(data) {
    try {
      // Perform ADI file import logic (parse and save contacts to db)
      // Track activity after the operation
      return await this.logActivity({
        ...data,
        operationType: UserActivityTypeEnum.IMPORT_ADI_FILE,
        timestamp: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
      });
    } catch (error) {
      return null;
    }
  },

  async logActivity(activityData) {
    try {
      const activityRef = collection(db, "UserActivity");
      return await addDoc(activityRef, activityData);
    } catch (error) {
      return null;
    }
  },
    // New function to get all user activities with optional filters
    getAllUserActivities(userId, filters = {}, callback) {
      try {
        // Base query for UserActivity collection
        const activityRef = collection(db, "UserActivity");
  
        // Build query constraints based on filters provided
        const queryConstraints: QueryConstraint[] = [where("userId", "==", userId)];
  
        if (filters.logbookId) {
          queryConstraints.push(where("logbookId", "==", filters.logbookId));
        }
  
        if (filters.status) {
          queryConstraints.push(where("status", "==", filters.status));
        }
  
        if (filters.activityType) {
          queryConstraints.push(where("activityType", "==", filters.activityType));
        }
  
        // Apply ordering if specified
        if (filters.orderByField) {
          queryConstraints.push(orderBy(filters.orderByField, filters.order || "asc"));
        }
  
        // Build the query
        const q = query(activityRef, ...queryConstraints);
  

        // Set up a real-time listener
        const unsubscribe = onSnapshot(q, (snapshot) => {
          if (!snapshot.empty) {
            const activities = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            callback && callback(activities);
          } else {
            callback && callback(null);
          }
        });
    
        // Optionally return the unsubscribe function if you want to stop listening later
        return unsubscribe;
  
      } catch (error) {
        console.error("Error fetching user activities:", error);
        return null;
      }
    },
    getActivityDetailsById(activityId: string, callback: (activityData: any) => void) {
      try {
        const activityRef = doc(db, "UserActivity", activityId); // Get a reference to the specific activity document
    
        // Set up a real-time listener
        const unsubscribe = onSnapshot(activityRef, (activityDoc) => {
          if (activityDoc.exists()) {
            const activityData = { id: activityDoc.id, ...activityDoc.data() };
            callback(activityData);
            // Do something with the activity data (e.g., update state in a React component)
          } else {
            console.log("No such activity found");
            callback(null);
          }
        });
    
        // Optionally return the unsubscribe function if you want to stop listening later
        return unsubscribe;
      } catch (error) {
        console.error("Error setting up activity listener:", error);
        return null;
      }
    }
};

export default UserActivityService;
