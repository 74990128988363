import { AuthorizationState } from "types/State";
import {
  AuthorizationActionPayload,
  SAVE_DISCOURSE_PARAMS,
  CALLSIGN_ASSISTANT_MODAL,
} from "store/Actions/authorization.actions";
import { LOGGED_IN_USER, RESET_IN_USER } from "constants/ActionTypes";
import { RootState } from "store/Reducers";
import { DISCOURSE_LOADING } from "store/Actions/loading.action";
const defaultState: AuthorizationState = {
  authFirstName: null,
  authLastName: null,
  discourseParams: null,
  discourseLoading: false,
  callSignAssistantModal: false,
};

export default function authorizationReducer(
  state = defaultState,
  action: AuthorizationActionPayload
) {
  switch (action.type) {
    case LOGGED_IN_USER:
      return {
        ...state,
        authFirstName: action.payload.authFirstName,
        authLastName: action.payload.authLastName,
      };
    case RESET_IN_USER:
      return {
        ...state,
        authFirstName: null,
        authLastName: null,
      };
    case SAVE_DISCOURSE_PARAMS:
      return {
        ...state,
        discourseParams: action.payload,
      };
    case DISCOURSE_LOADING:
      return {
        ...state,
        discourseLoading: action.payload,
      };
    case CALLSIGN_ASSISTANT_MODAL:
      return {
        ...state,
        callSignAssistantModal: action.payload,
      };

    default:
      return state;
  }
}

// GET STATE
export const getSavedUserInfoSelector = (state: RootState) => {
  return {
    firstName: state.authorization.authFirstName,
    lastName: state.authorization.authLastName,
  };
};

export const getDiscourseParams = (state: RootState) => {
  return state.authorization.discourseParams;
};

export const getDiscourseLoadingSelector = (state: RootState) => {
  return state.authorization.discourseLoading;
};

export const getCallSignAssistantModalSelector = (state: RootState) => {
  return state.authorization.callSignAssistantModal;
};
