import React, { useEffect, useRef, useState } from "react";
import Icons from "assets/Icons";
import Header from "components/Header";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getIsLogBookLoadingSelector } from "store/Reducers/loading.reducer";
import Images from "assets/Images";

import {
  FaArrowLeft,
  FaCopy,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaDownload
} from "react-icons/fa6";
import ReactCountryFlag from "react-country-flag";
import ContactMap from "components/Map/ContactMap";
import ContactInfo from "./ContactInfo";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import ConfirmationInfo from "./ConfirmationInfo";
import { LogBookContactModel } from "types/Models";
import { findCountryCode } from "helpers/Utils";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { getContactDetailSelector } from "store/Reducers/logBook.reducer";
import {
  fetchContactDetail,
  selectEditContactModalData,
} from "store/Actions/logBook.actions";
import RouteLoading from "components/Loading/RouteLoading";
import useUpload from "hooks/upload.hook";
import { setLoadingImageUploadStatus } from "store/Actions/loading.action";
import { UserAvatar } from "components/UserAvatar";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import ContactDetailsTable from "components/Table/Table/ContactDetailsTable";
import { PERMISSIONS } from "constants/Permissions";
import AccessControl from "components/AccessControl";
import CountryDxccFlag from "components/CountryDxccFlag";

function ContactDetail() {
  // const { userProfile } = useProfile();
  const dispatch = useDispatch();
  const location = useLocation();
  const contactDivRef = useRef(null);

  const [contactData, setContactData] = useState<LogBookContactModel>();
  const userProfile = useSelector(getUserProfileSelector);
  const [isLoading, setIsLoading] = useState(useSelector(getIsLogBookLoadingSelector));
  const [isGuest, setIsGuest] = useState(true);
  const [isCapturing, setIsCapturing] = useState(false);

  const {
    isLoadingImg,
    shareOnSocialMedia,
    captureInProgress,
    setCaptureInProgress,
    onDownloadClick,
    captureSnapshot
  } = useUpload();

  const contactDetail = useSelector(getContactDetailSelector);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(setLoadingImageUploadStatus(false));
    setCaptureInProgress(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("contactId")) {
      if (location && location.state) {
        setIsLoading(false);
        setContactData(location.state);
        if (userProfile && userProfile.callSign === location.state.myCallSign) {
          setIsGuest(false);
        } else {
          setIsGuest(true);
        }
      } else {
        setIsLoading(true);
      }

      dispatch(fetchContactDetail(searchParams.get("contactId")!));
    } else {
      window.history.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contactDetail) {
      setIsLoading(false);
      setContactData(contactDetail);
      if (userProfile && userProfile.callSign === contactDetail.myCallSign) {
        setIsGuest(false);
      } else {
        setIsGuest(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetail]);

  const updatedContactData = {
    ...contactData,
    myLatitude: contactData?.myCoordinates?.latitude,
    myLongitude: contactData?.myCoordinates?.longitude,
    theirLatitude: contactData?.theirCoordinates?.latitude,
    theirLongitude: contactData?.theirCoordinates?.longitude,
    logBook: contactData?.logBook?.name,
    myParks: Object.keys(contactData?.myParks || {}).join(", "),
  };

  const contactDataKeyValue = Object.keys(updatedContactData)
    .sort()
    .filter(
      (key) =>
        updatedContactData?.[key as keyof LogBookContactModel] &&
        ![
          "confirmationData",
          "callSignSearchIndex",
          "myProfilePic",
          "nameSearchIndex",
          "theirProfilePic",
          "timestamp",
          "myCoordinates",
          "theirCoordinates",
        ].includes(key)
    )
    .map((key) => {
      return {
        key,
        value:
          typeof updatedContactData?.[key as keyof LogBookContactModel] ===
            "object" ||
          typeof updatedContactData?.[key as keyof LogBookContactModel] ===
            "boolean"
            ? JSON.stringify(
                updatedContactData?.[key as keyof LogBookContactModel]
              )
            : updatedContactData?.[key as keyof LogBookContactModel] || "--",
      };
    });

  const renderContactDetailsTable = () => {
    return (
      <div className="flex flex-col w-[95%]">
        <div className="flex flex-col">
          <div className="flex justify-start w-[95%] my-10">
            <div className="flex flex-col">
              <h1 className="text-[#17F9DA] text-[25px] font-bold">
                Contact Details
              </h1>
              <p className="text-[#17F9DA] text-[14px]">
                Contact by {contactData?.myCallSign} on {contactData?.date}{" "}
                {contactData?.time} UTC
              </p>
            </div>
          </div>

          <div className="flex flex-wrap gap-x-10">
            {contactDataKeyValue.map((item) => (
              <div className="flex mb-2" key={item.key}>
                <p className="font-bold text-white text-[15px] mr-3 md:mr-10 w-[100px]">
                  {item.key}
                </p>
                <p className=" text-white text-[13px] bg-[#292B6A] overflow-hidden w-[250px] pl-2 py-1">
                  {item.value as string}
                </p>
              </div>
            ))}
          </div>

          {/* <div className="flex flex-wrap gap-x-10 mt-10 w-[50%]">
        <ContactDetailsTable list={contactDataKeyValue} />
      </div> */}
        </div>
      </div>
    );
  };

  const renderContactDetails = () => {
    if (captureInProgress) return null;
    if (!isGuest) return renderContactDetailsTable();
    return (
      <AccessControl
        permission={PERMISSIONS.superAdmin}
        children={renderContactDetailsTable()}
      />
    );
  };

  const handleDownload = async (contactDivRef: any) => {
    try {
      const url = await captureSnapshot(contactDivRef, true);
      onDownloadClick(`WorldRadioLeague_SingleContact_${contactData?.theirCallsign || ""}`, url);
    } catch (error) {
      console.error("Error capturing snapshot:", error);
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full font-[Play] text-white">
      <Header title="Contact" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <div className="flex justify-start">
          <div
            className="flex items-center mb-1 cursor-pointer"
            onClick={() => {
              window.history.back();
              // captureSnapshot(contactDivRef);
            }}
          >
            <FaArrowLeft />
            <p className="ml-2 font-bold">Back</p>
          </div>
        </div>
        <div
          className={`relative w-full bg-[#1A1C5F] flex flex-col items-center z-0  ${
            captureInProgress ? "pt-20 px-10 pb-5" : "pb-20"
          }`}
          ref={contactDivRef}
        >
          <div className="absolute top-0 left-0 z-0">
            <img
              src={Images.Globe}
              className="w-[60%]  object-cover filter brightness-10"
              alt="globe"
            />
          </div>
          <div className="flex justify-center items-center z-10">
            <img
              src={Icons.MainLogo}
              className="w-[50px] h-[50px]"
              alt="logo"
            />
            <p className="text-white font-[Play] text-base font-bold">
              www.WorldRadioLeague.com
            </p>
          </div>
          {captureInProgress || isLoadingImg ? null : (
            <div className="flex justify-start w-full mb-2">
              <div className="flex items-center px-4 rounded-[10px] w-full md:w-[440px] z-10">
                <p className="text-white mr-1">Share to Social Media:</p>
                {isGuest || captureInProgress || isLoadingImg ? null : (
                  <>
                    <div
                      className="w-[25px] h-[25px] rounded-[4px] bg-[#1877F2] flex items-center justify-center mr-2 cursor-pointer"
                      onClick={() => {
                        shareOnSocialMedia(
                          contactDivRef,
                          "facebook",
                          contactData?.id,
                          contactData?.shareImage
                        );
                      }}
                    >
                      <FaFacebook size={16} color="#fff" />
                    </div>
                    <div
                      className="w-[25px] h-[25px] rounded-[4px] bg-[#1DA1F2] flex items-center justify-center mr-2 cursor-pointer"
                      onClick={() => {
                        shareOnSocialMedia(
                          contactDivRef,
                          "twitter",
                          contactData?.id,
                          contactData?.shareImage
                        );
                      }}
                    >
                      <FaTwitter size={16} color="#fff" />
                    </div>
                    <div
                      className="w-[25px] h-[25px] rounded-[4px] bg-[#2867B2] flex items-center justify-center mr-2 cursor-pointer"
                      onClick={() => {
                        shareOnSocialMedia(
                          contactDivRef,
                          "linkedin",
                          contactData?.id,
                          contactData?.shareImage
                        );
                      }}
                    >
                      <FaLinkedin size={16} color="#fff" />
                    </div>
                  </>
                )}
                <div
                  className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                  onClick={() => {
                    shareOnSocialMedia(
                      contactDivRef,
                      "linkcopy",
                      contactData?.id,
                      contactData?.shareImage
                    );
                  }}
                >
                  <span className="text-[10px] mr-1 text-white">Copy Link</span>
                  <FaCopy size={16} color="#fff" />
                </div>
                <div
                  className="w-[120px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                  onClick={async () => {
                   setIsCapturing(true);
                   await handleDownload(contactDivRef)
                   setIsCapturing(false);
                  }}
                >
                  <span className="text-[10px] mr-1 text-white">
                    Download Image
                  </span>
                  <FaDownload size={16} color="#fff" />
                </div>
              </div>
            </div>
          )}

          <div className="relative w-[95%] h-[130%] md:h-[80%] bg-gradient-to-tr from-[#64E3FF]/60 to-[#3C1CFD]/60 rounded-xl z-10 shadow pb-20 md:pb-5">
            {isLoading || isLoadingImg ? (
              <div className="h-[500px]">
                <RouteLoading
                  noBg
                  text="Calibrating the signal for your link..."
                />
              </div>
            ) : (
              <>
                {isGuest || captureInProgress ? null : (
                  <div className="absolute -top-10 md:top-2 md:right-5 right-1">
                    {contactData ? (
                      <div className="flex justify-end w-full mb-5">
                        <div className="w-[70px]">
                          <BorderButtonSecondary
                            text={`Edit`}
                            height="h-6"
                            onClick={() => {
                              dispatch(selectEditContactModalData(contactData));
                            }}
                            className="sm:mb-0 cursor-pointer opacity-75"
                            childClassName={"text-[13px]"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}

                <h1 className="text-center mt-5 md:mt-0 mb-5 md:mb-1 text-shadow font-[Barlow] text-white text-[30px] md:text-[50px] font-bold ">
                  {contactData?.date} {contactData?.time} UTC
                </h1>
                <div className="flex justify-evenly items-center flex-wrap md:flex-auto responsive-flex md:px-10">
                  <div
                    className="bg-[#3E6EFF] flex rounded-xl px-6 py-1 shadow-md cursor-pointer"
                    onClick={() => {
                      if (
                        contactData?.myCallSign ||
                        contactData?.profileCallSign
                      ) {
                        window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                          contactData?.myCallSign ||
                            contactData?.profileCallSign ||
                            ""
                        )}?tab=Logbook&contact-tab=entries`;
                      }
                    }}
                  >
                    {contactData?.myProfilePic ? (
                      <img
                        src={contactData?.myProfilePic}
                        className="w-[40px] h-[40px] rounded-full shadow-md"
                        alt="profile"
                      />
                    ) : (
                      <UserAvatar className="w-[40px] h-[40px]" />
                    )}

                    <div className="ml-3">
                      <div className="flex items-center">
                        <CountryDxccFlag
                          countryCode={contactData?.myCountry || ""}
                          flagCode={""}
                        />
                        <p className="text-[15px] text-shadow ml-2">
                          {contactData?.myCallSign ||
                            contactData?.profileCallSign}
                        </p>
                      </div>
                      <p className="text-[13px] text-shadow">
                        {`${contactData?.myName || ""}`}
                      </p>
                    </div>
                  </div>
                  <h1 className="text-[#17F9DA] text-xl font-bold my-3">
                    QSO with
                  </h1>
                  <div
                    className="bg-[#3E6EFF] flex rounded-xl px-6 py-1 shadow-md cursor-pointer"
                    onClick={() => {
                      if (contactData?.theirCallsign) {
                        window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                          contactData?.theirCallsign || ""
                        )}?tab=Logbook&contact-tab=entries`;
                      }
                    }}
                  >
                    {contactData?.theirProfilePic ? (
                      <img
                        src={contactData?.theirProfilePic}
                        className="w-[40px] h-[40px] rounded-full shadow-md"
                        alt="profile"
                      />
                    ) : (
                      <UserAvatar className="w-[40px] h-[40px]" />
                    )}
                    <div className="ml-3">
                      <div className="flex items-center">
                        <CountryDxccFlag
                          countryCode={
                            contactData?.country ||
                            contactData?.theirCountry ||
                            ""
                          }
                          flagCode={contactData?.flagCode || ""}
                        />
                        <p className="text-[15px] text-shadow ml-2">
                          {contactData?.theirCallsign}
                        </p>
                      </div>
                      <p className="text-[13px] text-shadow">
                        {contactData?.theirName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center mt-4 items-center">
                  <div className="w-[15%]"></div>
                  <div className="w-[90%] md:w-[60%] transparent-white h-[250px] md:h-[300px] rounded-xl border border-[#fff] flex items-center justify-center mb-4 md:mb-0">
                    <ContactMap
                      className="w-[98%] h-[240px] md:h-[290px] relative rounded-xl overflow-hidden ml-1"
                      containerClassName="w-full"
                      showControls={false}
                      contactDetail={true}
                      mapControls={{
                        streetViewControl: false,
                        rotateControl: false,
                        mapTypeControl: false,
                        fullscreenControl: !isCapturing,
                        zoomControl: !isCapturing,
                        scrollwheel: true,
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2 md:flex md:flex-col ml-10 md:ml-4 w-full md:w-auto">
                    <ContactInfo
                      icon={Icons.Freq}
                      value={`${contactData?.frequency || ""} MHz`}
                    />
                    <ContactInfo
                      icon={Icons.Band}
                      value={contactData?.band || "--"}
                    />
                    <ContactInfo
                      icon={Icons.Mode}
                      value={contactData?.userMode || "--"}
                    />
                    {contactData?.distance ? (
                      <ContactInfo
                        icon={Icons.ContactDistance}
                        value={
                          contactData?.distance
                            ? `${contactData?.distance.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }
                              )} KM`
                            : "--"
                        }
                      />
                    ) : null}

                    {contactData?.power ? (
                      <ContactInfo
                        icon={Icons.Power}
                        value={`${
                          contactData?.power ? contactData?.power : "--"
                        }W`}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-center mt-2 px-10">
                  {contactData?.myRadio ? (
                    <p className="text-black">
                      <b>Radio: </b>
                      {contactData?.myRadio || "--"}
                    </p>
                  ) : null}
                  {contactData?.myAntenna ? (
                    <p className="ml-4 text-black">
                      <b>Antenna: </b> {contactData?.myAntenna || "--"}
                    </p>
                  ) : null}
                </div>
                {contactData?.notes ? (
                  <div className="flex justify-center mt-2 px-10">
                    <p className="text-black text-[12px]">
                      <b>Comment: </b>
                      {contactData?.notes}
                    </p>
                  </div>
                ) : null}

                {contactData?.confirmed && contactData?.confirmationData ? (
                  <div className="flex justify-center mt-3">
                    <div className="rounded-full text-white w-[90%] md:w-[80%] gradient-inset-shadow flex py-1 px-5 md:px-2 justify-center">
                      <img
                        src={Icons.Confirmed}
                        alt="confirmed icon"
                        className="mr-2"
                      />
                      <p className="text-shadow text-center">
                        <b className="mr-1">Contact Confirmed</b>
                        <span className="text-[#17F9DA] mr-1">by</span>
                        <span className="mr-1">
                          {contactData?.confirmationData.myCallSign}
                        </span>
                        <span className="text-[#17F9DA] mr-1">on</span>
                        <span className="mr-1">
                          {contactData?.confirmationData.date}{" "}
                          {contactData?.confirmationData.time}
                        </span>
                        <span className="mr-1 text-[#17F9DA]">UTC</span>
                      </p>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>

          {contactData?.confirmed &&
          contactData?.confirmationData &&
          !captureInProgress ? (
            <>
              <div className="flex justify-start w-[95%] mt-10">
                <div className="flex flex-col">
                  <h1 className="text-[#17F9DA] text-[25px] font-bold">
                    Confirmation Details
                  </h1>
                  <p className="text-[#17F9DA] text-[14px]">
                    Contact Confirmed by{" "}
                    {contactData?.confirmationData?.myCallSign} on{" "}
                    {contactData?.confirmationData?.date}{" "}
                    {contactData?.confirmationData?.time} UTC
                  </p>
                </div>
              </div>
              <div className="flex justify-start w-[95%] mt-10 flex-wrap">
                <div className="flex flex-col mr-10">
                  <ConfirmationInfo
                    title="Logged By"
                    value={contactData?.confirmationData.myCallSign || ""}
                  />
                  <ConfirmationInfo
                    title="Contact Time"
                    value={`${contactData?.confirmationData?.date} ${contactData?.confirmationData.time} UTC`}
                  />
                  {/* <ConfirmationInfo
                    title="Last Updated Time"
                    value="30/01/2024 9:23 UTC"
                  /> */}
                  <ConfirmationInfo title="Source" value="WRL Logbook" />
                  <ConfirmationInfo
                    title="RST Sent"
                    value={contactData?.confirmationData.rstSent}
                  />
                  <ConfirmationInfo
                    title="RST Rcvd"
                    value={contactData?.confirmationData.rstRCVD}
                  />
                  <ConfirmationInfo
                    title="Frequency"
                    value={`${
                      contactData?.confirmationData?.frequency
                        ? contactData?.confirmationData?.frequency
                        : ""
                    } MHz`}
                  />
                  <ConfirmationInfo
                    title="Band"
                    value={contactData?.confirmationData.band}
                  />
                  <ConfirmationInfo
                    title="Mode"
                    value={contactData?.confirmationData.userMode}
                  />
                  {contactData?.confirmationData.power ? (
                    <ConfirmationInfo
                      title="Power(W)"
                      value={`${contactData?.confirmationData.power}W`}
                    />
                  ) : null}
                </div>
                <div className="flex flex-col">
                  <ConfirmationInfo
                    title="My Callsign"
                    value={contactData?.confirmationData.theirCallsign}
                  />

                  {contactData?.confirmationData.userQth ? (
                    <ConfirmationInfo
                      title="QTH"
                      value={`${contactData?.confirmationData.userQth}`}
                    />
                  ) : null}
                  {contactData?.confirmationData.myRadio ? (
                    <ConfirmationInfo
                      title="Radio"
                      value={`${contactData?.confirmationData.myRadio}`}
                    />
                  ) : null}
                  {contactData?.confirmationData.myAntenna ? (
                    <ConfirmationInfo
                      title="Antenna"
                      value={`${contactData?.confirmationData.myAntenna}`}
                    />
                  ) : null}
                  {contactData?.confirmationData.grid ? (
                    <ConfirmationInfo
                      title="Grid Location"
                      value={`${contactData?.confirmationData.grid}`}
                    />
                  ) : null}
                  {contactData?.confirmationData.notes ? (
                    <ConfirmationInfo
                      title="Notes"
                      value={`${contactData?.confirmationData.notes}`}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {renderContactDetails()}
      </div>
    </main>
  );
}

export default ContactDetail;
