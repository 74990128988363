import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { mergeLogbooks } from "firebase-config";
import { convertSecondToDate } from "helpers/Date/DareConverter";
import { useEditContact } from "hooks/useEditContact.hook";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mergeLogBookContacts } from "store/Actions/logBook.actions";
import {
  getLogBookListSelector,
  getMergeLogbookSelector,
} from "store/Reducers/logBook.reducer";
import { LogBookModel } from "types/Models";

function MergeLogbookModal() {
  const dispatch = useDispatch();
  const showModal = useSelector(getMergeLogbookSelector);
  const logBooksList: LogBookModel[] = useSelector(getLogBookListSelector);
  const [logbookId, setlogbookId] = useState<string>("");
  const { bulkUpdateContact } = useEditContact();

  const logbookOptions = useMemo(
    () =>
      logBooksList.map((logbook) => ({
        label: `${logbook.name} \u00A0\u00A0|\u00A0\u00A0 ${convertSecondToDate(
          logbook?.timestamp?.seconds as any
        )} \u00A0\u00A0|\u00A0\u00A0 ${logbook?.contactCount || 0} QSOs`,
        value: logbook.id,
      })).filter((logbook) => {
        if (typeof showModal === "object" && !showModal?.id)
          return true;

        return typeof showModal === "object" && logbook.value !== showModal?.id;
      }),
    [logBooksList, showModal]
  );

  if (!showModal) return null;

  const selectedRows = showModal?.selectedRows || [];
  const qsoFilters = showModal?.qsoFilters || {};
  const isBulkMerge = showModal?.isBulkMerge || false;
  const allSelected = showModal?.allSelected || false;
  const totalQSOs = showModal?.totalQSOs || 0;

  const closeModal = () => {
    dispatch(mergeLogBookContacts(null));
    setlogbookId("");
  };

  const handleMergeLogbook = () => {
    mergeLogbooks({
      sourceLogBookId: showModal?.id,
      destinationLogBookId: logbookId,
    });

    closeModal();
  };

  const handleMoveContacts = () => {
    bulkUpdateContact({
      payload: {
        logBookId: logbookId,
      },
      selectedRows,
      qsoFilters,
    });

    closeModal();
  };

  return (
    <section
      className={`h-full w-full flex-grow overflow-x-auto absolute top-0 right-0 glassmorphism-secondary flex items-center justify-center`}
    >
      <div className="bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px] inline-flex relative w-[95vw] sm:w-auto sm:max-w-[600px] md:max-w-[800px] shadow-lg">
        <div className="bg-[#0B0B39] w-full h-full  px-8 sm:px-14 pt-10 pb-6">
          <div className="flex w-full flex-wrap relative">
            {isBulkMerge ? (
              <p className="font-[play] font-bold text-xl text-white mb-[26px]">
              Move <span className="text-[#17F9DA]">{ allSelected ? totalQSOs : selectedRows.length} QSOs</span> to Logbook:
              </p>
            ) : (
              <p className="font-[play] font-bold text-xl text-white mb-[26px]">
              Merge <span className="text-[#17F9DA]">{showModal?.name}</span> into:
              </p>
            )}

            <LogbookSettingDropdown
              list={logbookOptions}
              title=""
              value={logbookId || ""}
              setValue={(value) => {
                if (value === "add") {
                } else {
                  setlogbookId(value);
                }
              }}
              className="w-[80%] sm:w-[60%] md:w-[80%]"
            />
            { !isBulkMerge && <p className="text-white text-sm font-[Barlow]">The QSOs will be moved into the new logbook, and the old logbook will be removed.</p> }
          </div>

          <div className="flex w-full justify-center mt-[32px]">
            {isBulkMerge ? (
              <BorderButtonSecondary
                // isLoading={loading}
                text={"Move QSOs"}
                onClick={handleMoveContacts}
                childClassName="text-sm"
                className="w-[80%] sm:w-[50%] md:w-[50%] mt-3"
              />
            ) : (
              <BorderButtonSecondary
                // isLoading={loading}
                text={"Merge Logbook"}
                onClick={handleMergeLogbook}
                childClassName="text-sm"
                className="w-[80%] sm:w-[50%] md:w-[50%] mt-3"
              />
            )}
          </div>

          <div
            className="absolute top-2 right-2 cursor-pointer"
            onClick={closeModal}
            tabIndex={55}
          >
            <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MergeLogbookModal;
