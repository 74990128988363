import { LogBookContactModel } from "types/Models";
import { QueryParams } from "types/QSOManager.types";

export const FETCH_QSO_MANAGER_CONTACTS = "FETCH_QSO_MANAGER_CONTACTS";
export const SET_QSO_MANAGER_CONTACTS = "SET_QSO_MANAGER_CONTACTS";
export const DELETE_QSO_MANAGER_CONTACT = "DELETE_QSO_MANAGER_CONTACT";
export const REMOVE_QSO_MANAGER_CONTACT = "REMOVE_QSO_MANAGER_CONTACT";
export const SET_QSO_MANAGER_LOADING = "SET_QSO_MANAGER_LOADING";
export const SET_QSO_MANAGER_ERROR = "SET_QSO_MANAGER_ERROR";

export interface QSOManagerPayload {
    type: string;
    payload?: any;
}
export const fetchQSOManagerContacts = (queryParams: QueryParams) => ({
    type: FETCH_QSO_MANAGER_CONTACTS,
    payload: queryParams,
});

export const setQSOManagerContacts = (contacts: LogBookContactModel[]) => ({
    type: SET_QSO_MANAGER_CONTACTS,
    payload: contacts,
});

export const deleteQSOManagerContact = (contactId: string): QSOManagerPayload => ({
    type: DELETE_QSO_MANAGER_CONTACT,
    payload: contactId,
});

export const removeQSOManagerContact = (contactId: string): QSOManagerPayload => ({
    type: REMOVE_QSO_MANAGER_CONTACT,
    payload: contactId,
});

export const setQSOManagerLoading = (loading: boolean): QSOManagerPayload => ({
    type: SET_QSO_MANAGER_LOADING,
    payload: loading,
});

export const setQSOManagerError = (error: string): QSOManagerPayload => ({
    type: SET_QSO_MANAGER_ERROR,
    payload: error,
});