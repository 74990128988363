import { call, put, select, take } from "redux-saga/effects";
import ProfileService from "services/profile.service";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { identify } from "react-fullstory";
import {
  CHECK_CALLSIGN_AVAILABILITY,
  CHECK_USER_PROFILE,
  CoordinatesParams,
  EDIT_USER_PROFILE,
  SAVE_USER_COORDINATES,
  UPDATE_USER_PROFILE,
  FETCH_USER_AWARDS,
  SET_USER_AWARDS,
  IS_CALL_SIGN_AVAILABLE,
  SEND_WORDPRESS_LOGIN,
  SET_FEATURED_USER_AWARD,
  FETCH_FEATURED_MEMBERS,
  SET_FEATURED_MEMBERS,
  FETCH_AWARDS,
  SET_AWARDS,
  SET_USER_AWARDS_ADMIN,
  ADD_USER_AWARDS,
  SHOW_AWARD_MODAL,
  REMOVE_USER_AWARD,
  DISABLE_USER,
  DELETE_USER,
  ENABLE_USER,
  UPDATE_SUBSCRIPTION,
} from "store/Actions/profile.actions";
import {
  RESET_LOADING_STATUS,
  STORE_USER_PROFILE,
} from "constants/ActionTypes";
import { auth } from "firebase-config";
import { FeaturedMember, UserAwards, UserProfile } from "types/Models";
import { CREATE_USER_PROFILE } from "store/Actions/profile.actions";
import { globalNavigate } from "routes/GlobalRoutes";
import {
  getDiscourseParams,
  getSavedUserInfoSelector,
} from "store/Reducers/authorization.reducer";
import { serverTimestamp } from "firebase/firestore";
import { allErrorHandler } from "helpers/ErrorHandler";
import {
  ADMIN_CONTROL_LOADING,
  FEATURED_MEMBERS_LOADING,
  FETCH_AWARD_LOADING,
  LOADING_CALLSIGN_AVAILABILITY_STATUS,
  LOADING_PROFILE_STATUS,
  LOADING_STATUS_FOR_GETTING_LOCATION,
} from "store/Actions/loading.action";
import { UpdateUserProfile } from "types/State";
import UploadService from "services/upload.service";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import AuthService from "services/auth.service";

import { SET_PRIMARY_MODAL_DATA } from "store/Actions/config.actions";
import { createSearchIndex, sanitizeData } from "helpers/Utils";
import { CALLSIGN_ASSISTANT_MODAL } from "store/Actions/authorization.actions";

//Workers
export function* workerCheckUserProfile(payload: string) {
  try {
    yield put({
      type: LOADING_PROFILE_STATUS,
      payload: true,
    });

    const userProfile: UserProfile | null = yield call(() =>
      ProfileService.getUserProfileData(payload)
    );

    if (userProfile) {
      if (
        userProfile.firstName &&
        userProfile.lastName &&
        userProfile.bio &&
        userProfile?.callSign &&
        userProfile?.callSign?.length
      ) {
        identify(userProfile.callSign || "", {
          name: userProfile.firstName + " " + userProfile.lastName,
          email: userProfile.email || "",
          userId: userProfile.uid,
        });
        yield put({
          type: STORE_USER_PROFILE,
          payload: userProfile,
        });
      } else {
        let params: { sso: string; sig: string } | null = yield select(
          getDiscourseParams
        );

        let sso = "";
        let sig = "";
        if (params) {
          if (params.sso) {
            sso = params.sso;
          }
          if (params.sig) {
            sig = params.sig;
          }
        }

        globalNavigate(`/register/additional-info?sso=${sso}&sig=${sig}`, {
          state: userProfile,
        });
      }
      yield put({
        type: RESET_LOADING_STATUS,
        payload: false,
      });
    } else {
      yield put({
        type: CREATE_USER_PROFILE,
      });
    }
  } catch (e: any) {
    yield put({
      type: RESET_LOADING_STATUS,
      payload: false,
    });
    authErrorHandler(e);
  }
}

export function* createUserProfile() {
  try {
    let state: UserProfile = yield select(getSavedUserInfoSelector);

    const displayName = auth.currentUser?.displayName?.split(" ");
    if (displayName && displayName?.length >= 0) {
      state.firstName = displayName[0];
    }
    if (displayName && displayName?.length > 1) {
      state.lastName = displayName[1];
    }

    const firstSearchIndex = state?.firstName
      ? createSearchIndex(state.firstName.toLowerCase())
      : [];
    const lastSearchIndex = state?.lastName
      ? createSearchIndex(state.lastName.toLowerCase())
      : [];

    let fullNameSearchIndex: string[] = [];

    if (state?.firstName && state?.lastName) {
      const fullName = `${state.firstName} ${state.lastName}`;
      const startFrom =
        state.firstName && state.lastName
          ? state.firstName?.length + 2
          : undefined;
      fullNameSearchIndex = startFrom
        ? createSearchIndex(fullName.toLowerCase().trim(), startFrom)
        : [];
    }

    const nameSearchIndex = [
      ...firstSearchIndex,
      ...lastSearchIndex,
      ...fullNameSearchIndex,
    ];

    const userData: UserProfile = {
      email: auth.currentUser?.email ? auth.currentUser?.email : "",
      uid: auth.currentUser?.uid ? auth.currentUser?.uid : "",
      firstName: state?.firstName,
      lastName: state?.lastName,
      provideId: auth.currentUser?.providerData[0].providerId
        ? auth.currentUser?.providerData[0].providerId
        : "",
      bio: "",
      phoneNumber: "",
      callSign: "",
      nameSearchIndex,
      timestamp: serverTimestamp(),
    };

    const userCreated: boolean = yield call(() =>
      ProfileService.createUserProfileData(userData)
    );

    if (userCreated) {
      yield put({
        type: CHECK_USER_PROFILE,
        payload: auth.currentUser?.uid,
      });
    } else {
      authErrorHandler("Connection");
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* editUserProfile(payload: UserProfile) {
  try {
    yield put({
      type: LOADING_PROFILE_STATUS,
      payload: true,
    });
    const userCreated: boolean = yield call(() =>
      ProfileService.editUserProfileData(payload)
    );
    if (userCreated) {
      yield put({
        type: CHECK_USER_PROFILE,
        payload: auth.currentUser?.uid,
      });

      yield put({
        type: SEND_WORDPRESS_LOGIN,
        payload: null,
      });
    } else {
      authErrorHandler("something went wrong.");
      yield put({
        type: LOADING_PROFILE_STATUS,
        payload: false,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
    yield put({
      type: LOADING_PROFILE_STATUS,
      payload: false,
    });
  }
}

export function* updateUserProfile(payload: UpdateUserProfile) {
  try {
    yield put({
      type: LOADING_PROFILE_STATUS,
      payload: true,
    });
    let userData = { ...payload.user };
    let userProfile: UserProfile = yield select(getUserProfileSelector);

    const firstSearchIndex = userData?.firstName
      ? createSearchIndex(userData.firstName.toLowerCase())
      : [];
    const lastSearchIndex = userData?.lastName
      ? createSearchIndex(userData.lastName.toLowerCase())
      : [];

    let fullNameSearchIndex: string[] = [];

    if (userData?.firstName && userData?.lastName) {
      const fullName = `${userData.firstName} ${userData.lastName}`;
      const startFrom =
        userData.firstName && userData.lastName
          ? userData.firstName?.length + 2
          : undefined;
      fullNameSearchIndex = startFrom
        ? createSearchIndex(fullName.toLowerCase().trim(), startFrom)
        : [];
    }

    const nameSearchIndex = [
      ...firstSearchIndex,
      ...lastSearchIndex,
      ...fullNameSearchIndex,
    ];

    if (nameSearchIndex.length > 0) {
      userData.nameSearchIndex = nameSearchIndex;
    }

    const callsignSearchIndex = userData?.callSign
      ? createSearchIndex(userData?.callSign?.toLowerCase())
      : [];

    if (callsignSearchIndex.length > 0) {
      userData.callsignSearchIndex = callsignSearchIndex;
    }

    if (
      userData?.email !== userProfile?.email &&
      auth.currentUser &&
      userData?.email
    ) {
      const currentUser = auth.currentUser;
      const email = userData.email;
      yield call(() => AuthService.updateEmail(currentUser, email));
    }
    if (payload?.profilePic) {
      const profileImgUrl: null | string = yield call(() =>
        UploadService.handleUploadImage(payload.profilePic)
      );
      if (profileImgUrl) {
        const res: boolean = yield call(() =>
          ProfileService.editUserProfileData(
            sanitizeData({
              ...userData,
              profilePic: profileImgUrl ? profileImgUrl : "",
            })
          )
        );
        if (res === true) {
          const uid = auth.currentUser?.uid || "";
          const userProfile: UserProfile | null = yield call(() =>
            ProfileService.getUserProfileData(uid)
          );
          if (userProfile) {
            yield put({
              type: STORE_USER_PROFILE,
              payload: userProfile,
            });
            allErrorHandler("updated successfully");
          } else {
            allErrorHandler("Something went wrong");
          }
        } else {
          allErrorHandler("Something went wrong");
        }
      } else {
        allErrorHandler("Something went wrong");
      }
    } else {
      const res: boolean = yield call(() =>
        ProfileService.editUserProfileData(sanitizeData(userData))
      );
      if (res === true) {
        const uid = auth.currentUser?.uid || "";
        const userProfile: UserProfile | null = yield call(() =>
          ProfileService.getUserProfileData(uid)
        );
        if (userProfile) {
          yield put({
            type: STORE_USER_PROFILE,
            payload: userProfile,
          });
          allErrorHandler("updated successfully");
        } else {
          allErrorHandler("Something went wrong");
        }
      } else {
        allErrorHandler("Something went wrong");
      }
    }
  } catch (e: any) {
    console.log("UPDATE PROFILE ERROR : ", e);
    if (e?.code === "auth/requires-recent-login") {
      yield put({
        type: SET_PRIMARY_MODAL_DATA,
        payload: {
          title: "Login again to change your email address?",
          type: "Signout",
          submitText: "Signout",
          cancelText: "Cancel",
        },
      });
    } else {
      authErrorHandler(e);
    }
  }
  yield put({
    type: LOADING_PROFILE_STATUS,
    payload: false,
  });
}

export function* workerCoordinateChange(payload: CoordinatesParams) {
  try {
    yield call(() => ProfileService.storeCoordinate(payload));
  } catch (e: any) {}
  yield put({
    type: LOADING_STATUS_FOR_GETTING_LOCATION,
    payload: false,
  });
}

export function* workerFetchUserAward(payload: string) {
  try {
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: true,
    });
    const awards: UserAwards[] | null = yield call(() =>
      ProfileService.fetchUserAwards(payload)
    );
    if (awards) {
      yield put({
        type: SET_USER_AWARDS,
        payload: awards,
      });
    }
  } catch (e: any) {}
  yield put({
    type: FETCH_AWARD_LOADING,
    payload: false,
  });
}

export function* workerSetFeaturedUserAward(payload: UserAwards) {
  try {
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: true,
    });
    yield call(() => ProfileService.setFeaturedAward(payload));
    const uid: string | undefined = auth.currentUser?.uid;
    yield put({
      type: FETCH_USER_AWARDS,
      payload: uid,
    });
  } catch (e) {
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: true,
    });
  }
}

export function* workerCheckCallSignAvailability(payload: string) {
  try {
    yield put({
      type: LOADING_CALLSIGN_AVAILABILITY_STATUS,
      payload: true,
    });
    const callSignResponse: { available: boolean; data: UserProfile } =
      yield call(() => ProfileService.checkCallSignAvailability(payload));
    console.log("callSignResponse", callSignResponse);
    yield put({
      type: IS_CALL_SIGN_AVAILABLE,
      payload: {
        available: callSignResponse.available,
        data: callSignResponse?.data || null,
      },
    });

    if (callSignResponse.data) {
      yield put({
        type: CALLSIGN_ASSISTANT_MODAL,
        payload: { data: callSignResponse.data },
      });

      // showToast({
      //   message:
      //     "An account with this callsign already exists! Please use another callsign, or if you are still having issues reach out to support@worldradioleague.com",
      //   type: ToastTypes.ERROR,
      // });
    }
  } catch (e: any) {}

  yield put({
    type: LOADING_CALLSIGN_AVAILABILITY_STATUS,
    payload: false,
  });
}

export function* workerSendWordpressLogin() {
  try {
    yield call(() => ProfileService.sendWordPressLogin());
  } catch (e: any) {}
}

export function* workerFetchFeaturedMembers() {
  try {
    yield put({
      type: FEATURED_MEMBERS_LOADING,
      payload: true,
    });
    const users: FeaturedMember[] = yield call(() =>
      ProfileService.fetchFeaturedMembers()
    );
    yield put({
      type: SET_FEATURED_MEMBERS,
      payload: users,
    });
  } catch (e: any) {}
  yield put({
    type: FEATURED_MEMBERS_LOADING,
    payload: false,
  });
}
export function* workerFetchAwards(userId: string) {
  try {
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: true,
    });
    const awards: UserAwards[] = yield call(() => ProfileService.fetchAward());
    if (userId) {
      const userAwards: UserAwards[] = yield call(() =>
        ProfileService.fetchUserAwards(userId)
      );
      yield put({
        type: SET_USER_AWARDS_ADMIN,
        payload: userAwards,
      });
    }
    yield put({
      type: SET_AWARDS,
      payload: awards,
    });
  } catch (e: any) {}
  yield put({
    type: FETCH_AWARD_LOADING,
    payload: false,
  });
}

export function* workerAddUserAwards(payload: UserAwards[]) {
  try {
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: true,
    });
    yield call(() => ProfileService.addUserAwards(payload));
    if (payload && payload[0]) {
      yield put({
        type: FETCH_USER_AWARDS,
        payload: payload[0].uid,
      });
      yield put({
        type: FETCH_AWARDS,
        payload: payload[0].uid,
      });
    }
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: false,
    });
    yield put({
      type: SHOW_AWARD_MODAL,
      payload: null,
    });
    showToast({
      message: "User awards added successfully.",
      type: ToastTypes.SUCCESS,
    });
  } catch (e: any) {}
  yield put({
    type: FETCH_AWARD_LOADING,
    payload: false,
  });
}

export function* workerRemoveAward(payload: any) {
  try {
    const { awardId, userId } = payload;
    yield put({
      type: FETCH_AWARD_LOADING,
      payload: true,
    });
    const deleteAWard: boolean = yield call(() =>
      ProfileService.removeUserAward(awardId)
    );
    if (deleteAWard) {
      yield put({
        type: FETCH_USER_AWARDS,
        payload: userId,
      });
      yield put({
        type: FETCH_AWARDS,
        payload: userId,
      });
      showToast({
        message: "User award deleted successfully.",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {}
  yield put({
    type: FETCH_AWARD_LOADING,
    payload: false,
  });
}

export function* workerDisableUser(payload: string) {
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: true,
  });

  try {
    const disableUser: boolean = yield call(() =>
      ProfileService.disableUser(payload)
    );

    if (disableUser) {
      showToast({
        message: "User disabled successfully.",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {}
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: false,
  });
}

export function* workerDeleteUser(payload: string) {
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: true,
  });
  try {
    const deleteUser: boolean = yield call(() =>
      ProfileService.deleteUser(payload)
    );

    if (deleteUser) {
      showToast({
        message: "User deleted successfully.",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {}
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: false,
  });
}

export function* workerEnableUser(payload: string) {
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: true,
  });
  try {
    const enableUser: boolean = yield call(() =>
      ProfileService.enableUser(payload)
    );
    if (enableUser) {
      showToast({
        message: "User enabled successfully.",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {}
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: false,
  });
}

export function* workerUpdateSubscription(payload: any) {
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: true,
  });
  try {
    const updateSubscription: boolean = yield call(() =>
      ProfileService.updateSubscriptionLevel(payload)
    );
    if (updateSubscription) {
      showToast({
        message: "Subscription updated successfully.",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {}
  yield put({
    type: ADMIN_CONTROL_LOADING,
    payload: false,
  });
}

//Watchers
export function* watchCheckProfileUser() {
  while (true) {
    const { payload } = yield take(CHECK_USER_PROFILE);
    yield call(workerCheckUserProfile, payload);
  }
}

export function* watchCreateUserProfile() {
  while (true) {
    yield take(CREATE_USER_PROFILE);
    yield call(createUserProfile);
  }
}

export function* watchEditUserProfile() {
  while (true) {
    const { payload } = yield take(EDIT_USER_PROFILE);
    yield call(editUserProfile, payload);
  }
}

export function* watchUpdateUserProfile() {
  while (true) {
    const { payload } = yield take(UPDATE_USER_PROFILE);

    yield call(updateUserProfile, payload);
  }
}

export function* watchCoordinateChange() {
  while (true) {
    const { payload } = yield take(SAVE_USER_COORDINATES);
    yield call(workerCoordinateChange, payload);
  }
}

export function* watchFetchUserAwards() {
  while (true) {
    const { payload } = yield take(FETCH_USER_AWARDS);
    yield call(workerFetchUserAward, payload);
  }
}

export function* watchSetFeaturedUserAward() {
  while (true) {
    const { payload } = yield take(SET_FEATURED_USER_AWARD);
    yield call(workerSetFeaturedUserAward, payload);
  }
}

export function* watchCheckCallSignAvailability() {
  while (true) {
    const { payload } = yield take(CHECK_CALLSIGN_AVAILABILITY);
    yield call(workerCheckCallSignAvailability, payload);
  }
}

export function* watchWordpressLogin() {
  while (true) {
    yield take(SEND_WORDPRESS_LOGIN);
    yield call(workerSendWordpressLogin);
  }
}
export function* watchFetchFeaturedMembers() {
  while (true) {
    yield take(FETCH_FEATURED_MEMBERS);
    yield call(workerFetchFeaturedMembers);
  }
}
export function* watchFetchAwards() {
  while (true) {
    const { payload } = yield take(FETCH_AWARDS);
    yield call(workerFetchAwards, payload);
  }
}
export function* watchAddUserContest() {
  while (true) {
    const { payload } = yield take(ADD_USER_AWARDS);
    yield call(workerAddUserAwards, payload);
  }
}
export function* watchRemoveAward() {
  while (true) {
    const { payload } = yield take(REMOVE_USER_AWARD);
    yield call(workerRemoveAward, payload);
  }
}

export function* watchDisableUser() {
  while (true) {
    const { payload } = yield take(DISABLE_USER);
    yield call(workerDisableUser, payload);
  }
}

export function* watchDeleteUser() {
  while (true) {
    const { payload } = yield take(DELETE_USER);
    yield call(workerDeleteUser, payload);
  }
}

export function* watchEnableUser() {
  while (true) {
    const { payload } = yield take(ENABLE_USER);
    yield call(workerEnableUser, payload);
  }
}

export function* watchUpdateSubscription() {
  while (true) {
    const { payload } = yield take(UPDATE_SUBSCRIPTION);
    yield call(workerUpdateSubscription, payload);
  }
}
