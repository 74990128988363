import React, { useEffect } from "react";
import Header from "components/Header";
import RouteLoading from "components/Loading/RouteLoading";

import useSubscription from "hooks/subscription.hook";

import Pricing from "./Pricing";
import { useSearchParams } from "react-router-dom";

import { STRIPE_RESPONSE } from "types/Functions";

const PricingList = () => {
  const { subscriptionStatus, isSubscriptionLoading } = useSubscription();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get(STRIPE_RESPONSE.SUCCESS) === "true") {
    } else if (searchParams.get(STRIPE_RESPONSE.SUCCESS) === "false") {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStatus]);

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Plans" />
      <Pricing />
      {isSubscriptionLoading ? (
        <div className="absolute z-10 top-0 w-full h-full">
          <RouteLoading text={"Loading Subscription..."} />
        </div>
      ) : null}
    </main>
  );
};

export default PricingList;
