import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { LocationSource } from "components/DefaultLocationSettings";
import useDefaultLogbooksSettings from "hooks/useDefaultLogbooksSettings";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { useEffect } from "react";

export const LogBookSettingsSaveAction = () => {
  const { submitForm, setValues } = useLogBookSettingsForm();
  const { defaultLogbookSettings, isLoading } = useDefaultLogbooksSettings();

  useEffect(() => {
    if (defaultLogbookSettings) {
      setValues((prev) => ({
        ...prev,
        logbookStyle: defaultLogbookSettings?.logbookStyle || "",
        defaultLocation: defaultLogbookSettings.defaultLocation?.id || "",
        radio: defaultLogbookSettings.radio?.id || "",
        antenna: defaultLogbookSettings.antenna?.id || "",
        defaultCallSign: defaultLogbookSettings?.defaultCallSign || "",
        callSignType: defaultLogbookSettings?.callSignType || "",
        defaultPower: defaultLogbookSettings?.defaultPower || "",
        locationSource:  defaultLogbookSettings?.defaultLocation?.locationSource || LocationSource.NONE,
      }));
    }
  }, [defaultLogbookSettings]);

  const saveClickHandler = () => submitForm();

  return (
    <div className="w-full flex justify-center">
      <div className="w-[220px] mt-10">
        <BorderButtonSecondary
          text={`Save Settings`}
          onClick={saveClickHandler}
          className="sm:mb-0 cursor-pointer"
          height="h-8"
          childClassName="text-xs"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
