import { showToast } from "helpers/Toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  enrollContest,
  fetchActiveContestData,
  fetchContestDetails,
  fetchContestantDetail,
  fetchPassedContestData,
  fetchUpcomingContestData,
  getAwardContest,
  getContestContacts,
  getContestLeaderBoard,
  setActiveContestData,
  setContestContactData,
  setContestDetails,
  setEnrollDetails,
  setShowAddContestModal,
  storeContestActionForAdmin,
  setContestIdForEditModel,
  setEditContest,
  updateContest,
} from "store/Actions/contest.actions";
import {
  getActiveContestSelector,
  getAwardContestSelector,
  getContestContactsSelector,
  getContestDetailsSelector,
  getContestLeaderBoardSelector,
  getContestLeaderSelector,
  getContestantDetailSelector,
  getEnrollDetailsSelector,
  getPassedContestSelector,
  getShowAddContestModalSelector,
  getUpcomingContestSelector,
  getUserContestantDetailsSelector,
  getShowEditContestModalSelector,
} from "store/Reducers/contest.reducer";
import {
  getIsActiveContestLoading,
  getIsContestContactsLoading,
  getIsContestDetailLoading,
  getIsContestLeaderBoardLoading,
  getIsContestLeaderLoading,
  getIsContestLoadingSelector,
  getIsContestUserDetailLoading,
  getIsContestantDetailLoading,
  getIsEnrollContestLoading,
  getIsPassedContestLoading,
  getIsUpcomingContestLoading,
} from "store/Reducers/loading.reducer";
import { ToastTypes } from "types/Component";
import {
  Timestamp,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  ContestDescription,
  ContestModel,
  LogBookContactModel,
  LogBookModel,
  UserAwards,
} from "types/Models";
import { CONTEST_STATUS } from "types/Functions";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import {
  handleDeleteContestAction,
  setSelectedLogBookAction,
} from "store/Actions/logBook.actions";
import { ContestTable, LogBookContactTable } from "constants/Collections";
import { db } from "firebase-config";
import useSubscription from "./subscription.hook";
import useConfig from "./config.hook";
import { globalNavigate } from "routes/GlobalRoutes";

const useContest = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [image, setImage] = useState<any>("");
  const [imagePrev, setImagePrev] = useState("");
  const [description, setDescription] = useState<ContestDescription[]>([
    {
      title: "",
      description: "",
    },
  ]);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();

  const [endDate, setEndDate] = useState();
  const [membershipLevel, setMembershipLevel] = useState<string[]>([]);
  const [bands, setBands] = useState<string[]>([]);
  const [modes, setModes] = useState<string[]>([]);
  const [geo, setGeo] = useState("");
  const [multiplier, setMultiplier] = useState(1);
  const [exchangeOne, setExchangeOne] = useState("");
  const [exchangeTwo, setExchangeTwo] = useState("");
  const [exchangeThree, setExchangeThree] = useState("");
  const [exchangeFour, setExchangeFour] = useState("");

  const [selectedAwards, setSelectedAwards] = useState<UserAwards[]>([]);

  const [shortDescription, setShortDescription] = useState("");

  const showAddContestModal = useSelector(getShowAddContestModalSelector);
  const showEditContestModal = useSelector(getShowEditContestModalSelector);
  const showEnrollSuccessData = useSelector(getEnrollDetailsSelector);

  const isContestLoading = useSelector(getIsContestLoadingSelector);

  const isActiveContestLoading = useSelector(getIsActiveContestLoading);
  const isUpcomingContestLoading = useSelector(getIsUpcomingContestLoading);
  const isPassedContestLoading = useSelector(getIsPassedContestLoading);

  const isEnrollContestLoading = useSelector(getIsEnrollContestLoading);
  const isContestantDetailLoading = useSelector(getIsContestantDetailLoading);

  const isContestDetailLoading = useSelector(getIsContestDetailLoading);

  const activeContest = useSelector(getActiveContestSelector);
  const upcomingContest = useSelector(getUpcomingContestSelector);
  const passedContest = useSelector(getPassedContestSelector);

  const contestDetail = useSelector(getContestDetailsSelector);

  const userProfile = useSelector(getUserProfileSelector);

  const contestantDetails = useSelector(getContestantDetailSelector);

  const [contestStatus, setContestStatus] = useState<string | null>(null);

  const isContestLeaderLoading = useSelector(getIsContestLeaderLoading);
  const contestLeader = useSelector(getContestLeaderSelector);

  const contestContacts = useSelector(getContestContactsSelector);
  const isContestContactsLoading = useSelector(getIsContestContactsLoading);

  const userContestantDetail = useSelector(getUserContestantDetailsSelector);
  const isUserContestantLoading = useSelector(getIsContestUserDetailLoading);

  const isContestLeaderBoardLoading = useSelector(
    getIsContestLeaderBoardLoading
  );
  const contestLeaderBoard = useSelector(getContestLeaderBoardSelector);

  const awardContest = useSelector(getAwardContestSelector);

  const { haveAccessToContest } = useSubscription();
  const { handlePrimaryModal } = useConfig();

  useEffect(() => {
    if (contestDetail) {
      const now = new Date();
      //determine if a contest is ongoing in the future or past based on fields from contestDetail.startDate and contestDetail.endDate
      const startDate = new Date(contestDetail?.startDate.seconds * 1000);
      const endDate = new Date(contestDetail?.endDate.seconds * 1000);
      if (contestDetail) {
        if (
          now.getTime() > startDate.getTime() &&
          now.getTime() < endDate.getTime()
        ) {
          setContestStatus(CONTEST_STATUS.ACTIVE);
        } else if (now.getTime() < startDate.getTime()) {
          setContestStatus(CONTEST_STATUS.UPCOMING);
        } else {
          setContestStatus(CONTEST_STATUS.PASSED);
        }
      }
    }
  }, [contestDetail]);

  const toggleAddContestModal = (value: boolean) => {
    dispatch(setShowAddContestModal(value));
  };

  const editContest = () => {
    if (!name) {
      toast("Please enter contest name");
    } else {
      if (!description.length) {
        toast("Please enter contest description");
      } else {
        if (!startDate) {
          toast("Please enter contest start date");
        } else {
          if (!endDate) {
            toast("Please enter contest end date");
          } else {
            if (!membershipLevel.length) {
              toast("Please select contest membership level");
            } else {
              if (!bands.length) {
                toast("Please select contest bands");
              } else {
                if (!modes.length) {
                  toast("Please select contest modes");
                } else {
                  if (!geo) {
                    toast("Please enter contest geo");
                  } else {
                    if (!multiplier) {
                      toast("Please enter contest multiplier");
                    } else {
                      const startDateObj = new Date(
                        `${startDate}T${startTime}Z`
                      );
                      const endDateObj = new Date(`${endDate}T${endTime}Z`);
                      let data: any = {
                        name,
                        description,
                        startDate: Timestamp.fromDate(new Date(startDateObj)),
                        endDate: Timestamp.fromDate(new Date(endDateObj)),
                        membershipLevel,
                        bands,
                        modes,
                        geo,
                        multiplier,
                        exchangeOne,
                        exchangeTwo,
                        exchangeThree,
                        exchangeFour,
                        shortDescription,
                        timestamp: showEditContestModal?.timestamp,
                        image: image ? image : imagePrev,
                      };
                      dispatch(setEditContest(data, image ? true : false));
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const storeContestIdForEditModal = (value: ContestModel | null) => {
    dispatch(setContestIdForEditModel(value));
  };

  const toggleEnrollModal = (value: null) => {
    dispatch(setEnrollDetails(value));
  };

  const storeContestAction = (value: any) => {
    dispatch(storeContestActionForAdmin(value));
  };

  const enrollToContest = (contest: ContestModel) => {
    if (userProfile) {
      if (haveAccessToContest(contest.membershipLevel)) {
        dispatch(enrollContest(contest));
      }
    } else {
      toast("Please login or create an account to enroll in a contest.");
      globalNavigate("/register");
    }
  };

  const onPillClick = (
    item: string,
    currentState: string[],
    setState: (value: string[]) => void
  ) => {
    const existingState: string[] = [...currentState];
    const itemIndex = existingState.findIndex((i) => i === item);
    if (itemIndex > -1) {
      existingState.splice(itemIndex, 1);
    } else {
      existingState.push(item);
    }
    setState(existingState);
  };

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const isValidArray = (arr: ContestDescription[]) => {
    for (const item of arr) {
      if (!item.title || !item.description) {
        return false;
      }
    }
    return true; // All items passed the validation
  };

  const onSaveContest = () => {
    if (!name) {
      toast("Please enter contest name");
    } else {
      if (!image) {
        toast("Please upload contest image");
      } else {
        if (!description.length || !isValidArray(description)) {
          toast("Please enter all description titles and description body");
        } else {
          if (!startDate || !startTime) {
            toast("Please enter contest start date/time");
          } else {
            if (!endDate || !endTime) {
              toast("Please enter contest end date/time");
            } else {
              if (!membershipLevel.length) {
                toast("Please select contest membership level");
              } else {
                if (!bands.length) {
                  toast("Please select contest bands");
                } else {
                  if (!modes.length) {
                    toast("Please select contest modes");
                  } else {
                    if (!geo) {
                      toast("Please enter contest geo");
                    } else {
                      if (!multiplier) {
                        toast("Please enter contest multiplier");
                      } else {
                        const startDateObj = new Date(
                          `${startDate}T${startTime}Z`
                        );
                        const endDateObj = new Date(`${endDate}T${endTime}Z`);

                        let data: any = {
                          name,
                          description,
                          startDate: Timestamp.fromDate(startDateObj),
                          endDate: Timestamp.fromDate(endDateObj),
                          membershipLevel,
                          bands,
                          modes,
                          geo,
                          multiplier,
                          exchangeOne,
                          exchangeTwo,
                          exchangeThree,
                          exchangeFour,
                          shortDescription,
                          timestamp: Timestamp.fromDate(new Date()),
                          awards: selectedAwards,
                        };
                        if (image && image[0]) {
                          data.image = image[0];
                        }
                        storeContestAction(data);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const fetchContest = () => {
    dispatch(fetchActiveContestData());
    dispatch(fetchPassedContestData());
    dispatch(fetchUpcomingContestData());
  };

  const fetchContestLeaderBoard = (contestId: string) => {
    dispatch(getContestLeaderBoard(contestId));
  };

  const fetchContestContacts = (contestId: string) => {
    dispatch(getContestContacts(contestId));
  };

  const fetchContestDetail = (id: string) => {
    dispatch(
      fetchContestDetails({
        contestId: id,
        userId: userProfile?.uid || "",
      })
    );
  };

  const saveContestDetail = (contest: ContestModel | null) => {
    dispatch(setContestDetails(contest));
  };

  const setSelectedLogbook = (item: LogBookModel) => {
    dispatch(setSelectedLogBookAction(item));
  };

  const getSumOfContestants = () => {
    let sum = activeContest.reduce((total, currentItem) => {
      return total + (currentItem.contestantCount || 0);
    }, 0);
    return sum;
  };
  const handleDeleteContest = (contestId: string) => {
    dispatch(handleDeleteContestAction(contestId));
  };

  const contestContactListener = (contestId: string) => {
    if (contestId) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, LogBookContactTable),
          where("contestId", "==", contestId),
          orderBy("contactTimeStamp", "desc")
        ),
        (snapshot) => {
          const newLogBookContacts: LogBookContactModel[] = snapshot.docs.map(
            (doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }
          );
          dispatch(setContestContactData(newLogBookContacts));
          fetchContestLeaderBoard(contestId);
        }
      );
      return unsubscribe;
    }
  };

  const contestListener = () => {
    const currentDate = new Date();

    const unsubscribe = onSnapshot(
      query(
        collection(db, ContestTable),
        where("startDate", "<=", currentDate),
        orderBy("startDate", "desc")
      ),
      (querySnapshot) => {
        const contestData: ContestModel[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const endDate = data.endDate.seconds * 1000;
          if (new Date(endDate) > currentDate) {
            const eventData = { id: doc.id, ...data };
            contestData.push(eventData as ContestModel);
          }
        });
        // console.log("contestData : LISTENER", contestData);
        dispatch(setActiveContestData(contestData));
      }
    );
    return unsubscribe;
  };

  const onDescriptionTitleChange = (index: number, title: string) => {
    const existingDescription: any[] = [...description];

    existingDescription[index].title = title;

    setDescription(existingDescription);
  };
  const onDescriptionBodyChange = (index: number, descriptionText: string) => {
    const existingDescription: any[] = [...description];
    existingDescription[index].description = descriptionText;

    setDescription(existingDescription);
  };

  const addSectionClicked = () => {
    const existingDescription: any[] = description.slice();
    existingDescription.push({
      title: "",
      description: "",
    });
    setDescription(existingDescription);
  };

  const removeSectionClicked = (index: number) => {
    const existingDescription: any[] = description.slice();
    existingDescription.splice(index, 1);
    setDescription(existingDescription);
  };

  const getContestantDetail = (contestId: string, userId: string) => {
    dispatch(
      fetchContestantDetail({
        contestId: contestId,
        userId: userId,
      })
    );
  };

  const fetchAwardContest = () => {
    dispatch(getAwardContest());
  };

  const declareWinner = (contestId: string) => {
    handlePrimaryModal({
      title: "Are you sure you want to declare this user as winner?",
      type: "Declare Winner",
      onCancelPressed: () => handlePrimaryModal(null),
      onSubmitPressed: () => {
        const data: any = {
          leader: userContestantDetail?.user,
          id: contestId,
        };
        dispatch(updateContest(data));
        handlePrimaryModal(null);
      },
      submitText: "Declare Winner",
      cancelText: "Cancel",
    });
  };

  return {
    editContest,
    toggleAddContestModal,
    storeContestIdForEditModal,
    showAddContestModal,
    showEditContestModal,
    name,
    setName,
    image,
    setImage,
    description,
    setDescription,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    membershipLevel,
    setMembershipLevel,
    bands,
    setBands,
    modes,
    setModes,
    geo,
    setGeo,
    multiplier,
    setMultiplier,
    exchangeOne,
    setExchangeOne,
    exchangeTwo,
    setExchangeTwo,
    onPillClick,
    onSaveContest,
    isContestLoading,
    fetchContest,
    isActiveContestLoading,
    isUpcomingContestLoading,
    isPassedContestLoading,
    activeContest,
    upcomingContest,
    passedContest,
    fetchContestDetail,
    saveContestDetail,
    contestDetail,
    setShortDescription,
    shortDescription,
    contestStatus,
    showEnrollSuccessData,
    toggleEnrollModal,
    enrollToContest,
    isEnrollContestLoading,
    contestantDetails,
    isContestantDetailLoading,
    setSelectedLogbook,
    userProfile,
    getSumOfContestants,
    isContestLeaderLoading,
    contestLeader,
    handleDeleteContest,
    fetchContestLeaderBoard,
    isContestLeaderBoardLoading,
    contestLeaderBoard,
    contestContactListener,
    fetchContestContacts,
    contestContacts,
    isContestContactsLoading,
    onDescriptionTitleChange,
    onDescriptionBodyChange,
    addSectionClicked,
    removeSectionClicked,
    isContestDetailLoading,
    contestListener,
    getContestantDetail,
    userContestantDetail,
    isUserContestantLoading,
    fetchAwardContest,
    awardContest,
    setSelectedAwards,
    selectedAwards,
    imagePrev,
    setImagePrev,
    declareWinner,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    exchangeThree,
    setExchangeThree,
    exchangeFour,
    setExchangeFour,
  };
};

export default useContest;
