import React, { useEffect } from "react";
import Header from "components/Header";
import TabNavGlowContest from "components/TabNavGlow/TabNavGlowContest";
import { contestTabs } from "constants/NavMenus";

import { useSearchParams } from "react-router-dom";
import ContestRules from "./ContestRules";
import ContestLeaderboard from "./ContestLeaderboard";
import ContestMap from "./ContestMap";
import useContest from "hooks/contest.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import AddContactLogBook from "../LogBook/AddContactLogBook";
import RouteLoading from "components/Loading/RouteLoading";
import { getContestStatus } from "helpers/Utils";
import { getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";
import { useSelector } from "react-redux";
import { CONTEST_STATUS } from "types/Functions";
import Hint from "components/Hint";

function ContestDetail() {
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedLogBook = useSelector(getSelectedLogBookSelector);

  const {
    fetchContestDetail,
    contestDetail,
    contestStatus,
    contestantDetails,
    setSelectedLogbook,
    isContestantDetailLoading,
    fetchContestLeaderBoard,
    fetchContestContacts,
    isContestDetailLoading,
    saveContestDetail
  } = useContest();

  useEffect(() => {
    if (searchParams.get("contest_id")) {
      fetchContestDetail(searchParams.get("contest_id")!);
      fetchContestLeaderBoard(searchParams.get("contest_id")!);
      fetchContestContacts(searchParams.get("contest_id")!);
    } else {
      globalNavigate("/contest");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("contest_id")]);

  useEffect(() => {
    // if (!contestDetail) {
    //   globalNavigate("/contest");
    // }
  }, [contestDetail]);

  useEffect(()=>{
    return ()=>{
      saveContestDetail(null);
    }
  },[])

  const getLayout = () => {
    if (searchParams.get("tab") === "contest_rules") {
      if (isContestDetailLoading) {
        return <RouteLoading noBg />;
      } else {
        return contestDetail ? (
          <ContestRules
            contestDetail={contestDetail}
            contestStatus={contestStatus}
          />
        ) : null;
      }
    } else if (searchParams.get("tab") === "real_time_leaderboard") {
      return contestDetail ? (
        <ContestLeaderboard
          contestDetail={contestDetail}
          contestStatus={contestStatus}
        />
      ) : null;
    } else if (searchParams.get("tab") === "real_time_map") {
      return <ContestMap />;
    } else if (searchParams.get("tab") === "log_contest_contacts") {
      return (
        <div className="flex flex-col pt-4">
          {getContestStatus(selectedLogBook?.contest) ===
          CONTEST_STATUS.UPCOMING ? (
            <div className="w-full flex justify-center">
              <Hint
                warning
                text={
                  "You are outside of the contest window. Only contacts logged during the designated contest time will count for the contest."
                }
              />
            </div>
          ) : null}
          <AddContactLogBook />
        </div>
      );
    } else {
      return contestDetail ? (
        <ContestRules
          contestDetail={contestDetail}
          contestStatus={contestStatus}
        />
      ) : null;
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full flex flex-col items-center">
      <Header title={contestDetail?.name || ""} />
      <div className="px-4 py-8 sm:p-8 lg:p-14 lg:pt-3 dashboardWrapper overflow-x-hidden w-full">
        <TabNavGlowContest
          selectedIndex={
            searchParams.get("tab")
              ? searchParams.get("tab")
              : contestTabs[0]?.text
          }
          isUserEnrolled={contestantDetails?.isUserEnrolled}
          contestantDetailLoading={isContestantDetailLoading}
          contestStatus={contestStatus || ""}
          setSelectedIndex={(index) => {
            if (index === "log_contest_contacts") {
              setSelectedLogbook({
                id: contestantDetails?.enrolledUser?.logBookId,
                name: contestantDetails?.enrolledUser?.logbookName,
                uid: contestantDetails?.enrolledUser?.uid,
                contestId: contestDetail?.id,
                contest: {
                  name: contestDetail?.name || "",
                  startDate: contestDetail?.startDate,
                  endDate: contestDetail?.endDate,
                  image: contestDetail?.image || "",
                  shortDescription: contestDetail?.shortDescription || "",
                },
              });
              setSearchParams((prev: any) => {
                return {
                  ...prev,
                  tab: index,
                  contest_id: searchParams.get("contest_id"),
                  "logbook-id": contestantDetails?.enrolledUser?.logBookId,
                  "logbook-name": contestantDetails?.enrolledUser?.logbookName,
                };
              });
            } else {
              setSearchParams((prev: any) => {
                return {
                  ...prev,
                  tab: index,
                  contest_id: searchParams.get("contest_id"),
                };
              });
            }
          }}
        />

        {getLayout()}
      </div>
    </main>
  );
}

export default ContestDetail;
