import React, { useMemo, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, PolylineF } from "@react-google-maps/api";
import { mapStylesDark } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import { FlatGoogleMapProps } from "types/Component";
import { MarkerLines, MarkerPoint } from "types/State";
import useMap from "hooks/map.hook";

import ContactMarker from "components/GoogleMap/ContactMarker";
import { generateCurvePath } from "helpers/Utils";

const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

const CurvedLine = ({ start, end, lineData, setHoverArc }: any) => {
  const onLoad = (polyline: any) => {
    // console.log("polyline: ", polyline);
  };

  const options = {
    strokeColor: "#17F9DA",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#17F9DA",
    fillOpacity: 0.35,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    geodesic: true,
  };

  const curvePath = [start, end];

  return (
    <PolylineF
      onLoad={onLoad}
      path={curvePath}
      options={options}
      onMouseOver={() => {
        if (setHoverArc) {
          setHoverArc(lineData);
        }
      }}
      onMouseOut={() => {
        if (setHoverArc) {
          setHoverArc(null);
        }
      }}
    />
  );
};

const UserContactMap = ({
  className,
  setHoverArc,
  captureSnapshot,
  guestMode,
  showShare,
}: FlatGoogleMapProps) => {
  const [mapMarkers, setMapMarkers] = useState<MarkerPoint[]>([]);
  const [polylines, setPolylines] = useState<MarkerLines[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { allUserContactMarkers, allUserContactMarkersLines } = useMap();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY!,
    libraries: googleLibraries,
  });
  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  useEffect(() => {
    const newMarkers = [...allUserContactMarkers];
    setMapMarkers(guestMode ? newMarkers.slice(0, 30) : newMarkers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserContactMarkers]);

  useEffect(() => {
    const newLines = [...allUserContactMarkersLines];
    setPolylines(guestMode ? newLines.slice(0, 30) : newLines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserContactMarkersLines]);
  useEffect(() => {
    if (isLoaded) {
      const newMarkers = [...allUserContactMarkers];
      const newLines = [...allUserContactMarkersLines];
      setMapMarkers(guestMode ? newMarkers.slice(0, 30) : newMarkers);

      setPolylines(guestMode ? newLines.slice(0, 30) : newLines);

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <div className={className}>
      {!isLoaded || isLoading ? (
        <RouteLoading />
      ) : (
        <GoogleMap
          mapContainerClassName="map-container no-close-button-on-info-windows"
          center={center}
          zoom={2}
          options={{
            styles: mapStylesDark,
          }}
        >
          {mapMarkers
            ? mapMarkers.map((position, index) => {
                return (
                    <ContactMarker
                      position={position}
                      key={`${position.lat}-${position.lng}-${index}`}
                      index={index}
                    />
                );
              })
            : null}
          {polylines.map((line: MarkerLines, index) => {
            return (
              <CurvedLine
                key={`${line.start}-${line.end}-${index}`}
                start={line.start}
                end={line.end}
                lineData={line}
                setHoverArc={setHoverArc}
              />
            );
          })}
        </GoogleMap>
      )}
    </div>
  );
};

export default UserContactMap;
