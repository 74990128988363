import { ConfigState } from "types/State";
import { RootState } from "store/Reducers";
import {
  ConfigActionPayload,
  MENU_VISIBILITY_STATUS,
  SET_PRIMARY_MODAL_DATA,
  SET_SCREENSHOT_IMAGE,
  SHOW_SIGNIN_HELP,
  ADD_CONTACT_SUCCESS,
} from "store/Actions/config.actions";
const defaultState: ConfigState = {
  isMenuOpen: false,
  primaryModalData: null,
  screenShotImage: "",
  showSignInHelp: false,
  addContactSuccess: false,
};

export default function configReducer(
  state = defaultState,
  action: ConfigActionPayload
) {
  switch (action.type) {
    case MENU_VISIBILITY_STATUS:
      return {
        ...state,
        isMenuOpen: action.payload,
      };
    case SET_PRIMARY_MODAL_DATA:
      return {
        ...state,
        primaryModalData: action.payload,
      };
    case SET_SCREENSHOT_IMAGE:
      return {
        ...state,
        screenShotImage: action.payload,
      };
    case SHOW_SIGNIN_HELP:
      return {
        ...state,
        showSignInHelp: action.payload,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        addContactSuccess: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getIsMenuOpenSelector = (state: RootState) => {
  return state.config.isMenuOpen;
};

export const getPrimaryModalSelector = (state: RootState) => {
  return state.config.primaryModalData;
};

export const getScreenShotImageSelector = (state: RootState) => {
  return state.config.screenShotImage;
};

export const getShowSignInHelpSelector = (state: RootState) => {
  return state.config.showSignInHelp;
};

export const getAddContactSuccessSelector = (state: RootState) => {
  return state.config.addContactSuccess || false;
};
