import RouteLoading from "components/Loading/RouteLoading";
import LogBooks from "pages/Dashboard/LogBook";
import AddContactLogBook from "pages/Dashboard/LogBook/AddContactLogBook";
import AddLogBook from "pages/Dashboard/LogBook/AddLogBook";
import Home from "pages/Dashboard/Home";
import Profile from "pages/Dashboard/Profile";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  CONTACT,
  CONTEST,
  EDIT_PROFILE,
  LEADERS_BOARD,
  LOG_CONTACT_ROUTE,
  MEMBERS_DIRECTORY,
  MY_PROFILE_ROUTE,
  REPORT_BUG,
  SETTING_ROUTE,
  SPOTTING,
} from "constants/Links";
import EditProfile from "pages/Dashboard/Profile/EditProfile";
import Setting from "pages/Dashboard/Setting";
import LeaderBoard from "pages/Dashboard/LeaderBoard";
import Pricing from "pages/Dashboard/Setting/Pricing";
import MembersDirectory from "pages/Dashboard/Member/MembersDirectory";
import RecentContacts from "pages/Dashboard/RecentContacts";
import PricingList from "pages/Dashboard/Setting/PricingList";
import ImportContact from "pages/Dashboard/LogBook/ImportContact";
import ImportSummary from "pages/Dashboard/LogBook/ImportSummary";
import Contest from "pages/Dashboard/Contest";
import ContestDetail from "pages/Dashboard/Contest/ContestDetail";
import UserLogbookDetail from "pages/Dashboard/LogBook/UserLogbookDetail";
import LeaderBoardDetail from "pages/Dashboard/LeaderBoardDetail";
import ContactDetail from "pages/Dashboard/Contact";
import Spotting from "pages/Dashboard/Spotting";
import LogbookStyles from "pages/Dashboard/LogBook/LogbookStyles";
import SavedLocations from "pages/Dashboard/LogBook/SavedLocations/SavedLocations";
import AddNewSavedLocations from "pages/Dashboard/LogBook/SavedLocations/AddNewSavedLocations";
import MyStation from "pages/Dashboard/LogBook/MyStation";
import { ReportBug } from "pages/Dashboard/ReportBug";
import DefaultLogbooksSettings from "pages/Dashboard/LogBook/DefaultSettings";
import ActivityDetails from "pages/Dashboard/LogBook/ActivityLog/ActivityDetails";

const MainLayout = lazy(() => import("../layout/MainLayout"));

function RootRoute() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<RouteLoading />}>
            <MainLayout />
          </Suspense>
        }
      >
        <Route index element={<Home />} />
        <Route path="/most-recent-contacts" element={<RecentContacts />} />
        <Route path={MY_PROFILE_ROUTE}>
          <Route index element={<Profile />} />
          <Route path=":callSignFromParam" element={<Profile />} />
          <Route path={EDIT_PROFILE} element={<EditProfile />} />
        </Route>
        <Route path={LOG_CONTACT_ROUTE}>
          <Route index element={<LogBooks />} />
          <Route path="add-logbook" element={<AddLogBook />} />
          <Route path="add-contact" element={<AddContactLogBook />} />
          <Route path="import-contact" element={<ImportContact />} />
          <Route path="activity-details" element={<ActivityDetails />} />
          <Route path="logbook-styles" element={<LogbookStyles />} />
          <Route path="saved-locations" element={<SavedLocations />} />
          <Route path="add-new-location" element={<AddNewSavedLocations />} />
          <Route path="my-station" element={<MyStation />} />
          <Route path="logbook-default-settings" element={<DefaultLogbooksSettings />} />
          <Route path=":tab" element={<LogBooks />} />

          {/* <Route path="edit-contact" element={<EditLogBookContact />} /> */}
        </Route>
        {/* <Route path={LEADERS_BOARD} element={<LeaderBoard />} /> */}
        <Route path={LEADERS_BOARD}>
          <Route index element={<LeaderBoard />} />
          <Route path="detail" element={<LeaderBoardDetail />} />
        </Route>
        <Route path={CONTACT}>
          <Route index element={<ContactDetail />} />
        </Route>
        <Route path={CONTEST}>
          <Route index element={<Contest />} />
          <Route path="contest-detail" element={<ContestDetail />} />
          <Route path="user-logbook-detail" element={<UserLogbookDetail />} />
        </Route>
        <Route path={MEMBERS_DIRECTORY}>
          <Route index element={<MembersDirectory />} />
        </Route>
        <Route path={SPOTTING}>
          <Route index element={<Spotting />} />
        </Route>
        <Route path={SETTING_ROUTE}>
          <Route index element={<Setting />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="pricingList" element={<PricingList />} />
        </Route>
        <Route path={REPORT_BUG}>
          <Route index element={<ReportBug />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense fallback={<RouteLoading />}>
            <Navigate to="/" replace={true} />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default RootRoute;
