import { call, put, take } from "redux-saga/effects";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import PotaService from "services/pota.service";
import { ParkReferenceModel, PotaAutoSpotPayload } from "types/Models";
import {
  ADD_POTA_AUTO_SPOT,
  GET_NEARBY_PARK_REFERENCES,
  GET_PARK_BY_REFERENCE,
  GET_PARK_REFERENCE_SUGGESTIONS,
  SET_NEARBY_PARK_REFERENCES,
  SET_PARK_BY_REFERENCE_DATA,
  SET_PARK_BY_REFERENCE_LOADING,
  SET_PARK_REFERENCE_SUGGESTIONS,
} from "store/Actions/pota.actions";
import LogBookService from "services/logBook.service";
import { Timestamp } from "firebase/firestore";
import { prioritizeExactParkMatchesAndFlipUSParksOrder } from "helpers/Utils";

export function* workerGetParkByReference(payload: {
  key: string;
  value: string;
}) {
  try {
    yield put({
      type: SET_PARK_BY_REFERENCE_LOADING,
      payload: { key: payload.key, value: true },
    });

    const parkReferenceData: ParkReferenceModel = yield call(() =>
      PotaService.getParkByReference(payload.value)
    );

    if (parkReferenceData) {
      yield put({
        type: SET_PARK_BY_REFERENCE_DATA,
        payload: { key: payload.key, value: parkReferenceData },
      });
    }

    yield put({
      type: SET_PARK_BY_REFERENCE_LOADING,
      payload: { key: payload.key, value: false },
    });
  } catch (e: any) {
    authErrorHandler(e);
    yield put({
      type: SET_PARK_BY_REFERENCE_LOADING,
      payload: { key: payload.key, value: false },
    });
  }
}

export function* watchGetParkByReference() {
  while (true) {
    const { payload } = yield take(GET_PARK_BY_REFERENCE);
    yield call(workerGetParkByReference, payload);
  }
}

export function* workerGetParkReferenceSuggestions(payload: {
  key: string;
  value: string;
}) {
  try {
    const parkReferenceData: ParkReferenceModel[] = yield call(() =>
      PotaService.getParkReferenceSuggestions(payload.value)
    );

    if (parkReferenceData && parkReferenceData.length) {
      const prioritizedSearchResults =
        prioritizeExactParkMatchesAndFlipUSParksOrder(
          payload.value,
          parkReferenceData
        );
      yield put({
        type: SET_PARK_REFERENCE_SUGGESTIONS,
        payload: { key: payload.key, value: prioritizedSearchResults },
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* watchGetParkReferenceSuggestions() {
  while (true) {
    const { payload } = yield take(GET_PARK_REFERENCE_SUGGESTIONS);
    yield call(workerGetParkReferenceSuggestions, payload);
  }
}

export function* workerGetNearbyParkReferences(payload: {
  center: [number, number];
  radiusInKm?: number;
}) {
  try {
    const parkReferenceData: ParkReferenceModel = yield call(() =>
      PotaService.getNearbyParks(payload.center, payload.radiusInKm)
    );

    if (parkReferenceData && parkReferenceData.length) {
      yield put({
        type: SET_NEARBY_PARK_REFERENCES,
        payload: parkReferenceData,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* watchGetNearbyParkReferences() {
  while (true) {
    const { payload } = yield take(GET_NEARBY_PARK_REFERENCES);
    yield call(workerGetNearbyParkReferences, payload);
  }
}

export function* workderAddPotaAutoSpot(payload: any) {
  try {
    const { spotData, logBookId } = payload;
    yield call(() => PotaService.addPotaAutoSpot(spotData));
    if (logBookId) {
      yield call(() =>
        LogBookService.editUserLogBookData(
          {
            lastSpottedAt: Timestamp.now(),
            lastSpottedFrequency: spotData?.frequency
              ? `${parseFloat(spotData?.frequency) / 1000}`
              : "",
            lastSpottedMode: spotData?.mode || "",
            lastSpottedBand: spotData?.band || "",
            lastSpottedComment: spotData?.comments || "",
          },
          logBookId
        )
      );
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* watchAddPotaAutoSpot() {
  while (true) {
    const { payload } = yield take(ADD_POTA_AUTO_SPOT);
    yield call(workderAddPotaAutoSpot, payload);
  }
}
