import ActivityIndicator from "components/Loading/ActivityIndicator";
import React from "react";

interface DynamicButtonProps {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  text?: string;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
}

const DynamicButton: React.FC<DynamicButtonProps> = ({
  leftElement,
  rightElement,
  text = "",
  onClick,
  className = "",
  isLoading = false,
}) => {
  if (isLoading)
    return (
      <div
        className={`relative w-[100px] bg-[#444467] h-[30px] flex items-center justify-center rounded-md mb-3 ml-2 cursor-pointer ${className}`}
      >
        <ActivityIndicator size={16} />{" "}
      </div>
    );

  return (
    <div
      className={`relative w-[100px] bg-[#444467] h-[30px] flex items-center justify-center rounded-md mb-3 ml-2 cursor-pointer ${className}`}
      onClick={onClick}
    >
      {/* Left Element (Checkbox, Icon, etc.) */}
      {leftElement && <div className="left-element">{leftElement}</div>}

      {/* Button Text */}
      {text && (
        <p className="font-[Play] text-[#FFFFFF] text-center text-xs">{text}</p>
      )}

      {/* Right Element (Icon, Image, etc.) */}
      {rightElement && <div className="right-element ml-2">{rightElement}</div>}
    </div>
  );
};

export default DynamicButton;
