import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSavedLocation,
  deleteSavedLocation,
  fetchSavedLocation,
  setEditLogBook,
} from "store/Actions/logBook.actions";
import { CoordinatesParams } from "store/Actions/profile.actions";
import {
  getIsLogBookLoadingSelector,
  getIsLogbookSettingLoadingSelector,
} from "store/Reducers/loading.reducer";
import {
  getAntennasSelector,
  getRadiosSelector,
  getSavedLocationsSelector,
  getSelectedLogBookSelector,
  getStationsSelector,
} from "store/Reducers/logBook.reducer";
import { LogBookModel, LogbookSettingsForm, SavedLocation } from "types/Models";
import useConfig from "./config.hook";
import { calculateMaidenhead, compareObjects } from "helpers/Utils";
import { getCountryAndStateFromGrid } from "firebase-config";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { useLocation } from "react-router-dom";
import { Templates } from "constants/Config";
import { deleteField } from "firebase/firestore";
import { LocationSource } from "components/DefaultLocationSettings";
import LogBookService from "services/logBook.service";

const useLogbookSetting = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const selectedLogbook = useSelector(getSelectedLogBookSelector);
  const logbookLoading = useSelector(getIsLogBookLoadingSelector);
  const savedLocations = useSelector(getSavedLocationsSelector);
  const radios = useSelector(getRadiosSelector);
  const antennas = useSelector(getAntennasSelector);
  const stations = useSelector(getStationsSelector);

  const logbookSettingLoading = useSelector(getIsLogbookSettingLoadingSelector);
  const { handlePrimaryModal } = useConfig();

  const [locationLoading, setLocationLoading] = useState(false);
  const [savedLocationOptions, setSavedLocationOptions] = useState<any>([]);
  const [selectedLocationOption, setSelectedLocationOption] = useState<any>([]);

  const [savedRadioOptions, setSavedRadioOptions] = useState<any>([]);
  const [selectedRadioOption, setSelectedRadioOption] = useState<any>([]);

  const [savedAntennaOptions, setSavedAntennaOptions] = useState<any>([]);
  const [selectedAntennaOption, setSelectedAntennaOption] = useState<any>([]);

  const [locationOption, setLocationOption] = useState<any>(1);

  const [locationForm, setLocationForm] = useState({
    locationName: "",
    locationType: "",
    locationCountry: "",
    locationState: "",
    locationLat: "",
    locationLong: "",
    locationQTH: "",
    locationGrid: "",
    defaultLocation: false,
    isCustom: false,
  });

  useEffect(() => {
    if (selectedLogbook && selectedLogbook?.defaultLocation?.isCustom) {
      setLocationOption(2);
      setLocationForm({
        locationCountry: selectedLogbook?.defaultLocation?.country || "",
        locationState: selectedLogbook?.defaultLocation?.state || "",
        locationGrid: selectedLogbook?.defaultLocation?.gridsquare || "",
        locationLat: selectedLogbook?.defaultLocation?.lat?.toString() || "",
        locationLong: selectedLogbook?.defaultLocation?.lng?.toString() || "",
        locationName: "",
        locationType: "",
        locationQTH: "",
        defaultLocation: false,
        isCustom: selectedLogbook?.defaultLocation?.isCustom || false,
      });
    }
  }, [selectedLogbook]);

  useEffect(() => {
    //set saved location options with the saved locations title and value as id
    const saveLocationLabel = savedLocations.map((location) => ({
      label: location.name,
      value: location.id,
    }));
    saveLocationLabel.push({ label: "View/Add More Locations", value: "add" });
    setSavedLocationOptions(saveLocationLabel);
  }, [savedLocations]);

  useEffect(() => {
    //set saved location options with the saved locations title and value as id
    const saveRadioLabel = radios.map((radio) => ({
      label: radio?.name || radio?.model || radio?.brand,
      value: radio.id,
    }));
    saveRadioLabel.push({ label: "View/Add More Radios", value: "add" });
    setSavedRadioOptions(saveRadioLabel);
  }, [radios]);

  useEffect(() => {
    //set saved location options with the saved locations title and value as id
    const saveAntennaLabel = antennas.map((antenna) => ({
      label: antenna?.name || antenna?.model || antenna?.brand,
      value: antenna.id,
    }));
    saveAntennaLabel.push({ label: "View/Add More Antennas", value: "add" });
    setSavedAntennaOptions(saveAntennaLabel);
  }, [antennas]);

  useEffect(() => {
    if (selectedLogbook?.defaultLocation?.id) {
      setSelectedLocationOption(selectedLogbook?.defaultLocation?.id);
    }
    if (selectedLogbook?.radio?.id) {
      setSelectedRadioOption(selectedLogbook?.radio?.id);
    }
    if (selectedLogbook?.antenna?.id) {
      setSelectedAntennaOption(selectedLogbook?.antenna?.id);
    }
  }, [selectedLogbook]);

  const editLogbook = (data: LogBookModel) => {
    dispatch(setEditLogBook(data));
  };

  const fetchUserSavedLocation = () => {
    dispatch(fetchSavedLocation());
  };

  const clearLocationForm = () => {
    setLocationForm({
      locationName: "",
      locationType: "",
      locationCountry: "",
      locationState: "",
      locationLat: "",
      locationLong: "",
      locationQTH: "",
      locationGrid: "",
      defaultLocation: false,
      isCustom: false,
    });
  };

  const findLocation = async () => {
    setLocationLoading(true);
    try {
      const coor: CoordinatesParams = await fetchCoordinates();

      if (coor) {
        setLocationForm((prev) => ({
          ...prev,
          locationLat: `${coor.latitude}`,
          locationLong: `${coor.longitude}`,
        }));
        const gridSquare = calculateMaidenhead(coor.latitude, coor.longitude);
        if (gridSquare) {
          setLocationForm((prev) => ({
            ...prev,
            locationGrid: gridSquare,
          }));
          const locationInfo: any = await getCountryAndStateFromGrid({
            grid: gridSquare,
          });
          if (locationInfo && locationInfo.data) {
            setLocationForm((prev) => ({
              ...prev,
              locationCountry: locationInfo?.data?.country || "",
              locationState:
                locationInfo?.data?.state || locationInfo?.data?.city || "",
              locationQTH: locationInfo?.data?.formattedAddress || "",
            }));
          }
          // console.log("locationInfo : ", locationInfo);
        }
        setLocationLoading(false);
      } else {
        handlePrimaryModal({
          title:
            "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
          type: "Location Permission",
          onCancelPressed: () => handlePrimaryModal(null),
          onSubmitPressed: () => {
            handlePrimaryModal(null);
          },
          submitText: "Ok",
          cancelText: "Cancel",
        });
      }
    } catch (e) {
      setLocationLoading(false);
    }
  };
  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const saveLocation = () => {
    if (locationForm.locationName) {
      if (locationForm.locationType) {
        if (locationForm.locationCountry) {
          if (locationForm.locationState) {
            if (locationForm.locationLat) {
              if (locationForm.locationLong) {
                if (locationForm.locationGrid) {
                  let data: SavedLocation = {
                    name: locationForm.locationName,
                    locationType: locationForm.locationType,
                    country: locationForm.locationCountry,
                    state: locationForm.locationState,
                    qth: locationForm.locationQTH,
                    default: locationForm.defaultLocation,
                    lat: parseFloat(locationForm.locationLat),
                    lng: parseFloat(locationForm.locationLong),
                    gridsquare: locationForm.locationGrid,
                  };
                  if (location?.state?.data?.id) {
                    data.id = location?.state?.data?.id;
                  }
                  dispatch(addSavedLocation(data));
                } else {
                  toast("Please enter location grid.");
                }
              } else {
                toast("Please enter location latitude.");
              }
            } else {
              toast("Please enter location longitude.");
            }
          } else {
            toast("Please enter location state.");
          }
        } else {
          toast("Please enter location country.");
        }
      } else {
        toast("Please select location type.");
      }
    }
  };

  const deleteUserSavedLocation = (locationId: string) => {
    // console.log("deleteUserSavedLocation", locationId);
    dispatch(deleteSavedLocation(locationId));
  };

  const saveLogbookSettings = (payload: LogbookSettingsForm) => {
    const {
      radio,
      antenna,
      myPark,
      myParks,
      logbookStyle,
      locationSource,
      ...rest
    } = payload;

    let parksData = myParks;
    let name = "";

    if (
      !payload?.name &&
      selectedLogbook?.name?.split("@")?.length &&
      selectedLogbook?.name?.split("@")?.length > 1 &&
      selectedLogbook?.logbookStyle === Templates.POTA &&
      Object.keys(myParks || {}).length > 0
    ) {
      name = `${
        payload?.defaultCallSign || selectedLogbook?.defaultCallSign
      } @ ${Object.keys(myParks || {}).join(", ")}`;
    }

    const { added, removed } = compareObjects(
      myParks || {},
      selectedLogbook?.myParks || {}
    );

    if (removed?.length > 0) {
      const newMyParks = { ...selectedLogbook?.myParks };

      removed.forEach((key) => {
        newMyParks[key] = deleteField() as any;
      });

      parksData = newMyParks;
    }

    let defaultLocation = null;

    if (locationOption === 1) {
      defaultLocation = {
        ...savedLocations.find(
          (location) => location.id === payload?.defaultLocation
        ),
        isCustom: false,
      };
    } else if (locationOption === 2) {
      defaultLocation = {
        isCustom: true,
        country: locationForm?.locationCountry,
        state: locationForm?.locationState,
        qth: locationForm?.locationQTH || "",
        lat: parseFloat(locationForm?.locationLat),
        lng: parseFloat(locationForm?.locationLong),
        gridsquare: locationForm?.locationGrid,
        id: "",
        locationType: "",
        default: false,
        name: "",
        uid: "",
        locationSource: locationSource || LocationSource.NONE,
      };
    } else if (
      payload?.defaultLocation &&
      typeof payload?.defaultLocation === "string"
    ) {
      defaultLocation = {
        ...savedLocations.find(
          (location) => location.id === payload?.defaultLocation
        ),
        isCustom: false,
      };
    } else if (typeof payload?.defaultLocation === "object") {
      defaultLocation = payload?.defaultLocation;
      if (locationSource) defaultLocation.locationSource = locationSource;
    }

    const logBookSettings = {
      ...rest,
      ...(defaultLocation
        ? {
            defaultLocation,
          }
        : {}),
      ...(radio
        ? {
            radio: radios.find((radio) => radio.id === payload?.radio),
          }
        : {}),
      ...(antenna
        ? {
            antenna: antennas.find(
              (antenna) => antenna.id === payload?.antenna
            ),
          }
        : {}),
      ...(logbookStyle ? { logbookStyle } : {}),
      ...(Object.keys(parksData || {}).length ? { myParks: parksData } : {}),
      ...(name ? { name } : {}),
    };

    editLogbook({
      id: selectedLogbook?.id || "",
      ...logBookSettings,
    });

    LogBookService.batchUpdateNameByLogBookId(selectedLogbook?.id || "", payload?.name || name);
  };

  const updateLogbookSettings = (payload: LogbookSettingsForm) => {
    const { radio, antenna, ...rest } = payload;

    editLogbook({
      id: selectedLogbook?.id || "",
      ...rest,
    });
  };

  const editLogbookLocation = (selectedOption: string) => {
    editLogbook({
      ...selectedLogbook,
      defaultLocation:
        savedLocations.find((location) => location.id === selectedOption) ||
        null,
    });
  };

  return {
    editLogbook,
    selectedLogbook,
    logbookLoading,
    fetchUserSavedLocation,
    logbookSettingLoading,
    savedLocations,
    clearLocationForm,
    findLocation,
    locationLoading,
    saveLocation,
    savedLocationOptions,
    selectedLocationOption,
    setSelectedLocationOption,
    deleteUserSavedLocation,
    savedRadioOptions,
    selectedRadioOption,
    setSelectedRadioOption,
    savedAntennaOptions,
    selectedAntennaOption,
    setSelectedAntennaOption,
    radios,
    antennas,
    saveLogbookSettings,
    editLogbookLocation,
    locationForm,
    setLocationForm,
    locationOption,
    setLocationOption,
    updateLogbookSettings,
    stations,
  };
};

export default useLogbookSetting;
