import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import DropDownTitled from "components/DropDown/DropDownTitled";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import { Form, Formik } from "formik";
import {
  convertBandValue,
  filterValidProps,
  findBandAndModeForFrequency,
  getDxDataFromCountry,
} from "helpers/Utils";
import CustomValidation from "helpers/Validation";
import { useEditContact } from "hooks/useEditContact.hook";
import { QuickSettings } from "./QuickSettings";
import useContest from "hooks/contest.hook";
import { useEffect, useState } from "react";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import useLogbookSetting from "hooks/logbookSetting.hook";
import SearchCountryPicker from "components/Inputs/SearchCountryPicker";
import { TheirParkField } from "./TheirParksField";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

export const EditContactForm = ({ isBulkEdit = false }) => {
  const { contestDetail, fetchContestDetail, saveContestDetail } = useContest();
  const { selectedLogbook } = useLogbookSetting();
  const {
    editContactModalData,
    editLogBookContact,
    isSavingOnLoading,
    setConfirmChangesModal,
  } = useEditContact();
  const [selectedSecondaryField, setSelectedSecondaryField] = useState(-1);
  const isPota = editContactModalData?.tags?.includes("POTA");
  const initialData = {
    theirCallsign: editContactModalData?.theirCallsign
      ? editContactModalData?.theirCallsign
      : "",
    time: editContactModalData?.time ? editContactModalData?.time : "",
    date: editContactModalData?.date ? editContactModalData?.date : "",
    operator: editContactModalData?.operator
      ? editContactModalData?.operator
      : "",
    qth: editContactModalData?.qth ? editContactModalData?.qth : "",
    rstSent: editContactModalData?.rstSent ? editContactModalData?.rstSent : "",
    rstRCVD: editContactModalData?.rstRCVD ? editContactModalData?.rstRCVD : "",
    notes: editContactModalData?.notes ? editContactModalData?.notes : "",
    state: editContactModalData?.state ? editContactModalData?.state : "",
    country: editContactModalData?.country ? editContactModalData?.country : "",
    grid: editContactModalData?.grid ? editContactModalData?.grid : "",
    frequency: editContactModalData?.frequency
      ? editContactModalData?.frequency
      : "",
    band: editContactModalData?.band ? editContactModalData?.band : "",
    userQth: editContactModalData?.userQth ? editContactModalData?.userQth : "",
    userMode: editContactModalData?.userMode
      ? editContactModalData?.userMode
      : "",
    myCallSign: editContactModalData?.myCallSign
      ? editContactModalData?.myCallSign
      : "",
    userGrid: editContactModalData?.userGrid
      ? editContactModalData?.userGrid
      : "",
    power: editContactModalData?.power ? editContactModalData?.power : "",
    theirName: editContactModalData?.theirName
      ? editContactModalData?.theirName
      : "",
    myRadio: editContactModalData?.myRadio ? editContactModalData?.myRadio : "",
    myAntenna: editContactModalData?.myAntenna
      ? editContactModalData?.myAntenna
      : "",
    adiError: false,
    exchangeTwo: editContactModalData?.exchangeTwo
      ? editContactModalData?.exchangeTwo
      : "",
    exchangeOne: editContactModalData?.exchangeOne
      ? editContactModalData?.exchangeOne
      : "",
    exchangeThree: editContactModalData?.exchangeThree
      ? editContactModalData?.exchangeThree
      : "",
    exchangeFour: editContactModalData?.exchangeFour
      ? editContactModalData?.exchangeFour
      : "",
    myRadioId: editContactModalData?.myRadioId
      ? editContactModalData?.myRadioId
      : "",
    myAntennaId: editContactModalData?.myAntennaId
      ? editContactModalData?.myAntennaId
      : "",
    mySavedLocationId: editContactModalData?.mySavedLocationId
      ? editContactModalData?.mySavedLocationId
      : "",
    theirPark: editContactModalData?.theirPark
      ? editContactModalData?.theirPark
      : "",
    theirParks: editContactModalData?.theirParks || {},
    logBookId: editContactModalData?.logBookId
      ? editContactModalData?.logBookId
      : "",
    dxccNumber: editContactModalData?.dxccNumber,
    continent: editContactModalData?.continent,
    flagCode: editContactModalData?.flagCode,
    countryCode: editContactModalData?.countryCode,
  };

  useEffect(() => {
    if (selectedLogbook?.contestId) {
      fetchContestDetail(selectedLogbook?.contestId!);
    }
  }, [selectedLogbook?.contestId]);

  // useEffect(() => {
  //   return () => {
  //     saveContestDetail(null);
  //   };
  // }, []);

  useEffect(() => {
    if (Object.keys(editContactModalData?.theirParks || {}).length) {
      setSelectedSecondaryField(2);
    }
  }, [editContactModalData?.theirPark]);

  const renderSecondaryFields = (values: any, setValues: any) => {
    if (selectedSecondaryField === 2) {
      return (
        <div className="flex items-start w-full gap-3">
          <TheirParkField {...{ values, setValues }} />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <Formik<any>
      initialValues={!isBulkEdit ? initialData : {}}
      {...(!isBulkEdit
        ? { validationSchema: CustomValidation.AddContactValidationSchema }
        : {})}
      onSubmit={async (values, { setSubmitting }) => {
        if (!isBulkEdit && editContactModalData && values) {
          editLogBookContact(values, editContactModalData);
        } else if (!!isBulkEdit && values) {
          const filteredValues = filterValidProps(values);
          if (Object.keys(filteredValues).length) {
            setConfirmChangesModal(filteredValues);
          } else {
            showToast({
              type: ToastTypes.ERROR,
              message: "No changes found",
            });
          }
        }
      }}
    >
      {({
        errors,
        touched,
        resetForm,
        handleChange,
        submitForm,
        values,
        setValues,
        setTouched,
      }) => {
        const onFrequencyBlur = (frequency: string, setValues: any) => {
          try {
            const freqNumber = parseFloat(frequency);
            const bandMode = findBandAndModeForFrequency(freqNumber);
            if (bandMode) {
              setValues((prev: any) => {
                return {
                  ...prev,
                  band: bandMode.band,
                  userMode: bandMode.mode,
                };
              });
              // setUserBand(bandMode.band);
              // setUserMode(bandMode.mode);
            } else {
              // setUserBand("");
              // setUserMode("");
            }
          } catch (e) {}
        };

        return (
          <>
            <QuickSettings />
            <Form className="flex flex-col w-full">
              <div className="w-full flex flex-wrap gap-x-2 md:gap-x-4 bg-[#151541] px-2 sm:px-3 py-5">
                <h5 className="font-[Play] text-2xl font-bold text-white py-2 w-full">
                  Edit Contact
                </h5>
                <TitledInput
                  setText={(e) => {
                    setValues((prev: any) => {
                      return {
                        ...prev,
                        theirCallsign: e.target.value.toUpperCase().trim(),
                      };
                    });
                  }}
                  name="theirCallsign"
                  title="Their Callsign"
                  placeHolder="Their Callsign"
                  tabindex={31}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  value={values.theirCallsign}
                  className={"mb-3"}
                  {...{
                    error: !isBulkEdit
                      ? !values.theirCallsign
                        ? true
                        : errors?.theirCallsign && touched?.theirCallsign
                        ? errors?.theirCallsign
                        : undefined
                      : false,
                  }}
                />
                <TitledInput
                  setText={handleChange}
                  name="rstSent"
                  title="RST Sent"
                  info='Signal report that you gave to the other operator. "59" is a good signal.'
                  placeHolder="RST Sent"
                  value={values.rstSent}
                  tabindex={32}
                  className={"mb-3"}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  {...{
                    error: !isBulkEdit
                      ? !values.rstSent
                        ? true
                        : errors?.rstSent && touched?.rstSent
                        ? errors?.rstSent
                        : undefined
                      : false,
                  }}
                />
                <TitledInput
                  setText={handleChange}
                  name="rstRCVD"
                  title="RST RCVD"
                  info='Signal report that you received from the other operator. "59" would be a good signal.'
                  placeHolder="RST RCVD"
                  value={values.rstRCVD}
                  tabindex={33}
                  className={"mb-3"}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  {...{
                    error: !isBulkEdit
                      ? !values.rstRCVD
                        ? true
                        : errors?.rstRCVD && touched?.rstRCVD
                        ? errors?.rstRCVD
                        : undefined
                      : false,
                  }}
                />
                <InputWithButton
                  setText={handleChange}
                  name="frequency"
                  title="Frequency"
                  info="Frequency on which you made the contact, you can get this from reading your radio."
                  placeHolder="Frequency"
                  onFieldBlur={() =>
                    onFrequencyBlur(values.frequency, setValues)
                  }
                  tabindex={34}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  value={values.frequency}
                  btnTxt="MHz"
                  className={"mb-3"}
                  fitBtn
                  // error={
                  //   !values.frequency
                  //     ? true
                  //     : errors?.frequency && touched?.frequency
                  //     ? errors?.frequency
                  //     : undefined
                  // }
                />
                <DropDownTitled
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="band"
                  info="The band you made the contact on."
                  value={values.band}
                  title="Band"
                  placeHolder="Band"
                  tabindex={5}
                  className={"mb-1"}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
                  lists={AllBandsOption}
                  getTitle={(item) =>
                    convertBandValue(item.name, item.unit) + item.unit
                  }
                  getValue={(item) => item.name}
                />
                <DropDownTitled
                  onChange={(e) => {
                    handleChange(e);
                    // setUserMode(e.target.value);
                  }}
                  name="userMode"
                  info="The mode of operation you are using on your radio."
                  value={values.userMode}
                  title="Mode"
                  placeHolder="Mode"
                  tabindex={36}
                  className={"mb-3"}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  lists={Modes}
                  // error={
                  //   !values?.userMode
                  //     ? true
                  //     : errors?.userMode && touched?.userMode
                  //     ? errors?.userMode
                  //     : undefined
                  // }
                />
                <TitledInput
                  setText={handleChange}
                  name="power"
                  title="Power (W)"
                  placeHolder="Power (W)"
                  tabindex={37}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  value={values.power}
                  className={"mb-3"}
                  error={
                    errors?.power && touched?.power ? errors?.power : undefined
                  }
                />

                <TitledTimeInput
                  setText={(e) => {
                    // handleChange(e);
                    setValues((prev: any) => {
                      return { ...prev, time: e };
                    });
                    // setTime(e);
                    // setUserEditTimeInput(e);
                  }}
                  name="time"
                  title="Time"
                  placeHolder="00:00 AM"
                  tabindex={40}
                  className={"mb-3"}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  type="time"
                  value={values.time}
                  {...{
                    error: !isBulkEdit
                      ? !values.time
                        ? true
                        : errors?.time && touched?.time
                        ? errors?.time
                        : undefined
                      : false,
                  }}
                />
                <TitledInput
                  setText={(e) => {
                    handleChange(e);
                    // setDate(e.target.value);
                  }}
                  name="date"
                  title="Date"
                  placeHolder="dd/mm/yyyy"
                  tabindex={41}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  type="date"
                  value={values.date}
                  className={"mb-3"}
                  {...{
                    error: !isBulkEdit
                      ? !values.date
                        ? true
                        : errors?.date && touched?.date
                        ? errors?.date
                        : undefined
                      : false,
                  }}
                  props={
                    {
                      // max: new Date().toJSON().slice(0, 10),
                    }
                  }
                />
                <SearchCountryPicker
                  name="country"
                  title="Their Country"
                  info="Country of the other operator."
                  tabindex={8}
                  value={values?.country}
                  countryCode={values?.countryCode}
                  placeHolder="Country"
                  dxccNumber={values?.dxccNumber || ""}
                  width={
                    values?.country && values?.country.length
                      ? "w-[48.5%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                      : "w-[48.5%] sm:w-[23.5%] md:w-[39%] xl:w-[27.5%]"
                  }
                  setText={(val) => {
                    const dxccData = getDxDataFromCountry(val);

                    setValues((prev: any) => {
                      return {
                        ...prev,
                        country: val,
                        state: "",
                        grid: val !== values?.country ? "" : values?.grid,
                        dxccNumber: dxccData?.dxccNumber || "",
                        continent: dxccData?.continent || "",
                        flagCode: dxccData?.flagCode || "",
                        countryCode: dxccData?.countryCode || "",
                      };
                    });
                  }}
                  error={
                    errors?.country && touched?.country
                      ? errors?.country
                      : undefined
                  }
                />

                {values?.country && values?.country.length ? (
                  <TitledRegionPicker
                    setText={(val) => {
                      setValues((prev: any) => {
                        return { ...prev, state: val };
                      });
                    }}
                    name="state"
                    title="Their State"
                    info="State of the other operator."
                    placeHolder="State"
                    tabindex={39}
                    width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                    className={"mb-3 !bg-[#23234C] !text-white"}
                    value={values.state}
                    error={
                      errors?.state && touched?.state
                        ? errors?.state
                        : undefined
                    }
                    country={values.country}
                    valueType={ values?.state?.length >=3 ? "full": "short"}
                  />
                ) : null}
                <TitledInput
                  setText={handleChange}
                  name="qth"
                  title="Their City"
                  info="Special location of the other operator. If they are at a park, for instance, you can write the identifier of the park."
                  placeHolder="City"
                  tabindex={43}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  className={"mb-3"}
                  value={values.qth}
                  error={errors?.qth && touched?.qth ? errors?.qth : undefined}
                />
                <TitledInput
                  setText={handleChange}
                  name="grid"
                  title="Their Grid"
                  placeHolder="Their Grid"
                  info="Special location of the other operator. If they are at a park, for instance, you can write the identifier of the park."
                  tabindex={42}
                  className={"mb-3"}
                  value={values.grid}
                  width={"w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"}
                  error={
                    errors?.grid && touched?.grid ? errors?.grid : undefined
                  }
                />
                <TitledInput
                  setText={handleChange}
                  name="theirName"
                  title="Their Name"
                  placeHolder="Their Name"
                  tabindex={44}
                  width="w-[47%] sm:w-[23.5%] md:w-[21%] xl:w-[13%]"
                  value={values.theirName}
                  className={"mb-3"}
                  error={
                    errors?.theirName && touched?.theirName
                      ? errors?.theirName
                      : undefined
                  }
                />
                {/* <div
                  className={`flex items-end pb-3 ${
                    values?.country && values?.country.length
                      ? "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27.5%]"
                      : "w-full sm:w-[48.5%] md:w-[39%] xl:w-[27.5%] mt-5 mb-2 sm:mb-0 sm:mt-0"
                  }`}
                >
                  <BorderButtonSecondary
                    isLoading={isGettingLocationIsLoading}
                    text={"Find My Location"}
                    onClick={checkLocationPermission}
                    childClassName="text-sm"
                  />
                </div> */}

                {selectedLogbook?.contestId ? (
                  <div className="flex flex-wrap gap-6 w-full items-center">
                    {contestDetail?.exchangeOne ||
                    contestDetail?.exchangeTwo ||
                    contestDetail?.exchangeThree ||
                    contestDetail?.exchangeFour ? (
                      <p className="text-white font-[Play] text-sm font-bold">
                        CONTEST EXCHANGE
                      </p>
                    ) : null}

                    {contestDetail?.exchangeOne ? (
                      <TitledInputGradient
                        setText={handleChange}
                        name="exchangeOne"
                        title={contestDetail?.exchangeOne}
                        placeHolder="Enter Answer"
                        value={values.exchangeOne}
                        width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                        className={"mb-3 placeholder-[#000]"}
                        tabindex={50}
                        error={undefined}
                      />
                    ) : null}
                    {contestDetail?.exchangeTwo ? (
                      <TitledInputGradient
                        setText={handleChange}
                        name="exchangeTwo"
                        title={contestDetail?.exchangeTwo}
                        placeHolder="Enter Answer"
                        value={values.exchangeTwo}
                        width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                        className={"mb-3 placeholder-[#000]"}
                        tabindex={51}
                        error={undefined}
                      />
                    ) : null}

                    {contestDetail?.exchangeThree ? (
                      <TitledInputGradient
                        setText={handleChange}
                        name="exchangeThree"
                        title={contestDetail?.exchangeThree}
                        placeHolder="Enter Answer"
                        value={values.exchangeThree}
                        width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                        className={"mb-3 placeholder-[#000]"}
                        tabindex={51}
                        error={undefined}
                      />
                    ) : null}

                    {contestDetail?.exchangeFour ? (
                      <TitledInputGradient
                        setText={handleChange}
                        name="exchangeFour"
                        title={contestDetail?.exchangeFour}
                        placeHolder="Enter Answer"
                        value={values.exchangeFour}
                        width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                        className={"mb-3 placeholder-[#000]"}
                        tabindex={51}
                        error={undefined}
                      />
                    ) : null}
                  </div>
                ) : null}

                <TitledInput
                  setText={handleChange}
                  name="notes"
                  title="Notes"
                  placeHolder="Notes"
                  value={values.notes}
                  tabindex={52}
                  width={"w-full"}
                  className={"mb-3"}
                  error={
                    errors?.notes && touched?.notes ? errors?.notes : undefined
                  }
                />
                {/* <div className="w-full sm:w-[49%] md:w-[39%] xl:w-[27.5%] flex justify-between mb-8 mt-7 sm:mt-0">
                <div className="flex items-end w-[47.5%]" tabIndex={53}>
                  <BorderButtonSecondary
                    //   isLoading={isSavingOnLoading}
                    text={"Save"}
                    onClick={() => {
                      submitForm();
                    }}
                    childClassName="text-sm"
                  />
                </div>
                <div className="flex items-end w-[47.5%]" tabIndex={54}>
                  <BorderButtonSecondary
                    isLoading={false}
                    text={"Cancel"}
                    onClick={() => {
                      setEditContactModalData(null);
                    }}
                    childClassName="text-sm"
                  />
                </div>
              </div> */}
                <SelectableButtonsGroup
                  initialSelected={selectedSecondaryField}
                  toggle
                  actions={[
                    {
                      id: 2,
                      icon: Icons.parkWhite,
                      label: isPota ? "Park to Park" : "Their Park Number",
                      tabIndex: 9,
                    },
                  ]}
                  onButtonChange={(action) => {
                    if (action && (action.id as number) > 0) {
                      setSelectedSecondaryField(action.id as number);
                    } else {
                      setSelectedSecondaryField(-1);
                    }
                  }}
                />
                {selectedSecondaryField > 0 ? (
                  <div className="flex w-full bg-indigo-500 bg-opacity-10  mt-4 items-center p-4">
                    {renderSecondaryFields(values, setValues)}
                  </div>
                ) : null}
              </div>
              <div className="w-full py-10 flex justify-center">
                <div
                  className="flex items-end w-full sm:w-[50%] md:w-[40%] lg:w-[30.5%]"
                  tabIndex={53}
                >
                  <BorderButtonSecondary
                    isLoading={isSavingOnLoading}
                    text={
                      isBulkEdit ? "Preview Your Changes" : "Update Contact"
                    }
                    onClick={() => {
                      submitForm();
                    }}
                    childClassName="text-sm"
                  />
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
