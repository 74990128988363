import { BorderButtonSecondaryProps } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import LottieFiles from "assets/LottieFiles";
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import { getAddContactSuccessSelector } from "store/Reducers/config.reducer";
import { useEffect } from "react";
import { addContactSuccess } from "store/Actions/config.actions";
function LogContactButton({
  text,
  onClick,
  className,
  isLoading,
  fontStyle,
  height,
  childHeight,
  childClassName,
  icon,
  selected,
  tabIndex,
}: BorderButtonSecondaryProps) {
  const contactSuccess = useSelector(getAddContactSuccessSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(addContactSuccess(false));
    }, 1500);
  }, [contactSuccess]);
  return (
    <div
      tabIndex={tabIndex}
      className={`bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full ${
        className ? className : ""
      } ${isLoading ? "pointer-events-none" : "cursor-pointer"} ${
        height ? height : "h-8"
      } rounded-full flex justify-center items-center`}
      onClick={onClick}
    >
      <div
        className={`${childHeight ? childHeight : ""} ${
          childClassName ? childClassName : "text-sm sm:text-base"
        } text-white ${
          selected ? "bg-[#002C87]" : "bg-[#1D204A]"
        } w-[calc(100%-3px)] h-[calc(100%-3px)] rounded-full flex justify-center items-center font-Play`}
      >
        {icon ? icon : ""}
        {isLoading ? (
          <div className="w-14 h-14">
            <Lottie animationData={LottieFiles.Loading} loop={true} />
          </div>
        ) : contactSuccess ? (
          <div className="w-14 h-14">
            <Lottie animationData={LottieFiles.CheckMark} loop={true} />
          </div>
        ) : (
          text
        )}
      </div>
    </div>
  );
}

export default LogContactButton;
