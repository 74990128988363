import { LocationSource } from "components/DefaultLocationSettings";
import { getCountryAndStateFromGrid } from "firebase-config";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { calculateMaidenhead } from "helpers/Utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoordinatesParams, editUserProfile } from "store/Actions/profile.actions";
import { getIsProfileLoadingSelector } from "store/Reducers/loading.reducer";
import { getAntennasSelector, getRadiosSelector, getSavedLocationsSelector } from "store/Reducers/logBook.reducer";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { LogbookSettingsForm, UserProfile } from "types/Models";
import useConfig from "./config.hook";

function useDefaultLogbooksSettings() {
  const dispatch = useDispatch();
  const isProfileLoading = useSelector(getIsProfileLoadingSelector);
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);
  const [defaultLogbookSettings, setDefaultLogbookSettings] = useState<any>(null);
  const { handlePrimaryModal } = useConfig();

  const savedLocations = useSelector(getSavedLocationsSelector);
  const radios = useSelector(getRadiosSelector);
  const antennas = useSelector(getAntennasSelector);
  const [locationLoading, setLocationLoading] = useState(false);
   
  const [locationOption, setLocationOption] = useState<any>(1);

  const [locationForm, setLocationForm] = useState({
    locationName: "",
    locationType: "",
    locationCountry: "",
    locationState: "",
    locationLat: "",
    locationLong: "",
    locationQTH: "",
    locationGrid: "",
    defaultLocation: false,
    isCustom: false,
  });

  useEffect(() => {
    if (defaultLogbookSettings && defaultLogbookSettings?.defaultLocation?.isCustom) {
      setLocationOption(2);
      setLocationForm({
        locationCountry: defaultLogbookSettings?.defaultLocation?.country || "",
        locationState: defaultLogbookSettings?.defaultLocation?.state || "",
        locationGrid: defaultLogbookSettings?.defaultLocation?.gridsquare || "",
        locationLat: defaultLogbookSettings?.defaultLocation?.lat?.toString() || "",
        locationLong: defaultLogbookSettings?.defaultLocation?.lng?.toString() || "",
        locationName: "",
        locationType: "",
        locationQTH: "",
        defaultLocation: false,
        isCustom: defaultLogbookSettings?.defaultLocation?.isCustom || false,
      });
    }
  }, [defaultLogbookSettings]);

  useEffect(() => {
    if (!isProfileLoading && userProfile) {
      setDefaultLogbookSettings(userProfile.defaultLogbookSettings);
    }
  }, [isProfileLoading, userProfile]);

  const updateDefaultLogbookSettings = (data: LogbookSettingsForm) => {
    const {
        radio,
        antenna,
        logbookStyle,
        locationSource,
        ...rest
      } = data;
  
  
      let defaultLocation = null;
  
      if (locationOption === 1) {
        defaultLocation = {
          ...savedLocations.find(
            (location) => location.id === data?.defaultLocation
          ),
          isCustom: false,
        };
      } else if (locationOption === 2) {
        defaultLocation = {
          isCustom: true,
          country: locationForm?.locationCountry,
          state: locationForm?.locationState,
          qth: locationForm?.locationQTH || "",
          lat: parseFloat(locationForm?.locationLat),
          lng: parseFloat(locationForm?.locationLong),
          gridsquare: locationForm?.locationGrid,
          id: "",
          locationType: "",
          default: false,
          name: "",
          uid: "",
          locationSource: locationSource || LocationSource.NONE,
        };
      } else if (
        data?.defaultLocation &&
        typeof data?.defaultLocation === "string"
      ) {
        defaultLocation = {
          ...savedLocations.find(
            (location) => location.id === data?.defaultLocation
          ),
          isCustom: false,
        };
      } else if (typeof data?.defaultLocation === "object") {
        defaultLocation = data?.defaultLocation;
        if (locationSource) defaultLocation.locationSource = locationSource;
      }
  
      const defaultLogbookSettingsData = {
        ...rest,
        ...(defaultLocation
          ? {
              defaultLocation,
            }
          : {}),
        ...(radio
          ? {
              radio: radios.find((radio) => radio.id === data?.radio),
            }
          : {}),
        ...(antenna
          ? {
              antenna: antennas.find(
                (antenna) => antenna.id === data?.antenna
              ),
            }
          : {}),
        ...(logbookStyle ? { logbookStyle } : {}),
      };

    const payload = {
      defaultLogbookSettings: defaultLogbookSettingsData,
    };

    dispatch(editUserProfile(payload as any));
  };


  const findLocation = async () => {
    setLocationLoading(true);
    try {
      const coor: CoordinatesParams = await fetchCoordinates();

      if (coor) {
        setLocationForm((prev) => ({
          ...prev,
          locationLat: `${coor.latitude}`,
          locationLong: `${coor.longitude}`,
        }));
        const gridSquare = calculateMaidenhead(coor.latitude, coor.longitude);
        if (gridSquare) {
          setLocationForm((prev) => ({
            ...prev,
            locationGrid: gridSquare,
          }));
          const locationInfo: any = await getCountryAndStateFromGrid({
            grid: gridSquare,
          });
          if (locationInfo && locationInfo.data) {
            setLocationForm((prev) => ({
              ...prev,
              locationCountry: locationInfo?.data?.country || "",
              locationState:
                locationInfo?.data?.state || locationInfo?.data?.city || "",
              locationQTH: locationInfo?.data?.formattedAddress || "",
            }));
          }
          // console.log("locationInfo : ", locationInfo);
        }
        setLocationLoading(false);
      } else {
        handlePrimaryModal({
          title:
            "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
          type: "Location Permission",
          onCancelPressed: () => handlePrimaryModal(null),
          onSubmitPressed: () => {
            handlePrimaryModal(null);
          },
          submitText: "Ok",
          cancelText: "Cancel",
        });
      }
    } catch (e) {
      setLocationLoading(false);
    }
  };

  return {
    updateDefaultLogbookSettings,
    defaultLogbookSettings,
    isLoading : isProfileLoading,
    locationOption,
    setLocationOption,
    locationForm,
    setLocationForm,
    locationLoading,
    findLocation,
  };
}

export default useDefaultLogbooksSettings;
