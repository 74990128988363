import { 
    QSO_MAX_PAGE_JUMP_SIZE, 
    QSO_MANAGER_PAGE_SIZE 
} from "constants/index";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";

interface PaginationProps {
    total: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ total, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(total / QSO_MANAGER_PAGE_SIZE);
    const maxPageJump = QSO_MAX_PAGE_JUMP_SIZE; // Limit to # pages for jumping
    const maxPageNumber = Math.floor(total / QSO_MANAGER_PAGE_SIZE) + 1;

    // Determine the range of pages to display
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + maxPageJump - 1, maxPageNumber);

    // Adjust startPage if we're near the end
    if (endPage - startPage < maxPageJump - 1) {
        startPage = Math.max(1, endPage - maxPageJump + 1);
    }

    const pageNumbers: number[] = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex justify-between items-center mt-5 no-select">
            <span className="font-[Barlow] text-xs text-white">
                Showing {(currentPage - 1) * QSO_MANAGER_PAGE_SIZE + 1} - {Math.min(currentPage * QSO_MANAGER_PAGE_SIZE, total)} of {total} QSOs
            </span>
            <div className="flex items-center space-x-2">
                <div className="flex justify-between items-center">
                    <div
                        className={`bg-[#0243DD] h-7 w-7 flex justify-center items-center mr-3 cursor-pointer ${currentPage === 1 ? 'opacity-50 pointer-events-none' : ''}`}
                        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                    >
                        <IoCaretBack color="#fff" />
                    </div>
                    {pageNumbers.map((pageNum) => (
                        <div
                            key={pageNum}
                            className={`h-7 w-7 flex justify-center items-center mr-3 cursor-pointer ${currentPage === pageNum ? 'bg-[#0243DD] text-white' : 'bg-[#FFFFFF]'}`}
                            onClick={() => onPageChange(pageNum)}
                        >
                            {pageNum}
                        </div>
                    ))}
                    <div
                        className={`bg-[#0243DD] h-7 w-7 flex justify-center items-center cursor-pointer ${(currentPage === totalPages || currentPage === maxPageNumber) ? 'opacity-50 pointer-events-none' : ''}`}
                        onClick={() => currentPage < totalPages && currentPage < maxPageNumber && onPageChange(currentPage + 1)}
                    >
                        <IoCaretForward color="#fff" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;