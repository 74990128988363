import React, { useEffect } from "react";
import Icons from "assets/Icons";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { subscriptionPlanTypes } from "constants/Awards";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useContest from "hooks/contest.hook";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import { IoIosCloseCircle } from "react-icons/io";
import TimePicker from "react-time-picker";
import { convertBandValue } from "helpers/Utils";

const EditContestModal = () => {
  const {
    showEditContestModal,
    editContest,

    storeContestIdForEditModal,
    name,
    setName,
    image,
    setImage,
    description,
    setDescription,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    membershipLevel,
    setMembershipLevel,
    bands,
    setBands,
    modes,
    setModes,
    geo,
    setGeo,
    multiplier,
    setMultiplier,
    exchangeOne,
    setExchangeOne,
    exchangeTwo,
    setExchangeTwo,
    onPillClick,
    isContestLoading,
    shortDescription,
    setShortDescription,
    onDescriptionTitleChange,
    onDescriptionBodyChange,
    addSectionClicked,
    removeSectionClicked,
    imagePrev,
    setImagePrev,
    setEndTime,
    setStartTime,
    startTime,
    endTime,
    exchangeThree,
    exchangeFour,
    setExchangeThree,
    setExchangeFour,
  } = useContest();

  const getPlans = (selectedPlan: string) => {
    const startIndex = subscriptionPlanTypes.indexOf(selectedPlan);
    return subscriptionPlanTypes.slice(startIndex + 1);
  };

  useEffect(() => {
    // console.log("showEditContestModal: ", showEditContestModal);
    setName(showEditContestModal?.name || "");
    setBands(showEditContestModal?.bands || []);
    setModes(showEditContestModal?.modes || []);
    if (showEditContestModal?.startDate?.seconds) {
      const date: any = new Date(showEditContestModal.startDate.seconds * 1000);
      setStartDate(date.toISOString().slice(0, 10));
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");

      const utcTimeForInput = `${hours}:${minutes}`;
      setStartTime(utcTimeForInput);
    }
    if (showEditContestModal?.endDate?.seconds) {
      const date: any = new Date(showEditContestModal.endDate.seconds * 1000);
      setEndDate(date.toISOString().slice(0, 10));
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");

      const utcTimeForInput = `${hours}:${minutes}`;
      setEndTime(utcTimeForInput);
    }
    setImagePrev(showEditContestModal?.image || "");
    setShortDescription(showEditContestModal?.shortDescription || "");
    setGeo(showEditContestModal?.geo || "");
    setExchangeOne(showEditContestModal?.exchangeOne || "");
    setExchangeTwo(showEditContestModal?.exchangeTwo || "");
    setExchangeThree(showEditContestModal?.exchangeThree || "");
    setExchangeFour(showEditContestModal?.exchangeFour || "");
    setDescription(showEditContestModal?.description || []);
    if (showEditContestModal?.multiplier) {
      setMultiplier(showEditContestModal?.multiplier);
    }
    setMembershipLevel(showEditContestModal?.membershipLevel || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[5%] lg:px-[10%]">
      <div className="overflow-x-hidden w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-3 py-5 items-center">
            <h5 className="font-[Play] text-xl font-bold text-white py-2 ml-3 w-full">
              Edit Contest (Admin)
            </h5>
            <TitledInputDefault
              setText={(e) => setName(e.target.value)}
              name="contestName"
              title="Contest Name"
              placeHolder="Contest Name"
              width={"w-[78.5%]"}
              className={""}
              tabindex={1}
              value={name}
              error={""}
            />
            <TitledInputDefault
              setText={(e) => {
                setImage(e.target?.files[0]);
              }}
              name="contestName"
              title="Featured Image"
              placeHolder="Contest Name"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              type="file"
              // value={image}
              error={""}
            />
            {image || imagePrev ? (
              <div className="w-[200px] h-[200px] relative">
                <img
                  src={image ? URL.createObjectURL(image) : imagePrev}
                  className="h-full"
                  alt=""
                />
                <IoIosCloseCircle
                  color="red"
                  className="absolute top-[-10px] right-[-10px]"
                  size={20}
                  onClick={() => {
                    setImage("");
                    setImagePrev("");
                  }}
                />
              </div>
            ) : null}
            <TitledInputDefault
              setText={(e) => setShortDescription(e.target.value)}
              name="contestName"
              title="Short Description"
              placeHolder="short description"
              width={"w-[78.5%]"}
              className={""}
              tabindex={1}
              value={shortDescription}
              error={""}
            />
            <div className="w-[78.5%] mb-3 flex justify-between">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Description Section
              </p>
              <button
                className="text-[#17F9DA] cursor-pointer"
                onClick={addSectionClicked}
              >
                Add Description Section
              </button>
            </div>
            <div className="w-[78.5%] mb-3 ml-20">
              {description && Array.isArray(description)
                ? description.map((desc, index) => (
                    <div>
                      <div className="w-[78.5%] flex justify-between">
                        <span></span>
                        <button
                          className="text-[red] cursor-pointer justify-end"
                          onClick={() => removeSectionClicked(index)}
                        >
                          Remove Section
                        </button>
                      </div>
                      <TitledInputDefault
                        setText={(e) =>
                          onDescriptionTitleChange(index, e.target.value)
                        }
                        name={"Description " + (index + 1)}
                        title={"Description " + (index + 1)}
                        placeHolder={`Description ${index + 1} Title`}
                        width={"w-[78.5%]"}
                        className={""}
                        tabindex={1}
                        value={desc.title}
                        error={""}
                      />
                      <TitledInputDefault
                        setText={(e) =>
                          onDescriptionBodyChange(index, e.target.value)
                        }
                        name={"Description Body" + (index + 1)}
                        title={"Description Body " + (index + 1)}
                        placeHolder={`Description ${index + 1} Body`}
                        width={"w-[78.5%]"}
                        className={"h-20"}
                        tabindex={1}
                        value={desc.description}
                        error={""}
                        multiple={true}
                      />
                      <hr className="w-[78.5%] mb-4" />
                    </div>
                  ))
                : null}
            </div>
            <div className="flex w-[78.5%] items-center">
              <TitledInputDefault
                setText={(e) => setStartDate(e.target.value)}
                name="contestName"
                title="Start Date / Time (UTC)"
                placeHolder="start date"
                width={"w-[50.5%]"}
                className={""}
                tabindex={2}
                type="date"
                value={startDate}
                error={""}
              />
              <TimePicker
                maxTime="23:59:59"
                locale="sv-sv"
                className={`h-8 bg-[#23234C] text-white font-[Barlow] text-sm w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%] ml-2 mt-[5px]`}
                onChange={(e: any) => {
                  setStartTime(e);
                }}
                value={startTime}
                disableClock={true}
                id=""
                clockClassName="hidden"
              />
            </div>
            <div className="flex w-[78.5%] items-center">
              <TitledInputDefault
                setText={(e) => setEndDate(e.target.value)}
                name="contestName"
                title="End Date / Time (UTC)"
                placeHolder="end date"
                width={"w-[50.5%]"}
                className={""}
                tabindex={2}
                type="date"
                value={endDate}
                error={""}
              />
              <TimePicker
                maxTime="23:59:59"
                locale="sv-sv"
                className={`h-8 bg-[#23234C] text-white font-[Barlow] text-sm w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%] ml-2 mt-[5px]`}
                onChange={(e: any) => {
                  setEndTime(e);
                }}
                value={endTime}
                disableClock={true}
                id=""
                clockClassName="hidden"
              />
            </div>
            <div className="w-[78.5%] mb-1">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Membership Status
              </p>
            </div>
            <div className="flex items-start justify-start w-[78.5%] mt-2 mb-4">
              {subscriptionPlanTypes.map((item, index) => (
                <button
                  onClick={() =>
                    onPillClick(item, getPlans(item), setMembershipLevel)
                  }
                  className={`rounded-full mx-4 ${
                    membershipLevel.includes(item)
                      ? "bg-[#17F9DA] text-black"
                      : "bg-[#23234C] text-white"
                  }  px-6 py-1 font-[Play] text-sm `}
                >
                  {item}
                </button>
              ))}
            </div>

            <div className="w-[78.5%] mt-4">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Bands
              </p>
            </div>
            <div className="flex items-start justify-start w-[78.5%] mt-2 mb-4 flex-wrap">
              {AllBandsOption.map((item, index) => (
                <button
                  onClick={() => onPillClick(item.name, bands, setBands)}
                  className={`rounded-full mx-4 mb-3 ${
                    bands.includes(item.name)
                      ? "bg-[#17F9DA] text-black"
                      : "bg-[#23234C] text-white"
                  }  px-6 py-1 font-[Play] text-sm `}
                >
                  {convertBandValue(item.name, item.unit) + item.unit}
                </button>
              ))}
            </div>

            <div className="w-[78.5%] mt-4">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Modes
              </p>
            </div>
            <div className="flex items-start justify-start w-[78.5%] mt-2 mb-4 flex-wrap">
              {Modes.map((item, index) => (
                <button
                  onClick={() => onPillClick(item, modes, setModes)}
                  className={`rounded-full mx-4 mb-3 ${
                    modes.includes(item)
                      ? "bg-[#17F9DA] text-black"
                      : "bg-[#23234C] text-white"
                  }  px-6 py-1 font-[Play] text-sm `}
                >
                  {item}
                </button>
              ))}
            </div>

            <TitledInputDefault
              setText={(e) => setGeo(e.target.value)}
              name="contestName"
              title="User Geo"
              placeHolder="user geo"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              value={geo}
              error={""}
            />

            <TitledInputDefault
              setText={(e) => setMultiplier(e.target.value)}
              name="contestName"
              title="Points Multiplier"
              placeHolder="points multiplier"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              type="number"
              value={multiplier}
              error={""}
            />

            <TitledInputDefault
              setText={(e) => setExchangeOne(e.target.value)}
              name="contestName"
              title="Contest Exchange 1"
              placeHolder="exchange 1"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              value={exchangeOne}
              error={""}
            />

            <TitledInputDefault
              setText={(e) => setExchangeTwo(e.target.value)}
              name="contestName"
              title="Contest Exchange 2"
              placeHolder="exchange 2"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              value={exchangeTwo}
              error={""}
            />

            <TitledInputDefault
              setText={(e) => setExchangeThree(e.target.value)}
              name="contestName"
              title="Contest Exchange 3"
              placeHolder="exchange 3"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              value={exchangeThree}
              error={""}
            />

            <TitledInputDefault
              setText={(e) => setExchangeFour(e.target.value)}
              name="contestName"
              title="Contest Exchange 4"
              placeHolder="exchange 4"
              width={"w-[78.5%] mb-6"}
              className={""}
              tabindex={2}
              value={exchangeFour}
              error={""}
            />

            <div className="w-full sm:w-[49%] md:w-[39%] xl:w-[27.5%] flex justify-between mb-8 mt-10 sm:mt-0">
              <div className="flex items-end w-[47.5%]">
                <BorderButtonSecondary
                  isLoading={false}
                  text={"Cancel"}
                  onClick={() => {
                    storeContestIdForEditModal(null);
                  }}
                  childClassName="text-sm"
                />
              </div>
              <div className="flex items-end w-[47.5%]">
                <BorderButtonSecondary
                  isLoading={isContestLoading}
                  text={"Save"}
                  onClick={() => editContest()}
                  childClassName="text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute top-22 right-16 cursor-pointer"
        onClick={() => storeContestIdForEditModal(null)}
      >
        <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
      </div>
    </section>
  );
};

export default EditContestModal;
