import NavItem from "components/NavItem";
import {
  navMenuLogIn,
  navMenuMembersDirectory,
  navMenuMembership,
  navMenuMembershipStatus,
  navMenus,
  navMenusSetting,
} from "constants/NavMenus";
import useAuth from "hooks/auth.hook";
import { useEffect, useRef, useState } from "react";
import { IoMdClose, IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import gsap from "gsap";
import useConfig from "hooks/config.hook";
import HeaderNavBar from "components/Header/HeaderNavBar";
import { MY_PROFILE_ROUTE } from "constants/Links";
import useProfile from "hooks/profile.hook";
import { NavMenuProps } from "types/Navigation";
import CustomValidation from "helpers/Validation";
import { useDispatch, useSelector } from "react-redux";
import { getIsCollapsedSelector } from "store/Reducers/profile.reducer";
import { setIsCollapsed } from "store/Actions/profile.actions";
import useIsLargeScreen from "hooks/useIsLargeScreen";

function NavBar() {
  const dispatch = useDispatch();
  const { logOut } = useAuth();
  const { userProfile } = useProfile();
  const navBarContainer = useRef(null);
  const [navMenusMain, setNavMenusMain] = useState<NavMenuProps[]>([]);
  const [navMenusMainOnResponsive, setNavMenusMainOnResponsive] =
    useState<NavMenuProps | null>(null);
  const navMenusSettings = userProfile
    ? navMenusSetting
    : [...navMenusSetting.slice(0, 1), navMenuLogIn];
  const {
    handleNavBarVisibility,
    handlePrimaryModal,
    primaryModalData,
    isMenuOpen,
  } = useConfig();

  const hideNav = () => {
    handleNavBarVisibility(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      gsap.to(navBarContainer.current, { left: 0 });
    } else if (!isMenuOpen) {
      gsap.to(navBarContainer.current, { left: -288 });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.subscriptionStatus === "active") {
        setNavMenusMain([...navMenus, navMenuMembersDirectory]);
        setNavMenusMainOnResponsive(navMenuMembershipStatus);
      } else {
        setNavMenusMain([
          ...navMenus,
          navMenuMembership,
          navMenuMembersDirectory,
        ]);
      }
    } else {
      setNavMenusMain([
        ...navMenus,
        navMenuMembership,
        navMenuMembersDirectory,
      ]);
    }
  }, [userProfile]);

  const isLgScreen = useIsLargeScreen();

  const collapsed = useSelector(getIsCollapsedSelector);

  const setCollapse = (value: boolean) => {
    dispatch(setIsCollapsed(value));
  };

  const handleCollapse = () => {
    setCollapse(!collapsed);
  };

  return (
    <section
      ref={navBarContainer}
      className={`${
        isLgScreen
          ? !collapsed
            ? "w-72 lg:min-w-[200px] xl:min-w-[260px] 2xl:min-w-[288px] transition-all duration-400 ease-in-out"
            : "w-[100px] min-w-[100px] transition-all duration-400 ease-in-out"
          : "w-72 lg:min-w-[200px] xl:min-w-[260px] 2xl:min-w-[288px]"
      } z-50 absolute lg:relative glassmorphism h-screen overflow-visible`}
      style={{ right: 0 }}
    >
      <div
        className="right-0 absolute lg:hidden w-10 h-10 flex justify-center items-center cursor-pointer z-10"
        onClick={hideNav}
      >
        <IoMdClose className="text-white" size={"16px"} />
      </div>
      <HeaderNavBar
        className={
          collapsed && isLgScreen
            ? "!mt-0 !px-1 !h-[80px] mb-16 transition-all duration-500 ease-in-out"
            : ""
        }
      />

      {isLgScreen ? (
        <div
          onClick={handleCollapse}
          className="absolute top-[26px] w-[22px] h-[22px] -right-[10px] hidden lg:flex justify-center items-center rounded-full bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] overflow-hidden p-[2px] cursor-pointer transform transition-transform duration-300 hover:scale-110"
        >
          <div className="w-full h-full bg-white flex justify-center items-center rounded-full">
            {collapsed ? (
              <IoMdArrowForward className="text-[#2650FD]" size={"16px"} />
            ) : (
              <IoMdArrowBack className="text-[#2650FD]" size={"16px"} />
            )}
          </div>
        </div>
      ) : null}

      {navMenusMain.map((item, index) => {
        return (
          <NavItem
            key={index}
            color=""
            link={
              item?.link === MY_PROFILE_ROUTE && !userProfile
                ? "/register"
                : item?.link === MY_PROFILE_ROUTE
                ? `${item?.link}/${CustomValidation.encodeSlash(
                    userProfile?.callSign ? userProfile?.callSign : ""
                  )}?tab=Logbook&contact-tab=entries`
                : item.link
            }
            text={item.title}
            newFeature={item.new}
            icon={item.icon}
            redirect={item?.redirect}
            onPress={() => {}}
            collapsed={collapsed && isLgScreen}
          />
        );
      })}
      {navMenusMainOnResponsive ? (
        <div className="block lg:hidden">
          <NavItem
            color=""
            link={navMenusMainOnResponsive.link}
            text={navMenusMainOnResponsive.title}
            icon={navMenusMainOnResponsive.icon}
            redirect={navMenusMainOnResponsive?.redirect}
            onPress={() => {}}
            collapsed={collapsed && isLgScreen}
          />
        </div>
      ) : null}
      <div className="settingNavBar w-full">
        {navMenusSettings.map((item, index) => {
          return (
            <NavItem
              key={index}
              link={item?.link}
              color={item?.color}
              text={item.title}
              newFeature={item.new}
              icon={item.icon}
              redirect={item?.redirect}
              onPress={async () => {
                if (window.innerWidth < 1024) {
                  hideNav();
                }
                if (item.title === "Log out") {
                  if (primaryModalData) {
                    handlePrimaryModal(null);
                  } else {
                    handlePrimaryModal({
                      title: "Are you sure you want to sign out?",
                      type: "Signout",
                      onCancelPressed: () => handlePrimaryModal(null),
                      onSubmitPressed: () => {
                        logOut();
                        handlePrimaryModal(null);
                      },
                      submitText: "Signout",
                      cancelText: "Cancel",
                    });
                  }
                }
              }}
              collapsed={collapsed && isLgScreen}
            />
          );
        })}
      </div>
    </section>
  );
}

export default NavBar;
