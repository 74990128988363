import { sendAutoUpdateParksDataTrigger } from "firebase-config";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

export const useAdmin = () => {
  const triggerAutoUpdateParksData = async () => {
    try {
      showToast({
        message: "Auto update parks data triggered",
        type: ToastTypes.SUCCESS,
      });

      await sendAutoUpdateParksDataTrigger();
    } catch (error) {
      showToast({
        message: "Error triggering auto update parks data",
        type: ToastTypes.ERROR,
      });
    }
  };

  return {
    triggerAutoUpdateParksData,
  };
};
