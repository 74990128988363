import ActivityIndicator from "components/Loading/ActivityIndicator";
import ActivityLogTable from "components/Table/ActivityLogTable";
import { useUserActivity } from "hooks/useUserActivity.hook";
import { globalNavigate } from "routes/GlobalRoutes";

function ActivityList() {
  const { userActivities, isLoading } = useUserActivity();

  const onClickHandler = (data: any) => {
    globalNavigate("/log-contacts/activity-details?activity_id=" + data.id);
  };

  return (
    <>
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit">
        {isLoading ? (
          <ActivityIndicator size={36} />
        ) : (
          <ActivityLogTable list={userActivities} itemClick={onClickHandler} />
        )}
      </div>
    </>
  );
}

export default ActivityList;
