import { dxccCountryList } from "constants/DXCCCountryList";
import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchSpot } from "store/Actions/spotting.action";
import { SortConfig } from "types/Models";
import { SpotFilters } from "types/Models";

export const useSortAndFilter = (
  initialSortConfig: SortConfig,
  initialFilters: SpotFilters[] = []
) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>(initialSortConfig);
  const [filters, setFilters] = useState<SpotFilters[]>(initialFilters);

  const [filterCountry, setFilterCountry] = useState<any>([]);
  const [filterRegion, setFilterRegion] = useState("");

  const [filterBand, setFilterBand] = useState([]);
  const [filterMode, setFilterMode] = useState([]);

  const [spottedCountry, setSpottedCountry] = useState<any>([]);
  const [spottedRegion, setSpottedRegion] = useState("");

  const [operatingNearMe, setOperatingNearMe] = useState(false);

  const [dxOnly, setDxOnly] = useState(false);
  const [spottedNearMe, setSpottedNearMe] = useState(false);
  const [potaOnly, setPotaOnly] = useState(false);
  const [wrlOnly, setWrlOnly] = useState(false);

  const [logged, setLogged] = useState(true);

  const sortConfigRef = useRef(sortConfig);
  const filtersRef = useRef(filters);
  const dispatch = useDispatch();

  useEffect(() => {
    sortConfigRef.current = sortConfig;
    filtersRef.current = filters;
    applyChanges();
  }, [sortConfig, filters]);

  const applyChanges = (filters?: SpotFilters[]) => {
    if (sortConfigRef.current?.column && sortConfigRef.current?.order) {
      dispatch(
        fetchSpot(
          filters || filtersRef.current || [],
          undefined,
          sortConfigRef.current
        )
      );
    } else {
      dispatch(fetchSpot(filters || filtersRef.current || []));
    }
  };

  return {
    sortConfig,
    setSortConfig,
    filters,
    setFilters,
    applyChanges,

    filterCountry,
    setFilterCountry,
    filterRegion,
    setFilterRegion,
    filterBand,
    setFilterBand,
    filterMode,
    setFilterMode,
    spottedCountry,
    setSpottedCountry,
    spottedRegion,
    setSpottedRegion,
    operatingNearMe,
    setOperatingNearMe,
    dxOnly,
    setDxOnly,
    spottedNearMe,
    setSpottedNearMe,
    potaOnly,
    setPotaOnly,
    wrlOnly,
    setWrlOnly,
    logged,
    setLogged,
  };
};
