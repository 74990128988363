import Header from "components/Header";
import RouteLoading from "components/Loading/RouteLoading";
import useSubscription from "hooks/subscription.hook";
import React, { useEffect } from "react";
import SubscriptionDetail from "./SubscriptionDetail";
import Pricing from "./Pricing";
import { useSearchParams } from "react-router-dom";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { STRIPE_RESPONSE } from "types/Functions";
import AnalyticsService from "services/analytics.service";
import { USER_PURCHASE } from "constants/AnalyticsEvents";
import useProfile from "hooks/profile.hook";

const Setting = () => {
  const { subscriptionStatus, isSubscriptionLoading } = useSubscription();
  const { userProfile } = useProfile();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get(STRIPE_RESPONSE.SUCCESS) === "true") {
      if (!subscriptionStatus) {
        showToast({
          message: "Subscription Successful!",
          type: ToastTypes.SUCCESS,
        });
      } else {
        AnalyticsService.logGtmEvent({
          event: USER_PURCHASE,
          currency: "USD",
          transaction_id: "",
          value: subscriptionStatus.amount,
          coupon: "",
          email: userProfile?.email || "",
          first_name: userProfile?.firstName || "",
          last_name: userProfile?.lastName || "",
          userId: userProfile?.uid || "",
          country: userProfile?.country || "",
          items: [
            {
              item_id: subscriptionStatus.name,
              item_name: subscriptionStatus.name,
              price: subscriptionStatus.amount,
              quantity: 1,
            },
          ],
        });
      }
    } else if (searchParams.get(STRIPE_RESPONSE.SUCCESS) === "false") {
      if (!subscriptionStatus) {
        showToast({
          message: "Checkout process cancelled!",
          type: ToastTypes.WARN,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStatus]);

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Plans" />
      {subscriptionStatus?.status === "active" ? (
        <SubscriptionDetail />
      ) : (
        <Pricing />
      )}

      {isSubscriptionLoading ? (
        <div className="absolute z-10 top-0 w-full h-full">
          <RouteLoading text={"Loading Subscription..."} />
        </div>
      ) : null}
    </main>
  );
};

export default Setting;
