import React, { useRef } from 'react';

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.offsetWidth / 2, // Scroll by half the width for smoother scrolling
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="relative w-full flex items-center">
      {/* Left Arrow */}
      <button
        onClick={scrollLeft}
        className="bg-gray-80 border w-10 h-10 text-white p-2 rounded-full z-10 mr-4 bg-white/20"
      >
        ←
      </button>

      {/* Carousel Content */}
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll scrollbar-hide px-6 space-x-4 snap-x snap-mandatory w-full items-center whitespace-nowrap"
      >
        {children}
      </div>

      {/* Right Arrow */}
      <button
        onClick={scrollRight}
        className="bg-gray-80 border w-10 h-10  text-white p-2 rounded-full z-10 ml-4 bg-white/20"
      >
        →
      </button>
    </div>
  );
};

export default Carousel;
