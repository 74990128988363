import {
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  updatePassword,
} from "firebase/auth";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

function useResetPassword() {
  const handleResetPassword = async (
    currentPassword: string | null,
    newPassword: string
  ) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("No user is currently signed in.");
      }

      // Check if the user signed in with Google or Apple
      const isGoogleUser = user.providerData.some(
        (provider) => provider.providerId === "google.com"
      );
      const isAppleUser = user.providerData.some(
        (provider) => provider.providerId === "apple.com"
      );
      const isPasswordProvider = user.providerData.some(
        (provider) => provider.providerId === "password"
      );

      if (isPasswordProvider && currentPassword) {
        // Reauthenticate with email/password

        const credential = EmailAuthProvider.credential(
          user.email!,
          currentPassword
        );
        await reauthenticateWithCredential(user, credential);

        // Update password
        await updatePassword(user, newPassword);

        showToast({
          message: "Password updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else if (isGoogleUser) {
        // Reauthenticate with Google
        const googleProvider = new GoogleAuthProvider();
        await reauthenticateWithPopup(user, googleProvider);

        // After reauthentication, update the password
        await updatePassword(user, newPassword);

        showToast({
          message: "Password updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else if (isAppleUser) {
        // Reauthenticate with Apple (using OAuthProvider)
        const appleProvider = new OAuthProvider("apple.com");
        await reauthenticateWithPopup(user, appleProvider);

        // After reauthentication, update the password
        await updatePassword(user, newPassword);

        showToast({
          message: "Password updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else {
        if (!currentPassword) {
          throw new Error(
            "Current password is required for email/password users."
          );
        }
      }
    } catch (error: any) {
      // Handle errors and show appropriate messages
      if (error.code === "auth/wrong-password") {
        showToast({
          message: "Incorrect current password. Please try again.",
          type: ToastTypes.ERROR,
        });
      } else if (error.code === "auth/weak-password") {
        showToast({
          message: "Password is too weak. Please choose a stronger password.",
          type: ToastTypes.ERROR,
        });
      } else if (error.code === "auth/popup-closed-by-user") {
        showToast({
          message: "Authentication popup closed. Please try again.",
          type: ToastTypes.ERROR,
        });
      } else {
        showToast({
          message: "Error updating password. Please try again later.",
          type: ToastTypes.ERROR,
        });
      }

      console.error("Error during password reset:", error);
    }
  };

  return { handleResetPassword };
}
export default useResetPassword;
