import React, { useEffect, useState } from "react";

interface ButtonProps {
  icon?: string;
  label: string;
  active: boolean;
  tabIndex?: number;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  icon,
  label,
  active,
  onClick,
  tabIndex,
  disabled
}) => {
  return (
    <div
      tabIndex={tabIndex}
      className={`relative flex flex-row justify-center items-center text-sm gap-2 w-full md:w-auto ${
        active ? "text-slate-900" : "text-white"
      } p-1 px-4 cursor-pointer transition-all duration-300 ease-in-out ${
        disabled ? "pointer-events-none opacity-50" : ""
      }`}
      onClick={onClick}
    >
      {icon ? <img className={`h-[16px] ${active ? "brightness-0": ""}`} src={icon} alt={label} /> : null}
      <div>{label}</div>
    </div>
  );
};

type Action = { id: number | string; icon?: string; label: string; tabIndex?: number };

interface SelectableButtonsGroupProps {
  actions: Array<Action>;
  onButtonChange: (action: Action | null) => void;
  initialSelected?: number | string;
  toggle?: boolean;
  disabled?: boolean;
}

export const SliderButtonGroup: React.FC<SelectableButtonsGroupProps> = ({
  actions,
  onButtonChange,
  initialSelected = -1,
  toggle,
  disabled,
}) => {
  const [selectedItem, setSelectedItem] = useState<number | string>(initialSelected);

  useEffect(() => {
    if (initialSelected) {
      setSelectedItem(initialSelected);
    }
  }, [initialSelected]);

  const handleItemClick = (action: Action) => {
    if (selectedItem === action.id && toggle) {
      setSelectedItem(-1);
      onButtonChange(null);
    } else {
      setSelectedItem(action.id);
      onButtonChange(action);
    }
  };

  return (
    <div className="relative flex w-[100%] flex-nowrap mt-6 md:mt-0 bg-[#010A41] border border-[#141e6e] py-1.5 rounded-full overflow-hidden">
      {/* Sliding Background */}
      <div
        className={`absolute top-1 h-[80%] bg-gradient-to-br from-indigo-500 to-cyan-300 rounded-full transition-all duration-300 ease-in-out`}
        style={{
          width: `${100 / actions.length}%`,
          transform: `translateX(${(actions.findIndex((action) => action.id === selectedItem)) * 100}%)`,
        }}
      />
      {/* Buttons */}
      {actions.map((action) => (
        <div
          key={action.id}
          className={`relative text-center`}
          style={{ width: `${100 / actions.length}%` }}
        >
          <ButtonComponent
            icon={action.icon}
            label={action.label}
            active={selectedItem === action.id}
            onClick={() => handleItemClick(action)}
            tabIndex={action.tabIndex}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  );
};
