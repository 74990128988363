import ActivityIndicator from "components/Loading/ActivityIndicator";
import TableCustom from "components/Table/Table";
import TabNavGlow from "components/TabNavGlow";
import { TAGS } from "constants/Config";
import { useEffect, useState } from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import { LogBookListProps } from "types/State";
import ImportList from "./ActivityLog/ActivityList";
import DropdownButton from "./DropdownButton";
import Icons from "assets/Icons";
import { useParams } from "react-router-dom";
import QSOManager from "./QSOManager";

function LogBooksList({ logBooksList, isLoading }: LogBookListProps) {
  const { tab } = useParams<{ tab: string }>();
  const [selectedIndex, setSelectedIndex] = useState<string | null>("Logbooks");
  
  const Logbooks = () => {
    return (
      <>
        <div className="mt-12 mb-10 px-5 md:px-7 h-fit">
          {isLoading ? (
            <ActivityIndicator size={36} />
          ) : (
            <TableCustom list={logBooksList} />
          )}
        </div>
      </>
    );
  };

  const getLayout = () => {
    switch (selectedIndex) {
      case "Logbooks":
        return <Logbooks />;
      case "QSO Manager":
        return (
          <QSOManager />
        );
      case "Activity Log":
        return <ImportList />;
      default:
        return <Logbooks />;
    }
  };

  const options = [
    {
      label: "New Generic Logbook",
      icon: <img src={Icons.AddLogBook} alt="bg" className={"h-5 w-5"} />,
      action: () => globalNavigate("/log-contacts/add-logbook"),
    },
    {
      label: "New POTA Log",
      icon: <img src={Icons.NewPota} alt="bg" className={"h-5 w-5"} />,
      action: () =>
        globalNavigate(`/log-contacts/add-logbook?tag=${TAGS.POTA}`),
    },
    {
      label: "Import .ADI File",
      icon: <img src={Icons.ImportFile} alt="bg" className={"h-5 w-5"} />,
      action: () => globalNavigate("/log-contacts/import-contact"),
    },
  ];

  useEffect(() => {
    if (tab === "qso-manager") {
      setSelectedIndex("QSO Manager");
    } else if (tab === "activity-log") {
      setSelectedIndex("Activity Log");
    } else {
      setSelectedIndex("Logbooks");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <main className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper">
      <div className="h-full w-full">
        <div className="glassmorphism w-full h-fit pb-6">
          <div className="h-fit">
            <div className="w-full flex flex-col md:flex-row md:justify-between pe-8">
              <TabNavGlow
                selectedIndex={selectedIndex ? selectedIndex : ""}
                setSelectedIndex={(index) => {
                  if (index === "Logbooks") {
                    globalNavigate(`/log-contacts`);
                  } else if (index === "QSO Manager") {
                    globalNavigate(`/log-contacts/qso-manager`);
                  } else if (index === "Activity Log") {
                    globalNavigate(`/log-contacts/activity-log`);
                  }
                }}
                tabs={["Logbooks", "QSO Manager", "Activity Log"]}
              />

              {selectedIndex === "Logbooks" ? (
                <div className="flex w-[340px] mt-4 items-center justify-end">
                  <DropdownButton
                    buttonLabel="Create New Logbook"
                    buttonIcon={
                      <img
                        src={Icons.NewLogbook}
                        alt="bg"
                        className={"h-5 w-5"}
                      />
                    }
                    dropdownOptions={options}
                    onClick={(option) => option.action()}
                  />

                  <div
                    className="cursor-pointer items-center ml-3"
                    onClick={() =>
                      globalNavigate("/log-contacts/logbook-default-settings")
                    }
                  >
                    <img src={Icons.SettingsIcon} alt="settings button" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* Divider */}
          <div className="h-[0.5px] w-full bg-[#1b1d63] mb-1" />
          {getLayout()}
        </div>
      </div>
    </main>
  );
}

export default LogBooksList;
