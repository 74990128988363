import PlanetLoading from "./planetLoading.json";
import ActivityIndicator from "./activityIndicator.json";
import Sync from "./sync.json";
import Loading from "./loading.json";
import CheckMark from "./checkmark.json";
import WorldLoading from "./worldLoading.json";
import AtomLoading from "./atomLoading.json";

const LottieFiles = {
  PlanetLoading: PlanetLoading,
  ActivityIndicator: ActivityIndicator,
  Sync,
  Loading,
  CheckMark,
  WorldLoading,
  AtomLoading
};

export default LottieFiles;
