import Icons from "assets/Icons";
import moment from "moment";
import { IoEyeOutline } from "react-icons/io5";
import { ImportsTableColProps } from "types/Component";

const IMPORT_STATUS_COLORS: any = {
  Complete: "#00FF87",
  Failed: "#FF3737",
  "In Progress": "#17F9DA",
  "Complete With Errors": "#FFF500"
}

export enum UserActivityTypeEnum {
  BULK_EDIT = "bulkEdit",
  BULK_DELETE = "bulkDelete",
  MERGE_LOGBOOK = "mergeLogbook",
  IMPORT_ADI_FILE = "importAdiFile",
  SINGLE_EDIT = "singleEdit",  // For editing a single contact
  SINGLE_DELETE = "singleDelete", // For deleting a single contact
  EXPORT_LOGBOOK = "exportLogbook", // For exporting a logbook
  EXPORT_CONTACTS = "exportContacts", // For exporting contacts
  EXPORT_ALL_CONTACTS = "exportAllContacts", // For exporting all contacts
}

export const UserActivityTypeDisplayName: any = {
  [UserActivityTypeEnum.BULK_EDIT]: "Bulk Edit",
  [UserActivityTypeEnum.BULK_DELETE]: "Bulk Delete",
  [UserActivityTypeEnum.MERGE_LOGBOOK]: "Merge Logbook",
  [UserActivityTypeEnum.IMPORT_ADI_FILE]: "Import ADI",
  [UserActivityTypeEnum.SINGLE_EDIT]: "Single Edit",
  [UserActivityTypeEnum.SINGLE_DELETE]: "Single Delete",
  [UserActivityTypeEnum.EXPORT_LOGBOOK]: "Export Logbook",
  [UserActivityTypeEnum.EXPORT_CONTACTS]: "Export Contacts",
  [UserActivityTypeEnum.EXPORT_ALL_CONTACTS]: "Full Export",
};

const UserActivityTypeIcon: any = {
  [UserActivityTypeEnum.BULK_EDIT]: Icons.edit,
  [UserActivityTypeEnum.MERGE_LOGBOOK]: Icons.edit,
  [UserActivityTypeEnum.IMPORT_ADI_FILE]: Icons.Import,
  [UserActivityTypeEnum.EXPORT_LOGBOOK]: Icons.Export,
  [UserActivityTypeEnum.EXPORT_CONTACTS]: Icons.Export,
  [UserActivityTypeEnum.EXPORT_ALL_CONTACTS]: Icons.Export,
  [UserActivityTypeEnum.BULK_DELETE]: Icons.CrossIcon,
}

function TableCol({ className, item, onClick }: ImportsTableColProps) {
  const statusColor = item?.status ? IMPORT_STATUS_COLORS?.[item?.status]: "#ffffff";
  const dataTime = moment.unix(item.timestamp.seconds).format("hh:mm:ss DD/MM/YYYY");
  
  const getDesctiption = () => {
    let description = `${UserActivityTypeDisplayName[item?.operationType]} `;

    if (item?.operationType === UserActivityTypeEnum.IMPORT_ADI_FILE) {
      description += `into ${item?.logbookName}`;
    } else if (item?.operationType === UserActivityTypeEnum.EXPORT_LOGBOOK) {
      description += `from ${item?.logbookName}`;
    } else if (item?.operationType === UserActivityTypeEnum.BULK_EDIT 
      || item?.operationType === UserActivityTypeEnum.BULK_DELETE 
      || item?.operationType === UserActivityTypeEnum.EXPORT_CONTACTS) {
      description += ` based on criteria`;
    } else if (item?.operationType === UserActivityTypeEnum.EXPORT_ALL_CONTACTS) {
      description += ``;
    } else {
      description += `${item?.sourceLogbookName} to ${item?.destinationLogbookName}`;
    }

    return description;
  }
  

  return (
    <tr className={`${className ? className : ""}`} onClick={onClick}>
      <td className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal">
        {dataTime}
      </td>
      <td className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal">
        {item?.id}
      </td>
      <td className="pl-4 text-[#17F9DA] text-xs font-[Barlow] font-normal py-3 flex items-center gap-2">
        <img src={UserActivityTypeIcon[item?.operationType]} alt="icon" className="w-5 h-5" />
        { UserActivityTypeDisplayName[item?.operationType] }
      </td>
      <td className="pl-4 text-white text-xs font-[Barlow] font-normal py-3 max-w-[230px]">
        { getDesctiption() }
      </td>
      <td className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal">
        {item?.totalQsos || '--'}
      </td>
      <td className={`pl-4 py-3 text-xs font-[Barlow] font-normal text-[${statusColor}]`}>
        {item?.status}
      </td>
      <td className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal">
        <IoEyeOutline color="#17F9DA" size={20} onClick={onClick} />
      </td>
    </tr>
  );
}

export default TableCol;
