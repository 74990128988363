import { increment } from "firebase/firestore";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { showToast } from "helpers/Toast";
import { all, call, put, select, take } from "redux-saga/effects";
import ProfileService from "services/profile.service";
import SpottingService from "services/spotting.service";
import { SPOT_LOADING } from "store/Actions/loading.action";
import { ADD_SPOT_MODAL } from "store/Actions/logBook.actions";
import {
  CREATE_SPOT,
  EDIT_SPOT,
  FETCH_SPOT,
  SET_SPOT,
} from "store/Actions/spotting.action";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { ToastTypes } from "types/Component";
import { SpotModel, UserProfile } from "types/Models";

//Workers

export function* workerCreateSpot(payload: SpotModel) {
  try {
    yield put({
      type: SPOT_LOADING,
      payload: true,
    });
    const spotCreated: boolean = yield call(() =>
      SpottingService.createSpot(payload)
    );
    if (spotCreated) {
      ProfileService.editUserProfileData({
        spotsCreated: increment(1),
      });
      showToast({
        message: "Spot created successfully",
        type: ToastTypes.SUCCESS,
      });
      yield all([
        put({
          type: FETCH_SPOT,
          payload: [],
        }),
        put({
          type: ADD_SPOT_MODAL,
          payload: null,
        }),
      ]);
    }
  } catch (e: any) {
    console.error("workerCreateSpot ERROR SAGA: ", e);
    authErrorHandler(e);
  }
  yield put({
    type: SPOT_LOADING,
    payload: false,
  });
}

export function* workerEditSpot(payload: SpotModel) {
  try {
    yield put({
      type: SPOT_LOADING,
      payload: true,
    });
    const spotEdited: boolean = yield call(() =>
      SpottingService.editSpot(payload)
    );
    if (spotEdited) {
      showToast({
        message: "Spot updated successfully",
        type: ToastTypes.SUCCESS,
      });
      yield all([
        put({
          type: FETCH_SPOT,
          payload: [],
        }),
        put({
          type: ADD_SPOT_MODAL,
          payload: null,
        }),
      ]);
    }
  } catch (e: any) {
    console.error("workerEditSpot ERROR SAGA: ", e);
    authErrorHandler(e);
  }
  yield put({
    type: SPOT_LOADING,
    payload: false,
  });
}

export function* workerFetchSpot(payload: any) {
  try {
    if (payload.loading) {
      yield put({
        type: SPOT_LOADING,
        payload: true,
      });
    }
    let userProfile: UserProfile = yield select(getUserProfileSelector);
    const spots: SpotModel[] = yield call(() =>
      SpottingService.fetchSpot({
        userCoordinates: userProfile?.coordinates,
        filters: payload.filters,
        userCountry: userProfile.country || "",
        myCallSign: userProfile.callSign,
        userId: userProfile.uid || "",
        sort: payload.sort,
      })
    );
    yield put({
      type: SET_SPOT,
      payload: spots,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: SPOT_LOADING,
    payload: false,
  });
}

//Watchers
export function* watchCreateSpot() {
  while (true) {
    const { payload } = yield take(CREATE_SPOT);
    yield call(workerCreateSpot, payload);
  }
}
export function* watchFetchSpot() {
  while (true) {
    const { payload } = yield take(FETCH_SPOT);
    yield call(workerFetchSpot, payload);
  }
}
export function* watchEditSpot() {
  while (true) {
    const { payload } = yield take(EDIT_SPOT);
    yield call(workerEditSpot, payload);
  }
}
