import Icons from "assets/Icons";
import Images from "assets/Images";
import { findCountryCode, getStateLongName } from "helpers/Utils";

import React from "react";
import ReactCountryFlag from "react-country-flag";
import { TodayLeaderItemProps } from "types/Component";
import { stateFlags } from "constants/Config";

function TodayLeaderItem({ data, onPress }: TodayLeaderItemProps) {
  const getImageLayout = () => {
    if (data.distance) {
      return (
        <div className="w-26 h-26 -mt-2 flex flex-col">
          {findCountryCode({ name: data?.fromCountry || "" }) ? (
            <ReactCountryFlag
              countryCode={
                findCountryCode({ name: data.fromCountry || "" }).alpha2
              }
              svg
              style={{
                width: "2.5rem",
                height: "2.5rem",
              }}
              title={data?.fromCountry || ""}
            />
          ) : null}
          <p className="text-white font-[Play] text-sm text-center">TO</p>
          {findCountryCode({ name: data?.toCountry || "" }) ? (
            <ReactCountryFlag
              countryCode={
                findCountryCode({ name: data.toCountry || "" }).alpha2
              }
              svg
              style={{
                width: "2.5rem",
                height: "2.5rem",
              }}
              title={data?.toCountry || ""}
            />
          ) : null}
        </div>
      );
    } else if (data.image) {
      return (
        <div className="w-26 h-26">
          <img src={data.image} alt="bg" className={"w-24 h-24 -mt-3"} />
        </div>
      );
    } else if (data.country) {
      return (
        <div className="w-26 h-26 -mt-2">
          {findCountryCode({ name: data?.country || "" }) ? (
            <ReactCountryFlag
              countryCode={findCountryCode({ name: data.country || "" }).alpha2}
              svg
              style={{
                width: "6rem",
                height: "6rem",
              }}
              title={data?.country || ""}
            />
          ) : null}
        </div>
      );
    } else if (data.state) {
      const longStateName = getStateLongName(data.state);
      return (
        <div className="w-26 h-26">
          {stateFlags[longStateName] ? (
            <img
              src={stateFlags[longStateName]}
              alt="bg"
              className={"w-24 h-24 -mt-3"}
            />
          ) : (
            <img src={Images.State} alt="bg" className={"w-24 h-24 -mt-3"} />
          )}
        </div>
      );
    } else if (data.band) {
      return (
        <div className="w-26 h-26 items-start justify-center">
          <h1 className="font-[Play] text-white text-[3.5rem] font-bold">
            {data.band}
          </h1>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className="relative mt-6 flex flex-col items-center todayLeaderImgWrapper cursor-pointer mb-10"
        onClick={() => {
          onPress(data);
        }}
      >
        <div className="font-[Play] font-bold text-white absolute -m-9">
          {data.title}{" "}
          <span className="text-[#17F9DA]"> {data.titleColor}</span>
        </div>

        <div className="absolute w-full flex justify-center h-[104%] top-[-1%]">
          <img
            // className="w-full object-fill md:w-[196.8px] h-[295.2px] lg:w-[246px] lg:h-[369px]"
            className={`object-fill w-[95%] h-full`}
            src={Icons.LeaderBg}
            alt="bg"
          />
        </div>
        <div
          className={`bg-[#161638] w-[91%] h-full  rounded-[10px] flex flex-col items-center justify-between pt-2 relative mt-3`}
        >
          <div className="w-full flex flex-col items-center">
            {getImageLayout()}
          </div>
          <div className="w-full flex flex-col items-center mb-2">
            <p className="text-white font-[Play] font-bold text-[14px] mb-1 text-center">
              {data.firstText}
            </p>
            <div className="bg-[#292948] w-[95%]">
              <p className="font-[Play] text-[18px] text-[#17F9DA] text-center">
                {data.secondText || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TodayLeaderItem;
