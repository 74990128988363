import Icons from "assets/Icons";
import HeaderNavBar from "components/Header/HeaderNavBar";
import ContactMap from "components/Map/ContactMap";
import useNotification from "hooks/notification.hook";
import { useRef, useState } from "react";

import { ShareMapModalProps, ToastTypes } from "types/Component";

import Images from "assets/Images";
import useConfig from "hooks/config.hook";

import useUpload from "hooks/upload.hook";
import RouteLoading from "components/Loading/RouteLoading";
import { QSOMapShare } from "types/Models";
import { showToast } from "helpers/Toast";
import { useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { serverTimestamp } from "firebase/firestore";
import {
  FaCopy,
  FaDownload,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa6";

const ShareMapModal = ({ className }: ShareMapModalProps) => {
  const { handleContactMapModalStatus } = useNotification();
  const userProfile = useSelector(getUserProfileSelector);
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [shareId, setShareId] = useState("");
  const [isCapturing, setIsCapturing] = useState(false);

  // const [screenShotImage, setScreenShotImage] = useState<any>("");
  const { screenShotImage } = useConfig();
  const {
    captureSnapshot,
    isLoadingImg,
    setScreenshotImage,
    captureInProgress,
    saveQsoMapShareData,
    shareToSocials,
    onDownloadClick,
  } = useUpload();

  const capture = async () => {
    try {
      const firebaseURl = await captureSnapshot(divRef, false, setLoading);
      if (firebaseURl) {
        setImageUrl(firebaseURl);
        const data: QSOMapShare = {
          imageUrl: firebaseURl,
          uid: userProfile?.uid || "",
          callSign: userProfile?.callSign || "",
          timestamp: serverTimestamp(),
        };
        const docId = await saveQsoMapShareData(data);
        if (docId) {
          setShareId(docId);
        }
      }
    } catch (e) {
      showToast({
        message: "Sorry, something went wrong. Please try again.",
        type: ToastTypes.WARN,
      });
    }

    setLoading(false);
  };

  return (
    <section
      className="absolute w-screen h-screen top-0 left-0 z-[1000]"
      ref={divRef}
    >
      <img
        src={Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="h-full w-full flex flex-col items-center glassmorphism py-12 px-[5%]">
        <div className="flex justify-center items-center z-10">
          <img
            src={Icons.MainLogo}
            className="w-[100px] h-[100px]"
            alt="logo"
          />
          <p className="text-white font-[Play] text-xl font-bold">
            www.WorldRadioLeague.com
          </p>
        </div>
        {shareId && imageUrl && !loading ? (
          <div className="mb-2 flex justify-center">
            <div className="flex items-center">
              <p className="font-[Play] text-white text-lg font-bold">
                Share Link Ready:{" "}
              </p>
              <input
                disabled
                value={`${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`}
                className="ml-2 bg-[#151540] text-white font-[Play] text-base p-1 rounded-md  border border-[#16F8DA]"
              />
            </div>
            <div className="flex items-center px-4 rounded-[10px] w-full md:w-[440px] z-10 mt-3 mb-3 justify-center">
              <div
                className="w-[25px] h-[25px] rounded-[4px] bg-[#1877F2] flex items-center justify-center mr-2 cursor-pointer"
                onClick={() => {
                  shareToSocials(
                    "facebook",
                    `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                    "Check out this QSO map I made on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <FaFacebook size={16} color="#fff" />
              </div>
              <div
                className="w-[25px] h-[25px] rounded-[4px] bg-[#1DA1F2] flex items-center justify-center mr-2 cursor-pointer"
                onClick={() => {
                  shareToSocials(
                    "twitter",
                    `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                    "Check out this QSO map I made on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <FaTwitter size={16} color="#fff" />
              </div>
              <div
                className="w-[25px] h-[25px] rounded-[4px] bg-[#2867B2] flex items-center justify-center mr-2 cursor-pointer"
                onClick={() => {
                  shareToSocials(
                    "linkedin",
                    `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                    "Check out this QSO map I made on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <FaLinkedin size={16} color="#fff" />
              </div>
              <div
                className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  shareToSocials(
                    "linkcopy",
                    `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                    "Check out this QSO map I made on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">Copy Link</span>
                <FaCopy size={16} color="#fff" />
              </div>
              <div
                className="w-[120px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  onDownloadClick(
                    `WorldRadioLeague_QSOMap_${userProfile?.callSign || ""}`
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">
                  Download Image
                </span>
                <FaDownload size={16} color="#fff" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="relative w-full h-full flex justify-center items-center">
          <div className={loading ? "hidden" : "block w-full h-full"}>
            <ContactMap
              captureSnapshot={async () => {
                setIsCapturing(true)
                await capture()
                setIsCapturing(false)
              }}
              className="w-full h-full flex justify-center items-center"
              containerClassName="h-full w-full relative"
              isLoadingImg={isLoadingImg}
              screenShotImage={screenShotImage}
              captureInProgress={captureInProgress}
              showShare={true}
              mapControls={{
                streetViewControl: false,
                rotateControl: false,
                mapTypeControl: false,
                fullscreenControl: !isCapturing,
                zoomControl: !isCapturing,
                scrollwheel: true,
              }}
            />
          </div>
          {loading && (
            <RouteLoading text="Generating your QSO map image..." />
          )}
        </div>

        {captureInProgress ? null : (
          <div
            className="absolute top-4 right-5 cursor-pointer"
            onClick={() => {
              setScreenshotImage("");
              handleContactMapModalStatus(false);
            }}
          >
            <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
          </div>
        )}
      </div>
    </section>
  );
};

export default ShareMapModal;
