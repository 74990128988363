import { Templates } from "constants/Config";
import { showToast } from "helpers/Toast";
import useConfig from "hooks/config.hook";
import useLogBook from "hooks/logBook.hook";
import { useUserActivity } from "hooks/useUserActivity.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import {
  TableDropDownItemsProps,
  TableDropDownProps,
  ToastTypes,
} from "types/Component";
import { UserActivityTypeDisplayName } from "../ActivityLogTable/TableCol";

function TableDropDown({ listItems, itemData, onClick }: TableDropDownProps) {
  const { handlePrimaryModal, primaryModalData } = useConfig();
  const { deleteLogBook, getLogbookContactForAdifExport, showMergeLogbookModal } = useLogBook();
  const { userActivityType, userActivityStatus } = useUserActivity();
  const listOptions = [...listItems];

  const preparePotaExportOptions = () => {
    if (itemData?.logbookStyle !== Templates.POTA) return [];

    const options: TableDropDownItemsProps[] = [];
    const parks = Object.keys(itemData?.myParks || {});

    parks.forEach((park) => {
      options.push({
        text: `Export ${park} ADIF`,
        meta: park,
      });
    });

    return options;
  };

  let insertPosition = listOptions.length - 1;
  listOptions.splice(insertPosition, 0, ...preparePotaExportOptions());

  return (
    <div className="absolute bg-white shadow-md right-0 ">
      {listOptions.map((item, index) => {
        return (
          <div
            className={`${
              item?.color ? item?.color : "text-white"
            } font-[Barlow] flex font-normal text-xs px-3 py-1.5 cursor-pointer bg-[#3C3C58] hover:bg-[#3C3C75]`}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
              if (item?.text === "Delete") {
                if(userActivityStatus === "In Progress"){
                  showToast({
                    message: `${UserActivityTypeDisplayName?.[userActivityType]} is in progress. Please complete the activity first.`,
                    type: ToastTypes.WARN,
                  });
                  return;
                }
                if (primaryModalData) {
                  handlePrimaryModal(null);
                } else {
                  handlePrimaryModal({
                    title:
                      "Are you sure you want to delete this logbook and all the contacts associated with it? This cannot be undone",
                    type: "Delete Logbook",
                    subType: itemData?.name,
                    onCancelPressed: () => handlePrimaryModal(null),
                    onSubmitPressed: () => {
                      deleteLogBook(itemData?.id ? itemData.id : "");
                      handlePrimaryModal(null);
                    },
                    submitText: "Delete",
                    cancelText: "Cancel",
                  });
                }
              } else if (item?.text === "Export ADIF") {
                if (itemData?.exportInProgress) {
                  showToast({
                    message: "Export in progress, please wait and try again!",
                    type: ToastTypes.WARN,
                  });
                } else {

                  getLogbookContactForAdifExport(itemData?.id || "");
                }
              } else if (item?.text === `Export ${item?.meta} ADIF`) {
                if (itemData?.exportInProgress) {
                  showToast({
                    message: "Export in progress, please wait and try again!",
                    type: ToastTypes.WARN,
                  });
                } else {
                  getLogbookContactForAdifExport(itemData?.id || "", {
                    parkReference: item?.meta,
                  });
                }
              } else if (item?.text === "View Import Progress") {
                globalNavigate("/log-contacts/import-summary");
              } else if (item?.link) {
                globalNavigate(item.link, {
                  state: itemData,
                });
              } else if (item?.text === "Merge to Other Logbook") {
                showMergeLogbookModal(itemData);
              }
            }}
          >
            {item.text}
          </div>
        );
      })}
    </div>
  );
}

export default TableDropDown;
