import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { Form, Formik } from "formik";
import { LogbookSettingsForm } from "types/Models";
import { BasicSettings } from "./BasicSettings";
import { LocationSettings } from "./LocationSettings";
import { EquipmentSettings } from "./EquipmentSettings";
import { CallSignSettings } from "./CallSignSettings";
import { LogBookSettingsSaveAction } from "./LogBookSettingsAction";
import useDefaultLogbooksSettings from "hooks/useDefaultLogbooksSettings";


const initialValues: LogbookSettingsForm = {
  logbookStyle: "",
  defaultLocation: "",
  radio: "",
  antenna: "",
  defaultCallSign: "",
  callSignType: "",
  defaultPower: "",
};

const DefaultSettings = () => {
  const {
    updateDefaultLogbookSettings,
    locationForm,
    setLocationForm,
    locationLoading,
    findLocation,
    setLocationOption,
    locationOption,
  } = useDefaultLogbooksSettings();

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          updateDefaultLogbookSettings(values);
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <div className="flex flex-col w-full gap-6 glassmorphism px-6 py-4 pb-16">
            {/* Basic Settings */}
            <BasicSettings />

            {/* Location Settings */}
            <LocationSettings
              {...{
                locationForm,
                setLocationForm,
                locationLoading,
                findLocation,
                setLocationOption,
                locationOption,
              }}
            />

            {/* Equipment Settings */}
            <EquipmentSettings />

            {/* CallSign Settings */}
            <CallSignSettings />

            {/* Save Button */}
            <LogBookSettingsSaveAction />
          </div>
        </Form>
      </Formik>
    </main>
  );
};

export default DefaultSettings;
