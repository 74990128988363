import { showToast } from "helpers/Toast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAdifFIle } from "store/Actions/logBook.actions";
import { getIsAdiUploadLoadingSelector } from "store/Reducers/loading.reducer";
import { getLogBookListSelector } from "store/Reducers/logBook.reducer";
import { ToastTypes } from "types/Component";
import { LogBookModel, SavedLocation, UserProfile } from "types/Models";
import useConfig from "./config.hook";
import { memberShipQuotas } from "constants/Config";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { LocationSource } from "components/DefaultLocationSettings";
import { useUserActivity } from "./useUserActivity.hook";
import { UserActivityTypeDisplayName } from "components/Table/ActivityLogTable/TableCol";

const useAdifImport = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<any>();
  const [logBookName, setLogBookName] = useState<string>("");
  const logBooksList: LogBookModel[] = useSelector(getLogBookListSelector);
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);

  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [grid, setGrid] = useState<string>("");
  const [lat, setLat] = useState<string>("");
  const [long, setLong] = useState<string>("");
  const [logbookAdiLocation, setLogbookAdiLocation] = useState<any>(null);

  const [isNewLogbook, setIsNewLogbook] = useState(true);
  const [selectedLogbookId, setSelectedLogbookId] = useState<string>("");

  const { getMemberStatusText } = useConfig();

  const FREE_FILE_SIZE = 10000000;

  const isAdiUploadLoading = useSelector(getIsAdiUploadLoadingSelector);

  const { userActivityStatus, userActivityType} = useUserActivity();

  const getUserMemberShipQuota = () => {
    const memberStatus = getMemberStatusText(
      userProfile?.membershipStatus || "FREE"
    );
    const firstAdiImport = logBooksList.filter((data) => data.adiImported);
    if (firstAdiImport.length === 0) {
      //ensure that even if its the users first time uploading we need to prioritize the user membership quota over the free size limit
      if (memberStatus === "PREMIUM" || memberStatus === "VIP") {
        const memberQuota = memberShipQuotas[memberStatus];
        return memberQuota;
      } else {
        return FREE_FILE_SIZE;
      }
    } else {
      const memberQuota = memberShipQuotas[memberStatus];
      return memberQuota;
    }
  };

  const submitForm = async () => {

    if (userActivityStatus === "In Progress") {
      showToast({
        message: `${UserActivityTypeDisplayName?.[userActivityType]} is in progress. Please complete the activity first.`,
        type: ToastTypes.WARN,
      });
      return;
    }

    const memberStatus = getMemberStatusText(
      userProfile?.membershipStatus || "FREE"
    );
    const fileSizeQuota = getUserMemberShipQuota();
    if (file.size > fileSizeQuota) {
      if (memberStatus === "VIP") {
        showToast({
          message: `File size too big, the size limit for a single file is ${
            fileSizeQuota / 1000000
          } MB.`,
          type: ToastTypes.WARN,
        });
      } else {
        showToast({
          message: `File size too big, with your current membership you can only upload ${
            fileSizeQuota / 1000000
          } MB file. Please upgrade to upload more!`,
          type: ToastTypes.WARN,
        });
      }
      return;
    } else {
      if (logBookName === "" && isNewLogbook) {
        showToast({
          message: "Please enter logbook name.",
          type: ToastTypes.WARN,
        });
        return;
      } else {
        let defaultLocation = null;
        if (logbookAdiLocation && logbookAdiLocation?.id) {
          defaultLocation = logbookAdiLocation;
        } else if (logbookAdiLocation) {
          defaultLocation = {
            isCustom: true,
            country: logbookAdiLocation?.locationCountry || "",
            state: logbookAdiLocation?.locationState,
            qth: logbookAdiLocation?.locationQTH || "",
            lat: parseFloat(logbookAdiLocation?.locationLat),
            lng: parseFloat(logbookAdiLocation?.locationLong),
            gridsquare: logbookAdiLocation?.locationGrid,
            id: "",
            locationType: "",
            default: false,
            name: "",
            uid: "",
            locationSource: LocationSource.NONE,
          };
        }

        let selectedLogbookName = "";
        if (selectedLogbookId){
          const selectedLogbook = logBooksList.find((logbook) => logbook.id === selectedLogbookId);
          if (selectedLogbook){
            selectedLogbookName = selectedLogbook.name as string;
          }
        }

        dispatch(
          handleAdifFIle({
            file,
            logBookName: isNewLogbook ? logBookName : selectedLogbookName,
            defaultLocation: defaultLocation,
            isNewLogbook, 
            selectedLogbookId
          })
        );
      }
    }
  };

  return {
    file,
    setFile,
    submitForm,
    setLogBookName,
    logBookName,
    isAdiUploadLoading,
    country,
    setCountry,
    state,
    setState,
    lat,
    setLat,
    long,
    setLong,
    grid,
    setGrid,
    logbookAdiLocation,
    setLogbookAdiLocation,
    isNewLogbook,
    setIsNewLogbook,
    selectedLogbookId,
    setSelectedLogbookId,
  };
};

export default useAdifImport;
