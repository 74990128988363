import Icons from "assets/Icons";
import { LogBookQuickSettingItem } from "./LogBookQuickSettingItem";
import { useEffect, useState } from "react";
import {
  AntennaModal,
  CallSignModal,
  LocationModal,
  PotaModal,
  RadioModal,
} from "./Modals";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { LogBookStyleModal } from "./Modals/LogBookStyleModal";
import { getCurrentDateString, getCurrentUTCtime } from "helpers/Date/Time";
import { Templates } from "constants/Config";
import { LocationSource } from "components/DefaultLocationSettings";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";
import { UserProfile } from "types/Models";

export const LogBookQuickSettings = () => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { selectedLogbook, savedLocations } = useLogbookSetting();
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);

  const showPotaParks =
    selectedLogbook?.logbookStyle === Templates.POTA &&
    selectedLogbook?.myParks &&
    Object.keys(selectedLogbook?.myParks).length > 0;

  const isPota = selectedLogbook?.logbookStyle === Templates.POTA;

  const renderModals = (item: any) => {
    if (!item) return null;

    switch (item.id) {
      case 1:
        return <CallSignModal onClose={() => setSelectedItem(null)} />;
      case 2:
        return <LocationModal onClose={() => setSelectedItem(null)} />;
      case 3:
        return <RadioModal onClose={() => setSelectedItem(null)} />;
      case 4:
        return <AntennaModal onClose={() => setSelectedItem(null)} />;
      case 5:
        return <LogBookStyleModal onClose={() => setSelectedItem(null)} />;
      case 7:
        return <PotaModal onClose={() => setSelectedItem(null)} />;
      default:
        return null;
    }
  };

  const renderCallSignItem = () => {
    return (
      <LogBookQuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 1,
          icon: Icons.AntennaIcon,
          label: selectedLogbook?.defaultCallSign
            ? selectedLogbook?.defaultCallSign
            : userProfile?.callSign || "Call Sign",
        }}
      />
    );
  };

  const renderLocationItem = () => {
    let locationDetails = undefined;

    if (userProfile?.savedLocationId) {
      locationDetails = savedLocations.find(
        (location: any) => location.id === userProfile?.savedLocationId
      );
    }

    const label = selectedLogbook?.defaultLocation?.isCustom
      ? selectedLogbook?.defaultLocation?.gridsquare || "Custom"
      : selectedLogbook?.defaultLocation?.name
      ? selectedLogbook?.defaultLocation?.name
      : locationDetails?.name || "Location";

    return (
      <LogBookQuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 2,
          icon: Icons.PinIcon,
          label,
        }}
      />
    );
  };

  const renderParksItem = () => {
    const label = Object.keys(selectedLogbook?.myParks || {}).join(", ");
    if (!label) return null;

    return (
      <LogBookQuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 7,
          icon: Icons.PinIcon,
          label,
        }}
      />
    );
  };

  const renderLogbookStyleItem = () => {
    return (
      <LogBookQuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 5,
          icon: Icons.SingleTreeIcon,
          label: selectedLogbook?.logbookStyle || "Logbook Style",
        }}
      />
    );
  };

  const renderRadioItem = () => {
    return (
      <LogBookQuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 3,
          icon: Icons.RadioIcon,
          label:
            selectedLogbook?.radio?.name ||
            selectedLogbook?.radio?.model ||
            "Radio",
        }}
      />
    );
  };

  const renderAntennaItem = () => {
    return (
      <LogBookQuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 4,
          icon: Icons.AntennaIcon,
          label:
            selectedLogbook?.antenna?.name ||
            selectedLogbook?.antenna?.brand ||
            "Antenna",
        }}
      />
    );
  };

  return (
    <div className="w-full flex flex-wrap justify-between bg-blue-900 bg-opacity-40 px-2 py-1">
      <div className="flex flex-row flex-wrap gap-x-5">
        {renderCallSignItem()}
        {isPota ? renderParksItem() : null}
        {renderLocationItem()}
        {renderRadioItem()}
        {renderAntennaItem()}
        {isPota ? renderLogbookStyleItem() : null}
      </div>
      <div>
        <LogBookQuickSettingItem
          onItemClick={setSelectedItem}
          data={{
            id: 6,
            icon: Icons.ClockIcon,
            label: `${getCurrentDateString()} | ${getCurrentUTCtime()} z`,
          }}
        />
      </div>
      {renderModals(selectedItem)}
    </div>
  );
};
