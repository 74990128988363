import { LOADING_STATUS_FOR_GETTING_LOCATION } from "store/Actions/loading.action";
import { UserAwards, UserProfile } from "types/Models";
import { UpdateUserProfile } from "types/State";
import { SET_IS_COLLAPSED } from "./config.actions";

export const CHECK_USER_PROFILE = "CHECK_USER_PROFILE";
export const CREATE_USER_PROFILE = "CREATE_USER_PROFILE";
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";
export const STORE_USER_PROFILE = "STORE_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const SAVE_USER_COORDINATES = "SAVE_USER_COORDINATES";
export const FETCH_USER_AWARDS = "FETCH_USER_AWARDS";
export const SET_USER_AWARDS = "SET_USER_AWARDS";
export const SET_FEATURED_USER_AWARD = "SET_FEATURED_USER_AWARD";
export const SET_USER_AVATAR="SET_USER_AVATAR";

export const FETCH_FEATURED_MEMBERS = "FETCH_FEATURED_MEMBERS";
export const SET_FEATURED_MEMBERS = "SET_FEATURED_MEMBERS";

export const CHECK_CALLSIGN_AVAILABILITY = "CHECK_CALLSIGN_AVAILABILITY";
export const IS_CALL_SIGN_AVAILABLE = "IS_CALL_SIGN_AVAILABLE";

export const SEND_WORDPRESS_LOGIN = "SEND_WORDPRESS_LOGIN";
export const SHOW_AWARD_MODAL = "SHOW_AWARD_MODAL";
export const FETCH_AWARDS = "FETCH_AWARDS";
export const SET_AWARDS = "SET_AWARDS";
export const SET_USER_AWARDS_ADMIN = "SET_USER_AWARDS_ADMIN";
export const ADD_USER_AWARDS = "ADD_USER_AWARDS";
export const REMOVE_USER_AWARD = "REMOVE_USER_AWARD";

export const ADMIN_CONTROLS_USER = "ADMIN_CONTROLS_USER";

export const DISABLE_USER = "DISABLE_USER";
export const DELETE_USER = "DELETE_USER";
export const ENABLE_USER = "ENABLE_USER";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

export const SHOW_ACCOUNT_DELETE_MODAL = "SHOW_ACCOUNT_DELETE_MODAL";

export type ProfileActionPayload = {
  type: string;
  payload: string | UserProfile | null;
};

export type ProfileEditActionPayload = {
  type: string;
  payload: UpdateUserProfile | null;
};

export type ActionPayload = {
  type: string;
  payload: any;
};

export type CoordinatesParams = {
  longitude: number;
  latitude: number;
};

export interface EditProfileActionPayload {
  type: string;
  payload: UserProfile;
}

export function checkUserProfile(uid: string): ProfileActionPayload {
  return {
    type: CHECK_USER_PROFILE,
    payload: uid,
  };
}

export function storeLoggedInUser(
  user: UserProfile | null
): ProfileActionPayload {
  return {
    type: STORE_USER_PROFILE,
    payload: user,
  };
}

export function editUserProfile(payload: UserProfile): ProfileActionPayload {
  return {
    type: EDIT_USER_PROFILE,
    payload: payload,
  };
}

export function fetchUserAwards(uid: string): ProfileActionPayload {
  return {
    type: FETCH_USER_AWARDS,
    payload: uid,
  };
}

export const setFeaturedUserAward = (award: UserAwards): ActionPayload => {
  return {
    type: SET_FEATURED_USER_AWARD,
    payload: award,
  };
};

export function updateUserProfile(
  payload: UpdateUserProfile
): ProfileEditActionPayload {
  return {
    type: UPDATE_USER_PROFILE,
    payload: payload,
  };
}

export function saveCoordinates(data: CoordinatesParams): ActionPayload {
  return {
    type: SAVE_USER_COORDINATES,
    payload: data,
  };
}

export function handleCoordinatesLoadingStatus(data: boolean): ActionPayload {
  return {
    type: LOADING_STATUS_FOR_GETTING_LOCATION,
    payload: data,
  };
}

export function sendWordPressLogin(): ActionPayload {
  return {
    type: SEND_WORDPRESS_LOGIN,
    payload: null,
  };
}

export function checkCallSignAvailability(data: string): ActionPayload {
  return {
    type: CHECK_CALLSIGN_AVAILABILITY,
    payload: data,
  };
}

export function setCallSignAvailability(data: boolean): ActionPayload {
  return {
    type: IS_CALL_SIGN_AVAILABLE,
    payload: data,
  };
}

export function fetchFeaturedMembers(): ActionPayload {
  return {
    type: FETCH_FEATURED_MEMBERS,
    payload: null,
  };
}

export function setShowAddAwardModal(value: UserProfile | null): ActionPayload {
  return {
    type: SHOW_AWARD_MODAL,
    payload: value,
  };
}

export function fetchAwards(userId?: string): ActionPayload {
  return {
    type: FETCH_AWARDS,
    payload: userId,
  };
}

export function addUserAwards(awards: any[]): ActionPayload {
  return {
    type: ADD_USER_AWARDS,
    payload: awards,
  };
}

export function removeUserAwards(data: any): ActionPayload {
  return {
    type: REMOVE_USER_AWARD,
    payload: data,
  };
}

export function setUserAdminControl(data: UserProfile | null): ActionPayload {
  return {
    type: ADMIN_CONTROLS_USER,
    payload: data,
  };
}

export function adminDisableUser(uid: string): ActionPayload {
  return {
    type: DISABLE_USER,
    payload: uid,
  };
}
export function adminDeleteUser(uid: string): ActionPayload {
  return {
    type: DELETE_USER,
    payload: uid,
  };
}
export function adminEnableUser(uid: string): ActionPayload {
  return {
    type: ENABLE_USER,
    payload: uid,
  };
}
export function adminUpdateSubscription(data: any): ActionPayload {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: data,
  };
}

export function setUserAvatarAction(data: string): ActionPayload {
  return {
    type: SET_USER_AVATAR,
    payload: data,
  };
}


export function setShowAccountDeleteModal(data: boolean): ActionPayload {
  return {
    type: SHOW_ACCOUNT_DELETE_MODAL,
    payload: data,
  };
}

export function setIsCollapsed(data: boolean): ActionPayload {
  return {
    type: SET_IS_COLLAPSED,
    payload: data,
  };
}