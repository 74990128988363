import React, { useState } from "react";

import TabNavGlow from "components/TabNavGlow";
import Awards from "components/Awards";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { useAdmin } from "hooks/admin.hook";

const AdminOptions = () => {
  const [selectedIndex, setSelectedIndex] = useState<string | null>("POTA");
  const { triggerAutoUpdateParksData } = useAdmin();

  const renderParkOptions = () => (
    <div className="flex justify-center items-start flex-col gap-2 p-10">
      <div>
        Manual Parks Data Update
      </div>
      <BorderButtonSecondary
        className="w-1/3"
        text="Trigger Parks Update"
        onClick={() => triggerAutoUpdateParksData()}
      />

      <div className="text-xs mt-4">
        Warning: This will trigger a manual update of the parks data. Also avoid triggering this multiple times in a short period of time.
      </div>

    </div>
  )

  const getLayout = () => {
    switch (selectedIndex) {
      case "Awards":
        return <Awards />;
      case "POTA":
        return renderParkOptions();
      default:
        return renderParkOptions();
    }
  };

  return (
    <div className="w-full flex justify-center p-10 dashboardWrapper overflow-x-hidden">
      <div className="h-full w-full overflow-x-hidden">
        <div className="glassmorphism w-full h-fit pb-6">
          <div className="px-3 md:px-7 py-5 flex items-center justify-between border-b border-slate-400 border-solid">
            <p className="font-[play] font-bold text-lg text-white">
              More Options
            </p>
          </div>
          <div className="mt-6 mb-5 h-fit overflow-x-scroll">
            <div className="w-full flex justify-start -mt-6">
              <TabNavGlow
                selectedIndex={selectedIndex ? selectedIndex : ""}
                setSelectedIndex={(index) => {
                  setSelectedIndex(index);
                }}
                tabs={["POTA", "Awards"]}
              />
            </div>
          </div>
          {getLayout()}
        </div>
      </div>
    </div>
  );
};

export default AdminOptions;
