import { bulkRemoveContacts, bulkExportContacts } from "firebase-config";
import { showToast } from "helpers/Toast";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { ToastTypes } from "types/Component";

export function useQsoManager() {
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useSelector(getUserProfileSelector);

  async function bulkRemove(criteria: any) {
    try {
      setIsLoading(true);
      const uid = userProfile?.uid;

      const { selectedRows, qsoFilters } = criteria;

      if (!criteria) throw new Error("No criteria provided");
      if (
        selectedRows &&
        !selectedRows.length &&
        !Object.keys(qsoFilters).length &&
        !uid
      )
        throw new Error("No criteria provided");

      const [first] = selectedRows;

      if (first === "all") {
        await bulkRemoveContacts({
          filter: {
            ...qsoFilters,
            uid,
          },
        });
      } else {
        await bulkRemoveContacts({
          filter: {
            selectedContacts: selectedRows,
          },
        });
      }

      showToast({
        type: ToastTypes.SUCCESS,
        message: "Contacts removed successfully",
      });

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error?.code ==="functions/deadline-exceeded" || error?.code === "deadline-exceeded") {}
      else{
        showToast({
          type: ToastTypes.ERROR,
          message: "Error removing contacts",
        });
      }
    }
  }

  async function bulkExport(criteria: any) {
    try {
      setIsLoading(true);
      const uid = userProfile?.uid;
      const callSign = userProfile?.callSign;
      let response = null;
      let firstContact = null;

      const { selectedRows, qsoFilters, exportAll } = criteria;

      if (!exportAll){
        if (!criteria) throw new Error("No criteria provided");
        if (
          selectedRows &&
          !selectedRows.length &&
          !Object.keys(qsoFilters).length &&
          !uid
        ){
          throw new Error("No criteria provided");
        }
  
        const [first] = selectedRows;
        firstContact = first;
      }

      if(exportAll){
        response = await bulkExportContacts({
          filter: {
            uid,
          },
          formate: "adif",
          callSign,
          env: process.env.NODE_ENV,
          uid,
          exportAll: true,
        });
      } else if (firstContact === "all") {
        response = await bulkExportContacts({
          filter: {
            ...qsoFilters,
            uid,
          },
          formate: "adif",
          callSign,
          env: process.env.NODE_ENV,
          uid,
          exportAll: false,
        });
      } else {
        response = await bulkExportContacts({
          filter: {
            selectedContacts: selectedRows,
          },
          formate: "adif",
          callSign,
          env: process.env.NODE_ENV,
          uid,
          exportAll: false,
        });
      }

      if (response && response?.data) {
        const fileUrl: any = response?.data; // Replace with your file URL
        const link = document.createElement("a");
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
      }

      showToast({
        type: ToastTypes.SUCCESS,
        message: "Contacts exported successfully",
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error?.code ==="functions/deadline-exceeded" || error?.code === "deadline-exceeded") {}
      else{
        showToast({
          type: ToastTypes.ERROR,
          message: "Error exporting contacts",
        });
      }
    }
  }

  return { bulkRemove, bulkExport, isLoading };
}
