import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import Icons from 'assets/Icons';
import GradientDynamicButton from 'components/Button/GradientDynamicButton';
import usePota from 'hooks/pota.hook';
import useLogBook from 'hooks/logBook.hook';
import LogBookService from 'services/logBook.service';

import { autocompleteCountry, capitalizeFirstLetter, suggestionsFormatter } from 'helpers/Utils';
import { Filter } from 'pages/Dashboard/LogBook/QSOManager';
import { QSO_IMPORTED_ONLY, QSO_WRL_LOGGER_ONLY } from 'constants/index';
import { dxccCountryList } from 'constants/DXCCCountryList';

// Main Filter List (showing list of available filters)
interface MainFilterModalProps {
    filterConfig: Filter[];
    appliedFilters: Filter[];
    onSelectFilter: (filter: Filter) => void;
}

const MainFilterModal: React.FC<MainFilterModalProps> = ({ onSelectFilter, filterConfig, appliedFilters }) => {
    const appliedFields = appliedFilters.map((f) => f.field);
    return (
        <div className="absolute top-10 left-0 w-[10vw]  bg-[#444467] z-10 shadow-lg p-2 dropdown-wrapper" onClick={(e)=> e.stopPropagation()}>
            {filterConfig
                .filter((filter) => !appliedFields.includes(filter.field)) // Exclude applied filters
                .map((filter) => (
                    <div key={filter.field} className="py-1">
                        <button className="text-white font-Play text-sm" onClick={(e) => {
                            e.stopPropagation();
                            onSelectFilter(filter);
                            }}>
                            {filter.title}
                        </button>
                    </div>
                ))}
        </div>
    );
};

// Dynamic Filter Modal (Handles the selected filter logic)
interface DynamicFilterModalProps {
    filter: Filter;
    filterConfig: Filter[];
    setFiltersConfig: React.Dispatch<React.SetStateAction<Filter[]>>;
    onApplyFilter: (field: string, value: any) => void;
    onRemoveFilter: (field: string) => void;
    initialValues?: any[];
    onClose: () => void;
    modalPosition?: { top: number; left: number };
}

const DynamicFilterModal: React.FC<DynamicFilterModalProps> = ({ filter, setFiltersConfig, onApplyFilter, onRemoveFilter, initialValues = [], onClose, modalPosition }) => {
    const [selectedValues, setSelectedValues] = useState<any[]>(initialValues);
    const {
        parkReferenceSuggestionsData,
        getParReferenceSuggestions,
    } = usePota();

    const {
        logBooksList,
    } = useLogBook();

    const debouncedParkReferenceChangeHandler = useDebouncedCallback(
        (searchString) => getParReferenceSuggestions(searchString),
        1000
    );
    const [options, setOptions] = useState<any[]>([]);  // Hold fetched values for autocomplete or predefined lists
    const [searchTerm, setSearchTerm] = useState<string>('');
    
    // Fetch predefined options or autocomplete values based on filter type
    useEffect(() => {
        const fetchData = async () => {
            if (filter.fetchAPI && filter.field === "theirParks") {
                console.log('fetching!')
                debouncedParkReferenceChangeHandler({
                    key: "theirParkKey",
                    value: searchTerm.toUpperCase().trim(),
                });

            } else if (filter.fetchAPI && filter.field === "adiFileName") {
                const initialOptions = await LogBookService.getAdiLogbooks()
                console.log('Initial AdiFileName Options:', initialOptions);
                if (initialOptions && initialOptions.length > 0) {
                    const options = initialOptions.map(itm => itm.fileName)
                    setOptions(options);
                }
            } else if (filter.fetchAPI && filter.field === "theirCountry") {
                setOptions(autocompleteCountry(searchTerm, dxccCountryList).map((item) => item.country));

            } else if (filter.options) {
                setOptions(filter.options); // If predefined options exist in the config
            }
        };
        fetchData();
    }, [filter.field, searchTerm]);

    useEffect(() => {
        setSelectedValues(initialValues);
    }, [initialValues]);


    useEffect(() => {
        let options: any[] = [];
        switch (filter.field) {
            case "logBookId":
                if (logBooksList?.length) {
                    options = logBooksList.map((logBook: any) => ({ title: logBook.name, value: logBook.id }));
                }
                setFiltersConfig((prev) => {
                    const updatedFilters = prev.map((f) => {
                        if (f.field === filter.field) {
                            return { ...f, options };
                        }
                        return f;
                    });
                    return updatedFilters;
                });
                break;
            case "adiFileName":
                options = filter.options || []
                break;
            case "theirParks":
                if (parkReferenceSuggestionsData?.theirParkKey?.length) {
                    options = parkReferenceSuggestionsData?.theirParkKey.map((park: any) => suggestionsFormatter(park));
                }
                break;
            default:
                break
        }

        setOptions(options);
    }, [parkReferenceSuggestionsData, logBooksList, filter.options]);

    // Function to handle typing in autocomplete field
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleCheckboxChange = (value: string | boolean) => {
        setSelectedValues((prev) => {
          // For the special case of "SSB" that should include "LSB" and "USB"
          if (value === "SSB") {
            if (prev.includes("SSB")) {
              // If "SSB" is being unchecked, remove "SSB", "LSB", and "USB"
              return prev.filter(v => v !== "SSB" && v !== "LSB" && v !== "USB");
            } else {
              // If "SSB" is being checked, add "SSB", "LSB", and "USB"
              return [...prev.filter(itm => itm !== "SSB" && itm !== "LSB" && itm !== "USB"), "SSB", "LSB", "USB"];
            }
          }
      
          // If "LSB" or "USB" are manually unchecked, also remove "SSB"
          if ((value === "LSB" || value === "USB") && prev.includes("SSB")) {
            const updatedValues = prev.filter(v => v !== value);
            if (!updatedValues.includes("LSB") && !updatedValues.includes("USB")) {
              // Remove "SSB" if both "LSB" and "USB" are unchecked
              return updatedValues.filter(v => v !== "SSB");
            }
            return updatedValues;
          }
      
          // Handle normal cases for other options
          return prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value];
        });
      };


    const handleApply = () => {
        onApplyFilter(filter.field, selectedValues);
        if (selectedValues.length === 0) {
            // Remove the filter from appliedFilters
            onRemoveFilter(filter.field);
        }
        onClose();
    };


    return (
        <div
            className="absolute bg-[#444467] z-10 shadow-lg p-3 min-w-max rounded-lg dropdown-wrapper"
            style={{
                top: `${modalPosition?.top}px`,
                left: `${modalPosition?.left}px`
            }}  // Position dynamically based on click
            onClick={(e) => e.stopPropagation()}
        >
            {/* Header */}
            <div className="flex justify-between items-center pb-2 border-b border-gray-500">
                <span className="text-white font-Play text-md">{filter.title}</span>
                <img
                    onClick={onClose}
                    className="h-3 w-3 ml-2 text-white cursor-pointer"
                    src={Icons.CrossIcon}
                    alt="cross icon"
                />
            </div>

            {/* Body */}
            <div className="py-4 space-y-3 max-h-[300px] overflow-y-auto">
                {/* Boolean Filter (hasOptions handles Yes/No case) */}
                {filter.type === "boolean" && !filter.hasOptions && (
                    <label className="flex items-center space-x-2 text-white text-sm font-Play">
                        <input type="checkbox" onChange={() => handleCheckboxChange(true)} checked={selectedValues.includes(true)} />
                        <span>Yes</span>
                    </label>
                )}

                {/* Boolean Filter with Yes/No options */}
                {filter.type === "boolean" && filter.hasOptions && (
                    <div className="flex items-center space-x-4">
                        <label className="flex items-center space-x-2 text-white text-sm font-Play">
                            <input type="radio" onChange={() => setSelectedValues([true])} checked={selectedValues.includes(true)} />
                            <span>Yes</span>
                        </label>
                        <label className="flex items-center space-x-2 text-white text-sm font-Play">
                            <input type="radio" onChange={() => setSelectedValues([false])} checked={selectedValues.includes(false)} />
                            <span>No</span>
                        </label>
                    </div>
                )}

                {/* Multi-Boolean Filter */}
                {filter.type === "multiBoolean" && (
                    <div className="space-y-2">
                        {filter.options?.map((opt) => (
                            <label key={opt} className="flex items-center space-x-2 text-white text-sm font-Play">
                                <input type="checkbox" onChange={() => handleCheckboxChange(opt)} checked={selectedValues.includes(opt)} />
                                <span>{opt}</span>
                            </label>
                        ))}
                    </div>
                )}

                {/* Multi-Select Filter */}
                {filter.type === "multiSelect" && (
                    <div className="space-y-2">
                        {filter.options?.map((opt: any) => (
                            <label
                                key={typeof opt === 'object' ? opt.value : opt} // Use `value` as the key if opt is an object
                                className="flex items-center space-x-2 text-white text-sm font-Play"
                            >
                                {/* Handle object and string values */}
                                <input
                                    type="checkbox"
                                    onChange={() => handleCheckboxChange(typeof opt === 'object' ? opt.value : opt)}
                                    checked={selectedValues.includes(typeof opt === 'object' ? opt.value : opt)}
                                />
                                <span>{typeof opt === 'object' ? opt.label : opt}</span> {/* Display title if available */}
                            </label>
                        ))}
                    </div>
                )}


                {/* Text Search Filter */}
                {filter.type === "textSearch" && (
                    <input
                        type="text"
                        className="text-white bg-[#31336e] p-2 rounded w-full text-sm font-Play"
                        value={selectedValues[0] || ''}
                        onChange={(e) => setSelectedValues([e.target.value])}
                        placeholder="Enter text"
                    />
                )}

                {/* Radio Filter */}
                {filter.type === "radio" && (
                    <div className="space-y-2">
                        {filter.options?.map((opt) => (
                            <label key={opt} className="flex items-center space-x-2 text-white text-sm font-Play">
                                <input type="radio" onChange={() => setSelectedValues([opt])} checked={selectedValues.includes(opt)} />
                                <span>{opt}</span>
                            </label>
                        ))}
                    </div>
                )}

                {/* Comparison Filter */}
                {filter.type === "comparison" && (
                    <div className="space-y-2">
                        <select
                            value={selectedValues[0] || ''}
                            onChange={(e) => setSelectedValues([e.target.value, selectedValues[1]])}
                            className="text-white bg-[#31336e] p-2 rounded w-full text-sm font-Play"
                        >
                            {filter.options?.map((opt) => <option key={opt}>{opt}</option>)}
                        </select>
                        <input
                            type="number"
                            className="text-white bg-[#31336e] p-2 rounded w-full text-sm font-Play"
                            value={selectedValues[1] || ''}
                            onChange={(e) => setSelectedValues([selectedValues[0], e.target.value])}
                            placeholder="Enter value"
                        />
                    </div>
                )}
                {/* Display Autocomplete */}
                {(filter.field === 'theirParks' || filter.field === 'theirCountry') && filter.type === "autoComplete" && (
                    <input
                        type="text"
                        className="text-white bg-[#444467] font-Play"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                    />
                )}

                {/* Render the fetched options dynamically */}
                {(filter.type === "autoComplete" && options.length > 0) && (
                    <div className="py-2">
                        {options.map((opt: any) => {
                            const displayTitle = typeof opt === 'object' ? opt.title : opt
                            const optionValue = typeof opt === 'object' ? opt.value : opt

                            return (
                                <div key={optionValue} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(optionValue)}
                                        checked={selectedValues.includes(optionValue)}
                                    />
                                    <span className="text-white ml-2 text-xs">{displayTitle}</span>
                                </div>
                            );
                        })}
                    </div>
                )}

            </div>

            {/* Footer */}
            <div className="pt-2 border-t border-gray-500 flex justify-end space-x-2">
                <button className="bg-transparent text-white px-2 py-1 rounded-sm font-Play text-md" onClick={onClose}>Cancel</button>
                <button className="bg-[#17F9DA] text-black px-2 py-1 rounded-sm font-Play text-md" onClick={handleApply}>Apply</button>
            </div>
        </div>
    );
};

// Applied Filters Component
interface AppliedFiltersProps {
    filters: Filter[];
    filterConfig: Filter[];
    onEditFilter: (filter: Filter, values: any, event: React.MouseEvent<HTMLSpanElement>) => void;
    onRemoveFilter: (field: string) => void;
}

const AppliedFilters: React.FC<AppliedFiltersProps> = ({ filters, filterConfig, onEditFilter, onRemoveFilter }) => {
    const getDisplayValue = (filter: Filter): string => {
        const foundFilter = filterConfig.find(f => f.field === filter.field);
        if (Object.keys(filter).length === 0) return '';
        const { field, value } = filter;
        
        if (field === 'adiImported') {
            const options = { adi: QSO_IMPORTED_ONLY, wrl: QSO_WRL_LOGGER_ONLY }
            if (Array.isArray(value)) {
                const displayLabels = value
                    .map((v: string) => options[v as keyof typeof options] || v)
                    .join(", ");
                return displayLabels;
            }
        }
        if (field === 'logBookId' && (filter.displayValue || foundFilter?.options?.length)) {
            if (foundFilter?.options?.length) {
                const labelMap = Object.fromEntries(foundFilter.options.map((opt) => [opt.value, opt.title]));
                return Array.isArray(value)
                    ? value.map((v: string) => labelMap[v] || v).join(", ")
                    : labelMap[value] || value;
            }

            return filter.displayValue || '';
        }
        return Array.isArray(value) ? value.join(', ') : value.toString();
    };

    return (
        <div className="flex items-center flex-wrap gap-2">
            {filters.map((filter) => {
                const foundFilter = filterConfig.find(f => f.field === filter.field);
                const hasOptionFalse = foundFilter?.hasOptions == false;
                const displayValue = getDisplayValue(filter);
                return (
                    <GradientDynamicButton
                        key={filter.field}
                        text={hasOptionFalse ? capitalizeFirstLetter(filter.title) || '' : `${filter.title}: ${ displayValue.length > 50 ? displayValue.substring(0, 50) + '...' : displayValue}`}
                        width="auto"
                        height="25px"
                        fontSize="text-sm"
                        spanClasses="text-sm"
                        showCloseIcon={true}
                        onClose={() => onRemoveFilter(filter.field)}
                        onClick={(event: React.MouseEvent<HTMLSpanElement>) => hasOptionFalse ? null : onEditFilter(foundFilter!, filter.value, event)}
                    />
                )
            })}
        </div>
    );
};

export { MainFilterModal, DynamicFilterModal, AppliedFilters };