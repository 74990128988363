import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa6';
import { IoCreateOutline, IoEyeOutline } from 'react-icons/io5';

import Icons from 'assets/Icons';
import DynamicButton from 'components/Button/DynamicButton';
import DateRangePicker from 'components/DateRangePicker';
import { AppliedFilters, DynamicFilterModal, MainFilterModal } from 'components/DropDown/DropDown/DynamicFilter';
import CountryDxccFlag from 'components/CountryDxccFlag';

import { getQSOManagerContactsSelector } from 'store/Reducers/qsoManager.reducer';
import { fetchQSOManagerContacts } from 'store/Actions/qsoManager.actions';
import { mergeLogBookContacts, setContactDetails } from 'store/Actions/logBook.actions';

import { Sort } from 'types/QSOManager.types';
import { ToastTypes } from 'types/Component';
import { LogBookContactModel } from 'types/Models';

import { convertBandValue, formatTimestamp, getPageContacts, sortArray } from 'helpers/Utils';
import { showToast } from 'helpers/Toast';

import { auth } from 'firebase-config';
import LogBookService from 'services/logBook.service';

import {
    COMPLETE,
    IN_PROGRESS,
    QSO_IMPORTED_ONLY,
    QSO_MANAGER_MAX_OFFSET,
    QSO_MANAGER_PAGE_SIZE,
    QSO_WRL_LOGGER_ONLY
} from 'constants/index';
import { AllBandsOption, Modes } from 'constants/ArrayVariables';
import { CONTACT, MY_PROFILE_ROUTE } from 'constants/Links';

import useLogBook from 'hooks/logBook.hook';
import { globalNavigate } from 'routes/GlobalRoutes';
import { endOfDay, startOfDay } from 'date-fns';
import Pagination from './Pagination';
import { useQsoManager } from 'hooks/useQsoManager.hook';
import useConfig from 'hooks/config.hook';
import { useEditContact } from 'hooks/useEditContact.hook';
import { useUserActivity } from 'hooks/useUserActivity.hook';
import { Tooltip } from 'components/Tooltip';
import CustomValidation from 'helpers/Validation';

export interface AppliedFilter {
    field: string;
    value: any;
    title: string;
    hasOptions?: boolean
}

type FilterType = "boolean" | "multiBoolean" | "multiSelect" | "textSearch" | "autoComplete" | "radio" | "comparison";

export interface Filter {
    field: string;
    title?: string;
    type?: FilterType;
    displayValue?: string;
    value?: any;
    hasOptions?: boolean; // Indicating if boolean filter should have "Yes/No" options
    options?: any[];
    fetchAPI?: string;
}

interface Column {
    key: keyof LogBookContactModel;
    label: string;
    visible: boolean;
    sortable: boolean;
    fetched: boolean;
    sortKey?: string;
    abbreviation?: string;
    url?: (item: LogBookContactModel) => string;
}


const filtersConfig: Filter[] = [
    { title: "Band", field: "band", type: "multiSelect", options: AllBandsOption.map(itm => ({ label: convertBandValue(itm.name, itm.unit) + itm.unit, value: itm.name })) },
    { title: "Mode", field: "userMode", type: "multiSelect", options: Modes },
    { title: "LogBook", field: "logBookId", type: "autoComplete", fetchAPI: "logBooks" },
    { title: "Country", field: "theirCountry", type: "autoComplete", fetchAPI: 'dxccCountryList', /*options: dxccCountryList.map((item) => item.country)*/ },
    { title: "Imports", field: "adiFileName", type: "autoComplete", fetchAPI: "adiImports" },
    { title: "Duplicate", field: "duplicate", type: "boolean", hasOptions: false },
    { title: "Confirmed", field: "confirmed", type: "boolean", hasOptions: false }, // Yes/No type
    { title: "Imported Contacts", field: "adiImported", type: "multiSelect", options: [{ label: QSO_IMPORTED_ONLY, value: 'adi' }, { label: QSO_WRL_LOGGER_ONLY, value: 'wrl' }] },
    // { title: "State Name", field: "stateLongName", type: "textSearch" },
    // { title: "Parks", field: "theirParks", type: "autoComplete", fetchAPI: "parks" },
    // { title: "Has Notes", field: "notes", type: "radio", options: ["Yes", "No"] },
    // { title: "Power", field: "power", type: "comparison", options: [">=", "<="] },
    // { title: "Missing Data", field: "missingData", type: "multiSelect", options: ["Missing CallSign", "Missing Coordinates", "Missing DXCC Entity"] },

];

const columnsConfig: Column[] = [
    { key: 'contactTimeStamp', label: 'Date/Time', visible: true, sortable: true, fetched: true },
    { key: 'logBook', label: 'Logbook', visible: true, sortable: false, fetched: true },
    { key: 'theirCallsign', label: 'Call Sign', visible: true, sortable: false, fetched: true, url: (item) => item?.theirCallsign ? `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(item?.theirCallsign)}?tab=Logbook&contact-tab=entries` : '' },
    // { key: 'country', label: 'Country', visible: true, sortable: false, fetched: true },
    { key: 'band', label: 'Band', visible: true, sortable: false, fetched: true },
    { key: 'userMode', label: 'Mode', visible: true, sortable: false, fetched: false },
    { key: 'theirCountry', label: 'Country', visible: true, sortable: false, fetched: false },
    { key: 'theirState', label: 'State', visible: true, sortable: false, fetched: false },
    { key: 'theirName', label: 'Name', visible: true, sortable: false, fetched: false },
    { key: 'confirmed', label: 'Confirmed', abbreviation: 'Conf.', visible: true, sortable: false, fetched: true },
    { key: 'power', label: 'Power', visible: false, sortable: false, fetched: true },
    // { key: 'mySavedLocationId', label: 'My Saved Location ID', visible: false, sortable: false, fetched: false },
    // { key: 'myAntenna', label: 'My Antenna', visible: false, sortable: false, fetched: false },
    // { key: 'myRadio', label: 'My Radio', visible: false, sortable: false, fetched: false },
    // { key: 'myCity', label: 'My City', visible: false, sortable: false, fetched: false },
    { key: 'myCountry', label: 'My Country', visible: false, sortable: false, fetched: false },
    // { key: 'state', label: 'State', visible: false, sortable: false, fetched: false }, // Verify this
    // { key: 'myState', label: 'My State', visible: false, sortable: false, fetched: false },
    // { key: 'myStateLongName', label: 'My State Long Name', visible: false, sortable: false, fetched: false },
    { key: 'myPark', label: 'My Park', visible: false, sortable: false, fetched: false },
    // { key: 'myParks', label: 'My Parks', visible: false, sortable: false, fetched: false },
    { key: 'notes', label: 'Notes', visible: false, sortable: false, fetched: false },
    { key: 'rstSent', label: 'RST Sent', visible: false, sortable: false, fetched: false },
    { key: 'rstRCVD', label: 'RST Rcvd', visible: false, sortable: false, fetched: false },
    { key: 'stateLongName', label: 'State Name', visible: false, sortable: false, fetched: false },
    { key: 'tags', label: 'Tags', visible: false, sortable: false, fetched: false },
    { key: 'theirCity', label: 'Their City', visible: false, sortable: false, fetched: false },
    { key: 'theirPark', label: 'Their Park', visible: false, sortable: false, fetched: false },
    // { key: 'theirParks', label: 'Their Parks', visible: false, sortable: false, fetched: false },
    { key: 'contestName', label: 'Contest', visible: false, sortable: false, fetched: false },
    // { key: 'operator', label: 'Operator', visible: false, sortable: false, fetched: false },
    { key: 'myCallSign', label: 'My CallSign', visible: false, sortable: false, fetched: false },
    { key: 'adiFileName', label: 'File Name', visible: false, sortable: false, fetched: false }
];

const handleCellClick = (url: string) => {
    window.location.href = url;
};


const renderCellValue = (value: any, key: any, contact: any): React.ReactNode => {

    if (value && key == 'contactTimeStamp') {
        const timestamp = formatTimestamp(value);
        const date = timestamp instanceof Date ? timestamp.toLocaleDateString() : 'N/A';
        const time = timestamp instanceof Date ? timestamp.toLocaleTimeString() : 'N/A';
        return (
            <span>
                {time} <span className="text-[#9D9D9D]">{date || 'N/A'}</span>
            </span>
        );
    }
    else if (value && key == 'confirmed') {
        return <img src={Icons.Confirmed} alt="" className="w-[20px] h-[20px]" />

    }
    else if (value && key == 'logBook') {
        const displayValue = value?.name?.length > 20 ? `${value.name.slice(0, 20)}...` : value.name;
        return value?.name?.length > 20 ? (
            <Tooltip message={value.name} className="bg-gradient-to-r from-[#3a3a5f] to-[#444467] border border-[#5a5a7a] rounded-md shadow-lg text-white text-xs p-2 whitespace-nowrap !-top-1 left-10">
                <span>{displayValue}</span>
            </Tooltip>
        ) : (
            <span>{value.name}</span>
        );
    }
    else if ((key === 'country' || key === 'myCountry') && contact.myCountry && contact.myFlagCode) {
        return <CountryDxccFlag
            countryCode={contact.myCountry || ""}
            flagCode={contact.myFlagCode || ""}
        />
    }
    else if (key === 'theirCountry' && (contact.theirCountry || contact.flagCode)) {
        return (contact.theirCountry && contact.flagCode)
            ?
            <div className="flex items-center">
                <CountryDxccFlag
                    countryCode={contact.theirCountry || ""}
                    flagCode={contact.flagCode || ""}
                />
                <span className="ml-2">{contact.theirCountry}</span>
            </div>
            : contact.theirCountry;
    }
    else if (value instanceof Date) {
        return value.toLocaleDateString();
    }
    else if (typeof value === 'object' && value !== null) {
        return <pre>{JSON.stringify(value, null, 2)}</pre>; // Render object as formatted JSON
    }
    else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        const column = columnsConfig.find(col => col.key === key);
        if (column?.url) {
            const url = column.url(contact);
            return (
                <span
                    className="cursor-pointer"
                    onClick={() => handleCellClick(url)}
                >
                    {value}
                </span>
            );
        }
        return value;
    }
    else {
        return '-';
    }
};

const renderColumnHeader = (column: Column): React.ReactNode => {
    if (column.abbreviation && column.label) {
        return (
            <Tooltip message={column.label} className="bg-gradient-to-r from-[#3a3a5f] to-[#444467] border border-[#5a5a7a] rounded-md shadow-lg text-white text-xs p-2 whitespace-nowrap !-top-1 left-10">
                <span>{column.abbreviation}</span>
            </Tooltip>
        );
    }
    return <span>{column.label}</span>
};

const QSOManager: React.FC = () => {

    const {
        setEditContactModalData
    } = useLogBook();
    const dispatch = useDispatch();
    const { contacts: fetchedContacts, loading, error, totalCount, limitExceeded } = useSelector(getQSOManagerContactsSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalQSOs, setTotalQSOs] = useState<number>(totalCount);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [fieldsToFetch, setFieldsToFetch] = useState<string[]>([]);
    const [sortConfig, setSortConfig] = useState<Sort | null>({ key: 'contactTimeStamp', direction: 'desc' });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [filterConfig, setFiltersConfig] = useState<Filter[]>(filtersConfig);
    const [qsoFilters, setQsoFilters] = useState<{ [key: string]: any }>({});

    // State to handle dropdown toggle for given lists
    const [dropdownVisible, setDropdownVisible] = useState<{ [key: string]: boolean }>({
        columns: false,
        filters: false,
    });

    // State for selective fetch flag
    const [useSelectiveFields, setUseSelectiveFields] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);
    const [recentAppliedFilter, setRecentAppliedFilter] = useState<Filter | { [key: string]: any } | null>(null);
    const [appliedFilters, setAppliedFilters] = useState<Filter[]>([]);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [columns, setColumns] = useState<Column[]>(columnsConfig);
    const [contacts, setContacts] = useState<LogBookContactModel[]>(fetchedContacts);
    const [localFilteredContacts, setLocalFilteredContacts] = useState<LogBookContactModel[]>([]);
    const [isFirstDateRangeApplied, setIsFirstDateRangeApplied] = useState<boolean>(false);
    const [paginatedContacts, setPaginatedContacts] = useState<LogBookContactModel[]>([]);
    const [fetchedFilters, setFetchedFilters] = useState<{ [key: string]: Set<any>; }>({});

    const [shouldFetchFromBackend, setShouldFetchFromBackend] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const cursorsRef = useRef<{ [pageNumber: number]: string | null }>({});
    const debounceRef = useRef<NodeJS.Timeout | null>(null);
    const prevFiltersRef = useRef<{ [key: string]: any }>({});

    const useFilterHooks = () => {
        const {
            getLogBooks,
            logBooksList,
        } = useLogBook();

        return {
            logBookId: { fetchFunction: getLogBooks, options: logBooksList },
        };
    };

    const limit = QSO_MANAGER_PAGE_SIZE;

    const { bulkExport, bulkRemove } = useQsoManager();
    const { handlePrimaryModal } = useConfig();
    const { setEditContactModalData: showEditModal } = useEditContact();
    const { previousStatus, userActivityStatus, shouldFetchActivities, setShouldFetchActivities, setPreviousStatus, userActivityType } = useUserActivity();

    const showMergeLogbookModal = (data: any) => {
        dispatch(mergeLogBookContacts(data));
    }


    const fetchDataFromBackend = () => {
        let page = currentPage;

        // Determine the lastVisibleDocId
        const offset = (page - 1) * limit;
        let lastVisibleDocId: string | null = null;

        if (offset <= QSO_MANAGER_MAX_OFFSET) {
            // Use offset-based pagination for small offsets
            lastVisibleDocId = null;
        } else {
            lastVisibleDocId = cursorsRef.current[page - 1] || null;
        }

        dispatch(fetchQSOManagerContacts({
            filters: qsoFilters,
            pagination: {
                limit,
                offset: offset <= QSO_MANAGER_MAX_OFFSET ? offset : null,
                lastVisibleDocId,
            },
            uid: auth.currentUser?.uid,
            fields: fieldsToFetch?.length > 0 ? fieldsToFetch : null,
            sortConfig: sortConfig,
        }));
    }

    useEffect(() => {
        if (
            previousStatus === IN_PROGRESS &&
            userActivityStatus === COMPLETE &&
            shouldFetchActivities
        ) {

            setSelectedRows([]);
            setAllSelected(false);
            setSelectAll(false);

            // Trigger fetch when the sequence ends with `progress -> completed`
            fetchDataFromBackend();
            setShouldFetchActivities(false); // Reset to prevent repeated fetching
        } else if (previousStatus === COMPLETE && userActivityStatus === COMPLETE) {
            // Set flag if sequence starts with `initial -> completed`
            setShouldFetchActivities(true);
        }

        // Update the previous status for next effect trigger
        setPreviousStatus(userActivityStatus);
        setIsLoading(userActivityType === "exportAllContacts" && userActivityStatus === IN_PROGRESS)
    }, [userActivityStatus, previousStatus]);

    useEffect(() => {
        setTotalQSOs(totalCount);
    }, [totalCount]);

    useEffect(() => {
        let page = currentPage;

        // Check if filters have changed
        const filtersChanged =
            JSON.stringify(prevFiltersRef.current) !== JSON.stringify(qsoFilters);

        if (filtersChanged) {
            // Reset pagination state
            cursorsRef.current = {};
            page = 1;
            prevFiltersRef.current = { ...qsoFilters };
            handlePageChange(1);
        } else {
            setCurrentPage(page);
        }

    }, [qsoFilters]);

    useEffect(() => {
        if (fetchedContacts && fetchedContacts.length > 0) {
            const newFetchedFilters = {} as any;

            // For each filterable field
            appliedFilters.forEach((filter) => {
                if (!newFetchedFilters[filter.field]) {
                    newFetchedFilters[filter.field] = new Set();
                }

                if (Array.isArray(filter.value)) {
                    filter.value.forEach((val) => newFetchedFilters[filter.field].add(val));
                } else {
                    newFetchedFilters[filter.field].add(filter.value);
                }
            });

            setFetchedFilters(newFetchedFilters);
        }

        if (fetchedContacts?.length === 0) {
            setPaginatedContacts([]);
        }
        if (limitExceeded) {
            setShouldFetchFromBackend(false);
        }

        setContacts(fetchedContacts);

    }, [fetchedContacts, limitExceeded]);

    useEffect(() => {
        if (!auth.currentUser?.uid) return;

        // if (limitExceeded && !isFilterSuperset) {
        if (limitExceeded && !shouldFetchFromBackend) {
            console.log('applying filters locally: ', contacts?.length);
            // Apply filters locally
            if (qsoFilters && Object.keys(qsoFilters).length > 0) {
                console.log('recentFilter: ', recentAppliedFilter)
                let filteredData = applyFiltersLocally(contacts, qsoFilters);
                setLocalFilteredContacts(filteredData);
                if (filteredData) {
                    setTotalQSOs(filteredData.length);
                    // Handle sorting locally
                    let sortedData = filteredData;
                    if (sortConfig && filteredData.length) {
                        sortedData = sortArray(filteredData, sortConfig);
                    }

                    let paginatedFilteredData: LogBookContactModel[] = getPageContacts(
                        sortedData,
                        currentPage,
                        limit
                    );
                    setPaginatedContacts(paginatedFilteredData);
                }
            }

            // setContacts(filteredData);
        } else {
            console.log('fetching data from backend');
            // Proceed with dispatch to fetch data from backend
            let page = currentPage;

            // Determine the lastVisibleDocId
            const offset = (page - 1) * limit;
            let lastVisibleDocId: string | null = null;

            if (offset <= QSO_MANAGER_MAX_OFFSET) {
                // Use offset-based pagination for small offsets
                lastVisibleDocId = null;
            } else {
                lastVisibleDocId = cursorsRef.current[page - 1] || null;
            }

            dispatch(fetchQSOManagerContacts({
                filters: qsoFilters,
                pagination: {
                    limit,
                    offset: offset <= QSO_MANAGER_MAX_OFFSET ? offset : null,
                    lastVisibleDocId,
                },
                uid: auth.currentUser?.uid,
                fields: fieldsToFetch?.length > 0 ? fieldsToFetch : null,
                sortConfig: sortConfig,
            }));
        }
    }, [qsoFilters, fieldsToFetch, sortConfig, auth.currentUser, currentPage, shouldFetchFromBackend]);

    useEffect(() => {
        if (contacts?.length) {
            if (limitExceeded) {
                console.log('limit exceeded: ', contacts.length);
                // setTotalQSOs(contacts.length);
                // Handle sorting locally
                let sortedData = localFilteredContacts.length ? localFilteredContacts : contacts;
                if (sortConfig) {
                    sortedData = sortArray(localFilteredContacts.length ? localFilteredContacts : contacts, sortConfig);
                }
                // Handle pagination locally
                const pageContacts = getPageContacts(sortedData, currentPage, limit);
                setPaginatedContacts(pageContacts);
            } else {
                console.log('limit not exceeded: ', contacts?.length);
                // Use contacts from selector (paginated from backend)
                const lastVisibleDocId = contacts[contacts.length - 1].id;
                cursorsRef.current[currentPage] = lastVisibleDocId ?? null;
                setPaginatedContacts(contacts);
            }
        }
    }, [contacts, currentPage, limitExceeded]);

    const hookConfig = useFilterHooks();

    // Initialize filters from URL on component load
    useEffect(() => {

        const initializeFiltersFromURL = async () => {
            const params = Object.fromEntries([...searchParams.entries()]); // Get all URL parameters
            const initialFilters = { ...qsoFilters };  // Clone existing filters

            // Handle Static Filters (e.g., `searchString`, `dateRange`) explicitly
            if (params.searchString) {
                initialFilters.searchString = params.searchString;
                setSearchTerm(params.searchString);
            }
            if (params.startDate && params.endDate) {
                // check if params has only startDate and endDate no other property
                if (Object.keys(params).length === 2) {
                    setIsFirstDateRangeApplied(true);
                }
                initialFilters.startDate = startOfDay(new Date(params.startDate));
                initialFilters.endDate = endOfDay(new Date(params.endDate));
            }

            const fetchedOptions = await fetchOptionsForFilters(params, hookConfig);

            // Populate Dynamic Filters from `filterConfig`
            filterConfig.forEach((filter) => {
                if (params[filter.field]) {
                    if (filter.type === "boolean") {
                        initialFilters[filter.field] = params[filter.field] === 'true';
                    } else if (filter.type === "multiSelect" || filter.type === "multiBoolean" || filter.type === "autoComplete") {
                        initialFilters[filter.field] = params[filter.field].split(',');
                    } else if (filter.type === "comparison") {
                        const [operator, value] = params[filter.field].split('|');
                        initialFilters[filter.field] = [operator, value];
                    }
                    else {
                        initialFilters[filter.field] = params[filter.field];
                    }
                }
            });
            setQsoFilters(initialFilters);
            setAppliedFilters(convertToAppliedFilters(initialFilters, fetchedOptions)); // Set applied filters from qsoFilters
        };
        if (searchParams.size)
            initializeFiltersFromURL();
    }, []);

    // Synchronize qsoFilters with URL params whenever they change
    useEffect(() => {
        const params = new URLSearchParams();

        // Iterate through `qsoFilters` and add each filter to the URL
        Object.entries(qsoFilters).forEach(([key, value]) => {
            if (value) {
                if (Array.isArray(value)) {
                    params.set(key, value.join(','));
                } else if (typeof value === 'object' && value.startDate && value.endDate) {
                    // Handle date range
                    params.set('startDate', value.startDate);
                    params.set('endDate', value.endDate);
                } else {
                    params.set(key, value.toString());
                }
            }
        });
        setSearchParams(params); // Update the URL
    }, [qsoFilters]);

    // When individual rows are selected/deselected, sync "selectAll" checkbox state
    useEffect(() => {
        setSelectAll(selectedRows?.length === contacts?.length && !allSelected);
    }, [selectedRows, contacts, allSelected]);

    // Check for enabling "Unselect all" button when all rows are manually checked
    // Prevent selectAll from flickering when state changes
    useEffect(() => {
        if (!allSelected) {
            setSelectAll(selectedRows?.length === contacts?.length);
        }
    }, [selectedRows, contacts?.length, allSelected]);


    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            // The dropdown wrapper and button are being used to check if the click was outside
            const dropdownWrapper = document.querySelector('.dropdown-wrapper');
            const dropdownButton = document.querySelector('.dropdown-button');

            // Check if the click target is neither inside the dropdown nor the dropdown button
            if (
                dropdownWrapper &&
                dropdownButton &&
                !dropdownWrapper.contains(event.target as Node) &&
                !dropdownButton.contains(event.target as Node)
            ) {
                toggleAllDropdowns();
                setSelectedFilter(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const needToFetchDataForFilter = (field: string, value: any): boolean => {
        const fetchedValues = fetchedFilters[field] || new Set();

        if (Array.isArray(value)) {
            return value.some((val) => !fetchedValues.has(val));
        } else {
            return !fetchedValues.has(value);
        }
    };

    const applyFiltersLocally = (
        data: LogBookContactModel[],
        filters: { [key: string]: any }
    ): LogBookContactModel[] => {
        let isDateFilterApplied = false;
        return Object.keys(filters).reduce((filteredData, key) => {
            const filterValue = filters[key];
            if (filterValue) {
                // Handle both `startDate` and `endDate` together if they exist
                if (!isDateFilterApplied && (key === 'startDate' || key === 'endDate')) {
                    const startDate = filters['startDate'];
                    const endDate = filters['endDate'];
                    if (startDate || endDate) {
                        isDateFilterApplied = true;
                        return filteredData.filter(item => {
                            const itemDate = new Date(formatTimestamp(item.contactTimeStamp));
                            const itemDateOnly = startOfDay(itemDate);
                            const startDateOnly = startDate ? startOfDay(new Date(startDate)) : null;
                            const endDateOnly = endDate ? endOfDay(new Date(endDate)) : null;
                            return (
                                (!startDateOnly || itemDateOnly >= startDateOnly) &&
                                (!endDateOnly || itemDateOnly <= endDateOnly)
                            );
                        });
                    }
                }

                if (key !== 'startDate' && key !== 'endDate') {
                    return filteredData.filter(item => {
                        const itemValue = item[key as keyof LogBookContactModel];
                        if (Array.isArray(filterValue)) {
                            return filterValue.some(val => itemValue == val);
                        }
                        return itemValue === filterValue;
                    });
                }

            }
            return filteredData;
        }, data);
    };

    const handleDateRangeChange = (range: { startDate: Date | null, endDate: Date | null }) => {
        if (!qsoFilters || (qsoFilters.length === 0 && !qsoFilters.startDate && !qsoFilters.endDate)) {
            setIsFirstDateRangeApplied(true);
        }

        const startDate = range.startDate ? startOfDay(new Date(range.startDate)) : null;
        const endDate = range.endDate ? endOfDay(new Date(range.endDate)) : null;

        setQsoFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            if (startDate) {
                updatedFilters.startDate = startDate;
            } else {
                delete updatedFilters.startDate;
            }
            if (endDate) {
                updatedFilters.endDate = endDate;
            } else {
                delete updatedFilters.endDate;
            }
            return updatedFilters;
        });

        setRecentAppliedFilter({ startDate, endDate });

        // Should only apply filter locally when no range is applied earlier otherwise may be fetch from BE
        if (limitExceeded && !isFirstDateRangeApplied) {
            setShouldFetchFromBackend(false);
        } else {
            setShouldFetchFromBackend(true);
        }

    };


    const convertToAppliedFilters = (filters: any, dynamicOptions: { [key: string]: any[] }) => {
        const applied = [];

        for (const key in filters) {
            if (filters[key] && key !== 'startDate' && key !== 'endDate' && key !== 'searchString') {
                // Determine the display title from filterConfig or custom logic
                const config = filterConfig.find((f) => f.field === key);
                const title = config ? config.title : key;

                // Check if the field has dynamic options available
                let displayValue = filters[key];
                if (dynamicOptions[key]) {
                    // Map the value to its corresponding label using dynamicOptions
                    const labelMap = Object.fromEntries(dynamicOptions[key].map((opt) => [opt.id, opt.name]));
                    displayValue = Array.isArray(filters[key])
                        ? filters[key].map((v: string) => labelMap[v] || v).join(", ")
                        : labelMap[filters[key]] || filters[key];
                }
                applied.push({
                    field: key,
                    title,
                    value: filters[key], // Keep value for backend usage
                    options: dynamicOptions[key] || [], // Add dynamic options for filter
                    displayValue,         // Add display value for showing in UI
                    hasOptions: config?.hasOptions || false,
                });
            }
        }

        return applied;
    };

    // Debounced search logic
    const handleDebouncedInput = (value: string) => {
        setSearchTerm(value);
        // Clear the previous timeout if the user is still typing
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        // Set a new timeout
        debounceRef.current = setTimeout(() => {
            // updateFilter('searchString', value);
            // setQsoFilters({ searchString: value });
            console.log(qsoFilters)

            if (!shouldFetchFromBackend) {
                setShouldFetchFromBackend(true)
            }

            setQsoFilters({ searchString: value });

            setAppliedFilters((prevAppliedFilters) => {
                if (prevAppliedFilters.length > 0) {
                    return [];
                }
                return prevAppliedFilters;
            });
        }, 1000);
    };

    // Handle page change
    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= Math.ceil(totalQSOs / limit)) {
            setCurrentPage(newPage);
        }
    };


    // Handle column visibility change
    const toggleColumnVisibility = (columnKey: string) => {
        setColumns((prevColumns) => {

            const oldColumn = prevColumns.find((col) => col.key === columnKey);
            // Determine the updated visibility status of the column and handle fetched state
            const updatedColumns = prevColumns.map((col) => {
                if (col.key === columnKey) {
                    const updatedVisibility = !col.visible;

                    // If visibility is being set to true, mark fetched as true if already fetched before
                    return {
                        ...col,
                        visible: updatedVisibility,
                        fetched: !col.fetched && updatedVisibility ? true : col.fetched,
                    };
                }
                return col;
            });

            // Check if `useSelectiveFields` is true and a new column is being added to visibility
            const column = updatedColumns.find((col) => col.key === columnKey);
            const needsFetch =
                column?.visible === true && useSelectiveFields && !oldColumn?.fetched;

            if (needsFetch) {
                // Get the updated list of visible columns' keys
                const fieldsToFetch = updatedColumns.filter((col) => col.visible).map((col) => col.key);
                setFieldsToFetch(fieldsToFetch);
            }

            return updatedColumns;
        });
    };


    const visibleColumns = useMemo(() => columns.filter((col) => col.visible), [columns]);

    const handleSort = (column: Column) => {
        if (!column.sortable) return;

        setSortConfig((prevSortConfig) =>
        ({
            key: column.key,
            direction: prevSortConfig?.key === column.key && prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
        })
        );
    };


    // Toggle dropdown visibility for a particular list
    const toggleDropdown = (listName: string, flag?: boolean) => {
        setDropdownVisible((prevState) => ({
            ...prevState,
            [listName]: flag || !prevState[listName], // Toggle the visibility of the clicked list
        }));
    };

    const toggleAllDropdowns = (exceptKey?: string) => {
        setDropdownVisible((prevState) => {
            // Iterate over each key and set to false, except for the specified key
            const updatedState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = key === exceptKey ? prevState[key] : false;
                return acc;
            }, {} as Record<string, boolean>);

            return updatedState;
        });
    };

    // Prevent dropdown from closing when clicking inside it
    const handleDropdownClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const handleSelectFilter = async (filter: Filter) => {
        if (filter.type === "autoComplete" && filter.fetchAPI && filter.field === 'adiFileName') {
            // Fetch initial values for the autocomplete filter when selected
            const initialOptions = await LogBookService.getAdiLogbooks()
            if (initialOptions && initialOptions.length > 0) {
                const options = initialOptions.map(itm => itm.fileName)
                // setDynamicOptions((prevOptions) => ({ ...prevOptions, [filter.field]: options || [] }));
                filter.options = options;
                setSelectedFilter(filter);
            }
        }
        if (filter.type === "boolean" && !filter.hasOptions) {
            // If it's a direct boolean filter, apply immediately
            handleApplyFilter(filter.field, true);
        } else {
            setSelectedFilter(filter); // Otherwise, show the second modal
        }
        // setShowMainModal(false);
        toggleDropdown('filters', false)
    };

    const handleApplyFilter = (field: string, value: any) => {
        if (searchTerm) {
            setSearchTerm('');
            setQsoFilters({})
        }
        // Find the index of the existing filter, if it exists
        const existingFilterIndex = appliedFilters.findIndex((f) => f.field === field);

        // If adding the filter exceeds the limit (and it's not an existing filter), show a warning and return early
        if (existingFilterIndex === -1 && appliedFilters?.length >= 4) {
            showToast({
                message: "You can only apply 4 filters at a time",
                type: ToastTypes.WARN,
            });
            return;
        }

        // Update the applied filters
        const newFilters = [...appliedFilters];
        if (existingFilterIndex !== -1) {
            newFilters[existingFilterIndex] = { field, value, title: selectedFilter?.title || field };
        } else {
            newFilters.push({ field, value, title: selectedFilter?.title || field });
        }

        const needToFetch = needToFetchDataForFilter(field, value);
        console.log('needToFetch', needToFetch);
        setShouldFetchFromBackend(needToFetch);

        setRecentAppliedFilter({ field: selectedFilter?.field ?? '', value });
        setAppliedFilters(newFilters);
        updateFilter(field, value);
        setSelectedFilter(null);
    };

    const handleRemoveFilter = (field: string) => {
        // Remove the specific field from qsoFilters
        setQsoFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            delete updatedFilters[field]; // Remove the field from the filters
            return updatedFilters;
        });
        setAppliedFilters((prev) => prev.filter((f) => f.field !== field));
        setShouldFetchFromBackend(true);
    };


    const handleEditFilter = (filter: Filter, values: any, event: React.MouseEvent<HTMLSpanElement>) => {
        const container = document.getElementById('filter-section');
        const rect = container?.getBoundingClientRect();

        // Adjust for the container offset and scroll position
        const topOffset = event.clientY - (rect?.top || 0);
        const leftOffset = event.clientX - (rect?.left || 0);

        setModalPosition({
            top: topOffset + window.scrollY,
            left: leftOffset + window.scrollX,
        });

        setSelectedFilter(null);

        // Slight delay to ensure previous modal cleanup before opening a new one
        setTimeout(() => {
            setSelectedFilter(filter);
            //setSelectedFilterValues(values); // Use this new state to set initial values
        }, 0);
    };


    const updateFilter = (field: string, value: any) => {
        setQsoFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const fetchOptionsForFilters = async (filters: any, hookConfig: any) => {
        const fetchedOptions: { [key: string]: any[] } = {};

        for (const key in filters) {
            // Check if there is a corresponding hook for this filter field
            const hook = hookConfig[key];
            if (hook && hook.fetchFunction) {
                try {
                    await hook.fetchFunction();  // Call the hook's function to populate options
                    fetchedOptions[key] = hook.options; // Assign options from the hook's state
                } catch (error) {
                    console.error(`Error fetching options for ${key}: `, error);
                }
            }
        }
        return fetchedOptions;
    };

    // Determine when to show "Select all X QSOs" span
    const showSelectAllSpan = selectedRows?.length > 0 && !allSelected;

    // Handle individual row selection/deselection
    const handleRowSelect = (id: string) => {
        const updatedSelection = selectedRows.includes(id)
            ? selectedRows.filter((rowId) => rowId !== id) // Deselect row
            : [...selectedRows, id]; // Select row

        setSelectedRows(updatedSelection);
        setAllSelected(false); // Reset full selection state if manual selection is made
    };

    // Handle "Select All" checkbox in the table header
    const handleSelectAll = () => {
        setSelectedRows(() => {
            if (selectAll || allSelected) {
                setSelectAll(false);
                setAllSelected(false);
                return []; // Deselect all contacts
            } else {
                setSelectAll(true);
                setAllSelected(false); // Ensure global selection isn't enabled
                return contacts.map((contact) => contact.id).filter((id): id is string => id !== undefined) // Select all visible contacts
            }
        });
    };

    // Handle "Select all X QSOs" span click
    const handleSelectAllQSOs = () => {
        if (allSelected) {
            setSelectedRows([]);
            setAllSelected(false);
            setSelectAll(false);
        } else {
            setSelectedRows(['all']); // Represent selecting all QSOs across pages
            setAllSelected(true);
            setSelectAll(false); // Ensure individual checkbox doesn't conflict
        }
    };

    return (<>
        <div id='filter-section' className="flex items-center justify-between w-full mt-0 space-y-2 md:space-y-0 md:space-x-2 no-select">
            {/* Left side content */}
            <div className="flex items-center space-x-4 my-2 pl-6">
                <div
                    className="flex flex-wrap flex-shrink-0 items-center relative dropdown-button no-select mr-4 md:mr-0"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleAllDropdowns('filters');
                        toggleDropdown('filters');
                    }}
                >
                    <img className="h-6 w-6" src={Icons.FilterIcon} alt="icon" />
                    {appliedFilters?.length === 0 && (<span className="text-white font-[Play] text-base ml-2">Filter</span>)}

                    {/* Main Filter Modal */}
                    {dropdownVisible['filters'] && <MainFilterModal onSelectFilter={handleSelectFilter} filterConfig={filterConfig} appliedFilters={appliedFilters} />}

                    {/* Dynamic Filter Modal */}
                    {selectedFilter && (
                        <DynamicFilterModal
                            filterConfig={filterConfig}
                            setFiltersConfig={setFiltersConfig}
                            filter={selectedFilter}
                            onApplyFilter={handleApplyFilter}
                            onRemoveFilter={handleRemoveFilter}
                            initialValues={appliedFilters.find(f => f.field === selectedFilter.field)?.value || []}
                            onClose={() => setSelectedFilter(null)}
                            modalPosition={modalPosition}
                        />
                    )}
                </div>

                {/* Show Applied Filters */}
                <AppliedFilters filters={appliedFilters} onRemoveFilter={handleRemoveFilter} onEditFilter={handleEditFilter} filterConfig={filterConfig} />
            </div>

            {/* Right side date picker */}
            <div className="flex pr-6">
                <DateRangePicker onDateRangeChange={handleDateRangeChange} initialValue={{ startDate: qsoFilters.startDate, endDate: qsoFilters.endDate }} />
            </div>
        </div>

        {/* Divider */}
        <div className="h-[0.5px] w-full bg-[#1b1d63] mt-1" />
        
        {/* Bulk Actions */}
        <div className="flex flex-wrap items-center justify-between w-full mt-0 space-y-2 md:space-y-0 md:space-x-2 px-6">
            <div className="flex items-center flex-wrap justify-center md:justify-start">
                {/* <div className="flex"> */}
                <div className="flex items-center bg-[#31336e] px-2 py-1 w-[200px] gap-2 h-[30px] rounded-md relative">
                    <img src={Icons.SearchBarIcon} alt="" className='w-[15px] h-[15px]' />
                    <input
                        name='searchString'
                        type="text"
                        className="w-full outline-none bg-[#31336e] text-[#E1E4FF] font-Play text-xs"
                        value={searchTerm}
                        onChange={(e) => {
                            setTotalQSOs(0);
                            handleDebouncedInput(e.target.value.toLocaleUpperCase());
                        }}
                        placeholder="Search by Callsign"
                        autoComplete="off"
                    //onKeyDown={keyDownHander}
                    />
                </div>
                <DynamicButton
                    leftElement={<input type="checkbox" className="form-checkbox h-3 w-3 bg-[#0243DD] mt-1 mr-2" checked={allSelected} />}
                    text="Select All"
                    className='w-[90px] my-3'
                    onClick={handleSelectAllQSOs}
                />
                {/* Columns Button with Dropdown */}
                <div
                    className="relative w-[100px] bg-[#444467] h-[30px] flex items-center justify-center rounded-md my-3 ml-2 cursor-pointer dropdown-button dropdown-wrapper"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleAllDropdowns('columns');
                        toggleDropdown('columns');
                    }}
                >
                    <img src={Icons.ColumnsIcon} alt="" className="w-[15px] h-[15px]" />
                    <span className="font-[Play] text-[#FFFFFF] text-center ml-2 text-xs">
                        Columns
                    </span>
                    {/* Dropdown */}
                    {dropdownVisible['columns'] && (
                        <div className="absolute top-10 left-0 max-w-max max-h-[300px] bg-[#444467] z-10 shadow-lg p-2 overflow-y-auto"
                            onClick={handleDropdownClick}
                        >
                            {columns.map((column) => (
                                <div key={column.key} className="flex items-center py-1 no-select">
                                    <input
                                        id={`checkbox-${column.key}`}
                                        type="checkbox"
                                        checked={column.visible}
                                        onChange={() => toggleColumnVisibility(column.key)}
                                        className="mr-2"
                                    />
                                    <label
                                        htmlFor={`checkbox-${column.key}`}
                                        className="text-white font-Play text-sm whitespace-nowrap"
                                    >
                                        {column.label}
                                    </label>
                                </div>

                            ))}
                        </div>

                    )}


                </div>
                <DynamicButton
                    leftElement={<img src={Icons.ExchangeIcon} alt="" className="w-[20px] h-[20px] mr-2" />}
                    text="Move to Logbook"
                    className='w-[140px] my-3'
                    onClick={() => {
                        if (selectedRows.length === 0) {
                            showToast({
                                message: "Please select at least one QSO",
                                type: ToastTypes.WARN,
                            });
                            return;
                        } else if (userActivityStatus === IN_PROGRESS) {
                            showToast({
                                message: `${userActivityType} is in progress. Please complete the activity first.`,
                                type: ToastTypes.WARN,
                            });
                            return;
                        }

                        showMergeLogbookModal(
                            {
                                isBulkMerge: true,
                                selectedRows,
                                qsoFilters,
                                totalQSOs,
                                allSelected,
                            }
                        )

                    }}
                />
                <DynamicButton
                    leftElement={<img src={Icons.NotepadEditIcon} alt="" className="w-[20px] h-[20px] mr-2" />}
                    text="Bulk Edit Details"
                    className='w-[140px] my-3'
                    onClick={() => {
                        if (selectedRows.length === 0) {
                            showToast({
                                message: "Please select at least one QSO",
                                type: ToastTypes.WARN,
                            });
                            return;
                        } else if (userActivityStatus === IN_PROGRESS) {
                            showToast({
                                message: `${userActivityType} is in progress. Please complete the activity first.`,
                                type: ToastTypes.WARN,
                            });
                            return;
                        }

                        showEditModal({
                            isBulkEdit: true,
                            selectedRows,
                            qsoFilters,
                            totalQSOs,
                            allSelected,
                        });
                    }}
                />
                <DynamicButton
                    leftElement={<img src={Icons.ExchangeVerticalIcon} alt="" className="w-[20px] h-[20px] mr-2" />}
                    text="Export QSO"
                    isLoading={userActivityType === "exportContacts" && userActivityStatus === IN_PROGRESS}
                    className='w-[100px] my-3'
                    onClick={() => {
                        if (selectedRows.length === 0) {
                            showToast({
                                message: "Please select at least one QSO",
                                type: ToastTypes.WARN,
                            });
                            return;
                        } else if (userActivityStatus === IN_PROGRESS) {
                            showToast({
                                message: `${userActivityType} is in progress. Please complete the activity first.`,
                                type: ToastTypes.WARN,
                            });
                            return;
                        }

                        bulkExport({
                            selectedRows,
                            qsoFilters
                        });
                    }}
                />
                <DynamicButton
                    leftElement={<img src={Icons.ExchangeVerticalCircularIcon} alt="" className="w-[20px] h-[20px] mr-2" />}
                    text="Export All"
                    isLoading={isLoading || (userActivityType === "exportAllContacts" && userActivityStatus === IN_PROGRESS)}
                    className='w-[100px] my-3'
                    onClick={() => {
                        setIsLoading(true);
                        bulkExport({
                            selectedRows,
                            qsoFilters,
                            exportAll: true
                        });
                    }}
                />
                {/* </div> */}
            </div>
            <DynamicButton
                leftElement={<img src={Icons.TrashIcon} alt="" className="w-[20px] h-[20px] mr-2" />}
                text="Delete"
                className='w-[90px] bg-[#D34A4A] my-3'
                onClick={() => {
                    if (selectedRows.length === 0) {
                        showToast({
                            message: "Please select at least one QSO",
                            type: ToastTypes.WARN,
                        });
                        return;
                    } else if (userActivityStatus === IN_PROGRESS) {
                        showToast({
                            message: `${userActivityType} is in progress. Please complete the activity first.`,
                            type: ToastTypes.WARN,
                        });
                        return;
                    }

                    handlePrimaryModal({
                        title:
                            "Are you sure you want to delete selected contacts? This cannot be undone",
                        type: "Delete Contacts",
                        subType: allSelected
                            ? `All ${totalQSOs} QSOs selected`
                            : `${selectedRows?.length} QSOs selected`,
                        onCancelPressed: () => handlePrimaryModal(null),
                        onSubmitPressed: () => {
                            handlePrimaryModal(null);
                            console.log("Delete logbook");
                            bulkRemove({
                                selectedRows,
                                qsoFilters
                            });
                        },
                        submitText: "Yes, Delete",
                        cancelText: "Cancel",
                    });
                }}
            />
        </div>

        {/* Divider */}
        <div className="h-[0.5px] w-full bg-[#1b1d63] mb-1" />

        <div className="flex row items-center justify-center w-full mt-0 space-x-2 no-select px-6">
            {!loading && totalQSOs ? (<>
                <span className="text-[#17F9DA] text-sm font-Play">{totalQSOs} QSOs match your search </span> |
                {/* Select All Span */}
                {(showSelectAllSpan || allSelected) && (
                    <span
                        className="text-[#17F9DA] text-sm font-Play font-bold cursor-pointer"
                        onClick={handleSelectAllQSOs}
                    >
                        {allSelected ? `Unselect all ${totalQSOs} QSOs` : `Select all ${totalQSOs} QSOs`}
                    </span>

                )}

                {/* Show count of selected QSOs */}
                {(allSelected || selectedRows?.length > 0) && (
                    <span className="ml-3 text-white text-sm font-Play">
                        {allSelected
                            ? `All ${totalQSOs} QSOs selected`
                            : `${selectedRows?.length} QSOs selected`}
                    </span>
                )}
            </>) : ''}
        </div>

        {/* Table */}
        <div className="mt-1 bg-[#151541] text-white rounded-md font-[Barlow] min-h-[450px] max-h-[450px] overflow-x-auto px-6">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <p>Loading...</p>
                </div>
            ) : (
                <table className="w-full min-w-max table-auto text-xs">
                    {/* min-w-max table-auto */}
                    <thead>
                        <tr className="bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid text-white no-select">
                            <th className="py-2 pl-2 font-[Barlow] text-xs md:text-sm md:text-left cursor-pointer">
                                {contacts?.length > 0 && <input
                                    type="checkbox"
                                    className='form-checkbox h-3 w-3 bg-[#0243DD]'
                                    name="selectAll"
                                    checked={selectAll || allSelected}
                                    onChange={handleSelectAll}
                                />}
                            </th>
                            {visibleColumns.map((column) => (
                                <th
                                    key={column.key}
                                    className="py-2 font-[Barlow] text-sm md:text-left cursor-pointer"
                                    onClick={() => handleSort(column)}
                                >
                                    <div className="flex items-center">
                                        {renderColumnHeader(column)}
                                        {column.sortable && (
                                            <div className="ml-2 mt-1">
                                                {sortConfig?.key === column.key ? (
                                                    sortConfig.direction === 'asc' ? (
                                                        <FaSortUp />
                                                    ) : (
                                                        <FaSortDown />
                                                    )
                                                ) : (
                                                    <FaSort />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                            <th className="py-3 font-[Barlow] text-sm"></th>
                            <th className="py-3 font-[Barlow] text-sm"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedContacts?.length > 0 ? paginatedContacts.map((contact, index) => {

                            return (<tr key={index} className={`${index % 2 ? "bg-[#151536]" : "bg-[#1F1F3F]"
                                } border-0 border-b border-slate-300 border-solid`}>
                                {/* Checkbox for selection */}
                                <td className="py-2 pl-2 text-xs md:text-sm">
                                    <input
                                        type="checkbox"
                                        className='form-checkbox h-3 w-3 bg-[#0243DD]'
                                        name={contact.id}
                                        checked={selectedRows.includes(contact.id!) || allSelected}
                                        onChange={() => handleRowSelect(contact.id!)}
                                    />
                                </td>

                                {/* Conditional rendering for each column based on visibleColumns */}
                                {visibleColumns.map((column) => (
                                    <td key={column.key} className="py-2">
                                        {renderCellValue(contact[column.key as keyof LogBookContactModel], column.key, contact)}
                                    </td>
                                ))}

                                <td className="py-2">
                                    <div
                                        className="flex justify-center items-center text-white cursor-pointer"
                                        onClick={(e) => {
                                            dispatch(setContactDetails({}));
                                            globalNavigate(`${CONTACT}?contactId=${contact?.id}`, {
                                                state: contact,
                                            });
                                        }}
                                    >
                                        <IoEyeOutline size={20} />
                                    </div>
                                </td>
                                <td className="py-2">
                                    <div
                                        className="flex justify-center items-center text-white cursor-pointer"
                                        onClick={() => setEditContactModalData(contact)}
                                    >
                                        <IoCreateOutline size={20} />
                                    </div>
                                </td>
                            </tr>
                            )
                        }) : (
                            <tr>
                                <td colSpan={Object.keys(columns)?.length + 1} className="text-center">No data available</td>
                            </tr>
                        )}
                    </tbody>

                </table>)}
        </div>

        {/* Pagination */}
        {!loading &&
            <div className='px-6'>
                <Pagination
                    total={totalQSOs}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        }
    </>);
};

export default QSOManager;